import { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import CircularProgress from '@mui/material/CircularProgress';

export default function TableComponent({ data, barData, manyTask }) {
  const TASKMAP = useMemo(() => {
    if (!manyTask) return;
    const map = new Map();
    for (const task of manyTask) {
      map.set(task.id, task);
    }
    return map;
  }, [manyTask]);

  if (!data) return <CircularProgress />;
  if (!barData) return <CircularProgress />;

  const a = barData.filter((bar) => bar.ticketId == data.ticketId);

  return (
    <TableContainer component={Paper}>
      <Table fullWidth="true" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Coluna</TableCell>
            <TableCell align="right">Tempo em minutos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {a.map((row) =>
            row.durations.map((a) => (
              <TableRow
                key={a.taskId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {TASKMAP.get(a.taskId)?.title}
                </TableCell>
                <TableCell align="right">
                  {Math.floor(a.timeInMinutes)}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
