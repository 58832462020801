import React, { useState } from 'react';
import { Box } from '@mui/material';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

export default function CrlvExerciseInput(props) {
    const { field, fieldState, formState } = useController({ name: props.name });

    //converte a string para integer
    const convertValue = value => {
        let exerciseValueInt;

        if(value.trim()  !== ''){ //.trim() remove os caracteres em branco do inicio e no final da string
            exerciseValueInt = parseInt(value, 10);
            return exerciseValueInt;
        }
        return exerciseValueInt = 0;
    }

    //atualiza o valor do componente
    const handleExerciseChange = value => { 
        const convertedValue = convertValue(value);
        field.onChange(convertedValue);
        props.handleExerciseValue(convertedValue);
    }

    return (
        <>
        <Box>
            <TextField
            {...field}
            {...props}
            label='Exercício (2023 ou 2024)'
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
            onChange={(e) => handleExerciseChange(e.target.value)}
            value={field.value}
            inputProps={{ maxLength: 4 }} 
            sx={{width:'100%'}}
        />
      </Box>
    </>
  );
}
