import api from './api';

async function createTask({ title, sequence, isTimeManaged, completable }) {
  const res = await api.post('/tasks', {
    title,
    sequence,
    isTimeManaged,
    completable,
    passable: !completable,
  });

  return res.data;
}

async function updateTask(
  taskId,
  { sequence, title, isTimeManaged, completable, indictable }
) {
  const res = await api.patch(`/tasks/${taskId}`, {
    title,
    sequence,
    isTimeManaged,
    completable,
    indictable,
  });

  return res.data;
}

async function deleteTask({ taskId }) {
  const res = await api.delete(`/tasks/${taskId}`);

  return res.data;
}

async function getManyTasks() {
  const res = await api.get('/tasks');

  return res.data;
}
const taskApi = { createTask, updateTask, getManyTasks, deleteTask };

export default taskApi;
