import useAuthSWR from './useAuthSWR';

export default function useTask(taskId, query) {
  const taskSWR = useAuthSWR(
    `/tasks/${taskId}`,
    {},
    { revalidateOnFocus: false }
  );
  const ticketsSWR = useAuthSWR(`/tasks/${taskId}/tickets`, query, {
    fallbackData: { tickets: [], numberOfTickets: 0 },
    refreshInterval: 15000,
  });

  return {
    task: taskSWR.data,
    isTaskLoading: !taskSWR.error && !taskSWR.data,
    taskError: taskSWR.error,
    tickets: ticketsSWR.data?.tickets,
    numberOfTickets: ticketsSWR.data?.numberOfTickets,
    isTicketsLoading: !taskSWR.error && !taskSWR.data,
    ticketsError: ticketsSWR.error,
  };
}
