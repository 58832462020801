import React from 'react';
import './shared/yupExtensions';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { setLocale } from 'yup';
import App from './App';

setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'Campo requirido',
  },
  number: {
    min: 'Deve ser maior que ${min}',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  
      <App />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
