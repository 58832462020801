import AnttList from './AnttList';
import WorkspaceResourceDialog from '../WorkspaceResourceDialog';

import React, { useEffect, useState } from 'react';
import { useWorkspaceAntts } from '../../../hooks/useWorkspaces';
import { useSnackbar } from '../../../contexts/SnackbarContext';

function WorkspaceAnttDialog({ value, onChange, onClose, open }) {
  const [selected, setSelected] = useState(value);
  useEffect(() => setSelected(value), [value]);

  const { deleteAntt } = useWorkspaceAntts();
  const handleRequest = useSnackbar();

  function onConfirm() {
    onChange(selected);
    onClose();
  }

  async function onDelete(antt) {
    async function main() {
      await deleteAntt(antt.id);

      if (value.id === antt.id) {
        setSelected(undefined);
        onChange(null);
      }
    }

    return handleRequest(main);
  }

  return (
    <WorkspaceResourceDialog
      title="Escolher ANTT"
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      disableConfirm={!selected}
    >
      <AnttList
        selected={selected}
        onSelect={setSelected}
        onDelete={onDelete}
      />
    </WorkspaceResourceDialog>
  );
}

export default React.memo(WorkspaceAnttDialog);
