import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

export default function MaskedTextField({ mask, getMask, ...props }) {
  if (mask || getMask) {
    const currentMask = mask ?? getMask(props.value);

    return (
      <InputMask
        mask={currentMask}
        maskChar={null}
        alwaysShowMask={false}
        {...props}
      >
        {(props) => <TextField {...props} />}
      </InputMask>
    );
  }

  return <TextField {...props} />;
}
