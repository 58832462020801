import { useMemo, useState } from 'react';
import useAuthSWR from '../../../hooks/useAuthSWR';
import usePagination from '../../../hooks/usePagination';

export default function useInactiveTickets(status) {
  const pagination = usePagination({ defaultLimit: 20, defaultPage: 0 });

  const [search, setSearch] = useState({
    licensePlate: null,
    originCity: null,
    destinyCity: null,
  });

  const { data: inactiveTickets, isValidating } = useAuthSWR('/tickets', {
    status,
    page: pagination.page,
    limit: pagination.limit,
    ...search,
  });

  function createSetSearch(key) {
    return function (value) {
      pagination.jumpToPage(0);
      setSearch((obj) => ({
        ...obj,
        [key]: value == '' || value == undefined ? undefined : value,
      }));
    };
  }

  const setLicensePlate = useMemo(() => createSetSearch('licensePlate'), []);
  const setOriginCity = useMemo(() => createSetSearch('originCity'), []);
  const setDestinyCity = useMemo(() => createSetSearch('destinyCity'), []);

  return {
    inactiveTickets,
    pagination,
    isValidating,
    setSearch,
    setLicensePlate,
    setOriginCity,
    setDestinyCity,
  };
}
