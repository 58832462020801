import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function TicketTableHead({ children }) {
  return (
    <TableHead>
      <TableRow>{children}</TableRow>
    </TableHead>
  );
}
