import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextFieldInput from '../../../components/Form/TextFieldInput';
import { FormProvider } from 'react-hook-form';
import useYupForm from '../../../hooks/useYupForm';
import * as yup from 'yup';
import { ticketsApi } from '../../../api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useState } from 'react';
const createNoteSchema = yup.object({
  content: yup.string().label('Conteúdo').min(2).required(),
  ticketId: yup.number(),
});
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export default function CreateNoteDialog({ open, handleClose, ticketId }) {
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const commands = useYupForm(createNoteSchema, {
    content: '',
    ticketId,
  });

  async function createNote({ content, ticketId }) {
    try {
      await ticketsApi.createNewNote({ ticketId, content });

      setMessage('Salvo !');
      setOpenStatusSuccess(true);
      handleClose(true);
    } catch (error) {
      if (error.response === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }
  return (
    <>
      <FormProvider {...commands}>
        <Dialog
          fullWidth
          sx={{ p: 10 }}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
        >
          <DialogTitle sx={{ justifyContent: 'center' }}>
            Escreva a nota
          </DialogTitle>

          <DialogContent>
            <TextFieldInput
              sx={{ mt: 1 }}
              name="content"
              label="Anotação"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={commands.handleSubmit(createNote)}>Salvar</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
