import { useWorkspace } from '../../contexts/WorkspaceContext';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
export default function WorkspaceImage({ name, ...props }) {
  const { workspace } = useWorkspace();

  return (
    <InnerImageZoom
      zoomType="hover"
      zoomPreload={true}
      alt={name}
      src={`${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${workspace.id}/images/${name}`}
      {...props}
    />
  );
}
