import React from 'react';
import { TextFieldInput, CheckboxInput } from '../../components/Form';
import TextField from '@mui/material/TextField';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import ThreeStateCheckbox from '../../components/Form/ThreeStateCheckbox';
import FormControlLabel from '@mui/material/FormControlLabel';
export default function PersonalReferenceComponentInput({
  name,
  sx,
  isDisabled,
}) {
  return (
    <>
      <FormControlLabel
        label="Nome"
        control={
          <ThreeStateCheckbox isDisabled={isDisabled} name={`${name}.name`} />
        }
      />
      <FormControlLabel
        label="Data nasc"
        control={
          <ThreeStateCheckbox
            isDisabled={isDisabled}
            name={`${name}.birthDate`}
          />
        }
      />
      <FormControlLabel
        label="Endereço"
        control={
          <ThreeStateCheckbox
            isDisabled={isDisabled}
            name={`${name}.address`}
          />
        }
      />
      <FormControlLabel
        label="Pai/Mãe"
        control={
          <ThreeStateCheckbox
            isDisabled={isDisabled}
            name={`${name}.parents`}
          />
        }
      />
      <FormControlLabel
        label="Cor cavalo"
        control={
          <ThreeStateCheckbox
            isDisabled={isDisabled}
            name={`${name}.truckColor`}
          />
        }
      />
      <FormControlLabel
        label="Telefone"
        control={
          <ThreeStateCheckbox isDisabled={isDisabled} name={`${name}.fone`} />
        }
      />
      <TextFieldInput
        size="small"
        {...sx}
        disabled={isDisabled}
        label="Nome"
        name={`${name}.referenceName`}
      />
      <TextFieldInput
        size="small"
        {...sx}
        disabled={isDisabled}
        label="Parentesco"
        name={`${name}.kinship`}
      />
    </>
  );
}
