import api from './api';

async function createDriver({ images, register }) {
  const formData = new FormData();
  for (const image of images) {
    formData.append('images', image);
  }
  formData.append('register', JSON.stringify(register));

  await api.post('/drivers', formData);
}

async function getManyDrivers({ page, limit }) {
  const res = await api.get('/drivers', { params: { page, limit } });

  return res.data;
}

const driverApi = { createDriver, getManyDrivers };

export default driverApi;
