import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function PeriodComponent(props) {
  return (
    <FormControl>
      <FormLabel>Período</FormLabel>
      <RadioGroup {...props} row>
        <FormControlLabel value="WEEKLY" control={<Radio />} label="Semanal" />
        <FormControlLabel value="MONTHLY" control={<Radio />} label="Mensal" />
      </RadioGroup>
    </FormControl>
  );
}
