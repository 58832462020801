import useYupForm from '../../hooks/useYupForm';
import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { DropdownFieldInput, TextFieldInput } from '../../components/Form';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import RegistrationFormComponentInput from './DocumentsComponentInput';
import DocumentForm from './DocumentsForm';
import CompanyReferencesComponentInput from './CompanyReferencesComponentInput';
import PersonalReferenceComponentInput from './PersonalReferenceComponentInput';
import TextField from '@mui/material/TextField';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ThreeStateCheckbox from '../../components/Form/ThreeStateCheckbox';
import useSWR from 'swr';
import formApi from '../../api/form.api';
import useAuthSWR from '../../hooks/useAuthSWR';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import RadioComponentInput from '../../components/Form/RadioComponentInput';
import ExperienceTimeComponent from './ExperienceTimeComponent';
import { useForm } from 'react-hook-form';

const dataSchema = yup.object({
  isAlreadyPopulated: yup.boolean().nullable(),
  approved: yup.boolean().nullable(),
  reprovedReason: yup.string().nullable(),
  score: yup.number().nullable(),
  isCnpj: yup.boolean().nullable(),
  driverInfo: yup.object({
    name: yup.string().nullable(),
    licensePlate: yup.string().nullable(),
    origin: yup.string().nullable(),
    destiny: yup.string().nullable(),
  }),
  documents: yup.array().of(
    yup.object({
      primaryName: yup.string().nullable(),
      primaryCity: yup.string().nullable(),
      secondaryName: yup.string().nullable(),
      secondaryCity: yup.string().nullable(),
    })
  ),
  companyReferences: yup.array().of(
    yup.object({
      name: yup.string().nullable(),
      licensePlate: yup.boolean().nullable(),
      travelQuantity: yup.number().nullable(),
      product: yup.string().nullable(),
      date: yup.string().nullable(),
    })
  ),
  personalReferences: yup.array().of(
    yup.object({
      referenceName: yup.string().nullable(),
      kinship: yup.string().nullable(),
      name: yup.boolean().nullable(),
      birthDate: yup.boolean().nullable(),
      address: yup.boolean().nullable(),
      parents: yup.boolean().nullable(),
      truckColor: yup.boolean().nullable(),
      fone: yup.boolean().nullable(),
    })
  ),
  extras: yup.object({
    pis: yup.boolean().nullable(),
    blackList: yup.boolean().nullable(),
    photos: yup.boolean().nullable(),
    pix: yup.boolean().nullable(),
    bankAccount: yup.boolean().nullable(),
  }),
  driverReference: yup.object({
    reference: yup.string().nullable(),
    experienceTime: yup.number().nullable(),
    anttDate: yup.string().nullable(),
    shaftsQuantity: yup.number().nullable(),
  }),
  responsibleReferences: yup.object({
    name: yup.string().nullable(),
    type: yup.string().nullable(),
    experienceTime: {
      days: yup.number().nullable(),
      months: yup.number().nullable(),
      years: yup.number().nullable(),
    },

    truckQuantity: yup.number().nullable(),
  }),
  observations: yup.object({
    documents: yup.string().nullable(),
    bankAccount: yup.string().nullable(),
    form: yup.string().nullable(),
  }),
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export const steps = [
  'Geral',
  'Inf. Motorista',
  'Documentos',
  'Ref. Empresarial',
  'Ref. Pessoal',
  'Último manifesto',
  'Ref. Motorista',
  'Ref. do responsável',
  'Outros',
];

export default function CreateForm() {
  const pathArray = window.location.pathname.split('/');
  const { register, setValue } = useForm();

  const { data: formData } = useAuthSWR(
    `registration-form/${pathArray[2]}`,
    {},
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMountComplete: false,
    },
    pathArray === undefined
  );

  const documentMethod = useYupForm(dataSchema, {
    isAlreadyPopulated: false,
  });

  const enableReasonTextField =
    documentMethod.watch('reprovedReason') === 'Outros';
  const isApprovedChecked = documentMethod.watch('approved') === false;

  useEffect(() => {
    if (!formData) return;

    const data = JSON.parse(JSON.stringify(formData));
    const a = documentMethod.getValues();
    const alreadyPopulated = a.isAlreadyPopulated;
    data.isAlreadyPopulated == alreadyPopulated;
    data.isAlreadyPopulated == false;
    if (!['Transportadora', 'Pessoal'].includes(data.reprovedReason)) {
      data.other = data.reprovedReason;
      data.reprovedReason = 'Outros';
    }
    if (data.responsibleReference.type === 'AGGREGATED') {
      data.responsibleReference.type = {
        name: 'Agregado',
        value: 'AGGREGATED',
      };
    }

    if (data.responsibleReference.type === 'REGISTERED') {
      data.responsibleReference.type = {
        name: 'Registrado',
        value: 'REGISTERED',
      };
    }

    if (data.responsibleReference.type === 'COMMISION') {
      data.responsibleReference.type = { name: 'Comissão', value: 'COMISSION' };
    }

    if (data.responsibleReference.experienceTime !== null) {
      var years = Math.floor(data.responsibleReference.experienceTime / 365);
      var months = Math.floor(
        (data.responsibleReference.experienceTime % 365) / 30
      );
      var days = Math.floor(
        (data.responsibleReference.experienceTime % 365) % 30
      );

      if (years !== undefined && months !== undefined && days !== undefined) {
        data.responsibleReference.experieceTime = {
          years,
          days,
          months,
        };
      }
    }
    documentMethod.reset({
      ...data,

      isAlreadyPopulated: true,
    });
  }, [formData]);

  const values = documentMethod.getValues();

  const { data: fetcher } = useSWR(
    () => {
      if (!formData) return null;
      const values = documentMethod.getValues();
      if (!values.isAlreadyPopulated) return null;

      return {
        id: `${pathArray[2]}`,
        ...values,
      };
    },

    formApi.putOperation,
    {
      refreshInterval: 1000 * 30,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMountComplete: false,
    }
  );

  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [value, setValues] = useState('');
  const [checked, setChecked] = useState([true, false]);
  const [experienceTime, setExperienceTime] = useState({
    days: 0,
    months: 0,
    years: 0,
  });
  const needReason = value == 'false';

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  async function SaveCurrentFormState() {
    try {
      const data = documentMethod.getValues();

      data;
      pathArray[2];

      await formApi.putOperation({
        id: pathArray[2],
        ...data,
      });

      setMessage('Salvo !');
      setOpenStatusSuccess(true);
    } catch (error) {
      error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 422) {
        setMessage('Faltou o tipo');
      } else if (error.response.status === 500) {
        setMessage('Erro inesperado !');
      }
      setOpenStatus(true);
    }
  }
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            color="linearProgress"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.primary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => SaveCurrentFormState()}
        >
          Salvar
        </Button>
      </Box>
    );
  }

  return (
    <>
      <FormProvider {...documentMethod}>
        <Grid container spacing={2}>
          <Box sx={{ width: '75%', ml: 'auto', mr: 'auto' }}>
            <LinearProgressWithLabel value={formData?.progress} />
          </Box>
          <Grid item xs={12} xl={5} lg={12}>
            <Stack
              direction="row"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Stack>
                <TextFieldInput
                  size="small"
                  label="Score"
                  name="score"
                  type="number"
                />
                <TextFieldInput
                  name="isAlreadyPopulated"
                  type="hidden"
                  sx={{ display: 'none' }}
                />

                <RadioComponentInput
                  name="approved"
                  title={undefined}
                  firstLabel="Aprovado"
                  secondLabel="Reprovado"
                  row={true}
                />

                {isApprovedChecked ? (
                  <DropdownFieldInput
                    size="small"
                    fullWidth
                    name="reprovedReason"
                    options={['Transportadora', 'Pessoal', 'Outros']}
                    label="Motivo"
                  />
                ) : (
                  <></>
                )}
                {enableReasonTextField && isApprovedChecked ? (
                  <TextFieldInput
                    sx={{ mt: '10px' }}
                    size="small"
                    name="other"
                    label="Outros"
                  />
                ) : (
                  <></>
                )}
              </Stack>

              <Divider orientation="vertical" flexItem sx={{ marginX: 5 }} />
              <Stack spacing={2}>
                <RadioComponentInput
                  name="gr"
                  title="GR"
                  firstLabel="Aprovado"
                  secondLabel="Reprovado"
                  row={false}
                />
                <RadioComponentInput
                  name="isCnpj"
                  title="PJ"
                  firstLabel="Sim"
                  secondLabel="Não"
                  row={false}
                />
              </Stack>

              <Stack alignItems="center" sx={{ ml: '10px' }}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Outros
                </Typography>
                <FormGroup name="reprovedReason">
                  <FormControlLabel
                    label="PIS"
                    control={
                      <ThreeStateCheckbox
                        name="extras.pis"
                        label="PIS"
                        indeterminate={true}
                        onClick={(e) => e.target.checked}
                      />
                    }
                  />
                  <FormControlLabel
                    label="BlackList"
                    control={
                      <ThreeStateCheckbox
                        name="extras.blacklist"
                        label="BlackList"
                        onClick={(e) => e.target.checked}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Fotos"
                    control={
                      <ThreeStateCheckbox
                        name="extras.photos"
                        label="Fotos"
                        onClick={(e) => e.target.checked}
                      />
                    }
                  />
                  <FormControlLabel
                    label="PIX"
                    control={<ThreeStateCheckbox name="extras.pix" />}
                  />

                  <FormControlLabel
                    label="Conta Bancária"
                    control={<ThreeStateCheckbox name="extras.bankAccount" />}
                  />
                </FormGroup>
              </Stack>
              <Stack sx={{ width: '25%' }} spacing={1}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Observações
                </Typography>

                <TextFieldInput
                  size="small"
                  name="observations.documents"
                  label="Documentos"
                />

                <TextFieldInput
                  size="small"
                  name="observations.form"
                  label="Geral"
                />

                <TextFieldInput
                  size="small"
                  name="observations.bankAccount"
                  label="Conta bancária"
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} xl={6} sx={{ marginX: '15px' }}>
            <Stack
              spacing={2}
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              direction="row"
            >
              <Stack sx={{ width: '25%' }} spacing={1}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Motorista
                </Typography>

                <TextFieldInput
                  size="small"
                  name="driverInfo.name"
                  label="Nome do motorista"
                />
                <TextFieldInput
                  size="small"
                  name="driverInfo.licensePlate"
                  mask="aaa-9*99"
                  label="Placa"
                />
                <TextFieldInput
                  size="small"
                  name="driverInfo.origin"
                  label="Origem"
                />
                <TextFieldInput
                  size="small"
                  name="driverInfo.destiny"
                  label="Destino"
                />
              </Stack>

              <Stack sx={{ width: '25%' }} spacing={1}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Referência do motorista
                </Typography>
                <TextFieldInput
                  size="small"
                  name="driverReference.reference"
                  label="Como chegou até nós"
                />
                <TextFieldInput
                  size="small"
                  name="driverReference.experienceTime"
                  label="Experiência"
                  type="number"
                />
                {/* aqui vai tres inputs, dia, mes e ano, ano*12*30 + mes*30 + dia */}
                <DatePickerInput
                  sx={{ width: '100%' }}
                  name="driverReference.anttDate"
                  label={'Data ANTT'}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
                <TextFieldInput
                  size="small"
                  name="driverReference.shaftsQuantity"
                  label="Eixos"
                  type="number"
                />
              </Stack>
              <Stack sx={{ width: '25%' }} spacing={1}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Último manifesto
                </Typography>
                <TextFieldInput
                  size="small"
                  name="lastManifest.companyName"
                  label="Empresa"
                />
                <DatePickerInput
                  name="lastManifest.date"
                  label={'Data'}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
                <TextFieldInput
                  size="small"
                  name="lastManifest.origin"
                  label="Origem"
                />
                <TextFieldInput
                  size="small"
                  name="lastManifest.destiny"
                  label="Destino"
                />
                <TextFieldInput
                  size="small"
                  name="lastManifest.product"
                  label="Produto"
                />
              </Stack>
              <Stack textAlign="center" sx={{ width: '25%' }} spacing={1}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Referência do responsável
                </Typography>
                <TextFieldInput
                  size="small"
                  name="responsibleReference.name"
                  label="Nome"
                />
                <DropdownFieldInput
                  size="small"
                  fullWidth
                  name="responsibleReference.type"
                  options={[
                    { name: 'Agregado', value: 'AGGREGATED' },
                    { name: 'Registrado', value: 'REGISTERED' },
                    { name: 'Comissão', value: 'COMISSION' },
                  ]}
                  label="Tipo"
                  getOptionLabel={(option) => option.name}
                />
                <ExperienceTimeComponent
                  name="responsibleReference.experieceTime"
                  experienceTime={experienceTime}
                />
                <TextFieldInput
                  size="small"
                  name="responsibleReference.truckQuantity"
                  label="Quantidade de caminhões"
                  type="number"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid
            container
            xs={12}
            spacing={4}
            justifyContent="center"
            sx={{ mt: 5 }}
          >
            <Grid item xs={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel>Documento 1</FormLabel>
                <Divider sx={{ width: '60%' }} />
              </Box>
              <RegistrationFormComponentInput
                name="documents.0"
                label={{
                  primary: 'CNH Proprietário',
                  secondary: 'Comprovante Residência',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: '10px',
                }}
              >
                <FormLabel>Documento 2</FormLabel>
                <Divider sx={{ width: '60%' }} />
              </Box>
              <RegistrationFormComponentInput
                name="documents.1"
                label={{
                  primary: 'CNH Motorista',
                  secondary: 'Comprovante Residência',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: '10px',
                }}
              >
                <FormLabel>Documento 3</FormLabel>
                <Divider sx={{ width: '60%' }} />
              </Box>
              <RegistrationFormComponentInput
                name="documents.2"
                sx={{ width: '100%' }}
                label={{
                  primary: 'Documento cavalo',
                  secondary: 'ANTT cavalo',
                }}
              />
              <DocumentForm
                name="documents"
                itemDefaultValue={{
                  primaryName: undefined,
                  secondaryName: undefined,
                  secondaryCity: undefined,
                  primaryCity: undefined,
                }}
                formLabelPrefix="Documento"
                buttonLabelSufix="documentação"
                ItemForm={RegistrationFormComponentInput}
                ItemFormProps={{
                  label: { primary: 'Doc carreta', secondary: 'ANTT carreta' },
                }}
                min={1}
                offset={3}
                max={Infinity}
              />
            </Grid>
            <Grid item xs={3}>
              <DocumentForm
                name="companyReferences"
                itemDefaultValue={{
                  name: undefined,
                  licensePlate: undefined,
                  travelQuantity: undefined,
                  product: undefined,
                  date: new Date(),
                }}
                formLabelPrefix="Referência empresarial"
                buttonLabelSufix="ref. empresarial"
                ItemForm={CompanyReferencesComponentInput}
                min={3}
                max={Infinity}
              />
            </Grid>
            <Grid item xs={3}>
              <DocumentForm
                name="personalReference"
                formLabelPrefix="Referência Pessoal"
                buttonLabelSufix="ref. pessoal"
                ItemForm={PersonalReferenceComponentInput}
                ItemFormProps={{
                  isDisabled: false,
                }}
                min={2}
                max={Infinity}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={3000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
