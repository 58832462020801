import {
  CustomTable,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  TableText,
  TableTitle,
} from '../../../components/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import useInactiveTickets from './useInactiveTickets';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import VisibilityIcon from '@mui/icons-material/Visibility';
import LinkClipboardButton from '../TicketInfo/LinkClipboardButton';

export default function InactiveTickets({ status }) {
  const {
    inactiveTickets,
    pagination,
    setLicensePlate,
    setDestinyCity,
    setOriginCity,
  } = useInactiveTickets(status);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      maxWidth="lg"
    >
      <Paper elevation={2} sx={{ mb: 2, width: '100%' }}>
        <Grid container sx={{ py: 3, px: 4 }} spacing={2}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Placa"
              onBlur={(e) => setLicensePlate(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Origem"
              onBlur={(e) => setOriginCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Destino"
              onBlur={(e) => setDestinyCity(e.target.value)}
            />
          </Grid>
          <Grid container item xs={3} justifyContent="center">
            <Pagination {...pagination.register()} />
          </Grid>
        </Grid>

        <CustomTable
          sx={{
            width: '100%',
            boxShadow: 0,
            borderRadius: 0,
          }}
        >
          <CustomTableHead>
            <CustomTableCell>
              <TableTitle>Motorista</TableTitle>
            </CustomTableCell>
            <CustomTableCell>
              <TableTitle>Placa</TableTitle>
            </CustomTableCell>
            <CustomTableCell>
              <TableTitle>Origem</TableTitle>
            </CustomTableCell>
            <CustomTableCell>
              <TableTitle>Destino</TableTitle>
            </CustomTableCell>
            <CustomTableCell>
              <TableTitle>Cliente</TableTitle>
            </CustomTableCell>
            {status === 'DELETED' ? (
              <CustomTableCell>
                <TableTitle>Motivo</TableTitle>
              </CustomTableCell>
            ) : undefined}
            <CustomTableCell>
              <TableTitle textAlign="center">Ações</TableTitle>
            </CustomTableCell>
          </CustomTableHead>

          <TableBody>
            {inactiveTickets?.map((ticket) => (
              <CustomTableRow key={ticket.id}>
                <CustomTableCell>
                  <TableText>{ticket.driverName}</TableText>
                </CustomTableCell>
                <CustomTableCell>
                  <TableText>{ticket.licensePlate}</TableText>
                </CustomTableCell>
                <CustomTableCell>
                  <TableText>{ticket.originCity}</TableText>
                </CustomTableCell>
                <CustomTableCell>
                  <TableText>{ticket.destinyCity}</TableText>
                </CustomTableCell>
                <CustomTableCell>
                  <TableText>{ticket.clientName}</TableText>
                </CustomTableCell>
                {status === 'DELETED' ? (
                  <CustomTableCell>
                    <TableText>{ticket.deleteReason}</TableText>
                  </CustomTableCell>
                ) : undefined}
                <CustomTableCell>
                  <Box
                    px={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Link to={`/tickets/${ticket.id}`}>
                      <IconButton>
                        <VisibilityIcon />
                      </IconButton>
                    </Link>

                    <LinkClipboardButton ticket={ticket} />
                  </Box>
                </CustomTableCell>
              </CustomTableRow>
            )) ?? <></>}
          </TableBody>
        </CustomTable>
      </Paper>
    </Container>
  );
}
