import { useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import TextFieldInput from '../../components/Form/TextFieldInput';
import Button from '@mui/material/Button';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import { ticketsApi } from '../../api';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import DropdownFieldInput from '../../components/Form/DropdownFieldInput';
import useAuthSWR from '../../hooks/useAuthSWR';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import CheckboxInput from '../../components/Form/CheckboxInput';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const creatorSchema = yup.object({
  licensePlate: yup.string(),
  price: yup.number(),
  originCity: yup.string(),
  destinyCity: yup.string(),
  originState: yup.string(),
  destinyState: yup.string(),
  clientName: yup.string(),
  status: yup.string(),
  loadingDate: yup.date(),
  stopOver: yup.string(),
  product: yup.string(),
  isTrocaNota: yup.boolean().default(false),
  driverName: yup.string(),
  task: yup.object(),
  isDriverRegistered: yup.boolean().default(false),
  isDriverUpdated: yup.boolean().default(false),
});
const STATES = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'DF',
];
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function CreateTicketForm() {
  const { data: getCreatePerm } = useAuthSWR(`/users/me/task-permissions`);

  const { data: manyTask } = useAuthSWR('/tasks');
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);

  const navigate = useNavigate();

  async function onSubmit({
    licensePlate,
    price,
    originCity,
    destinyCity,
    originState,
    destinyState,
    clientName,
    status,
    loadingDate,
    stopOver,
    product,
    isTrocaNota,
    driverName,
    task,
    isDriverRegistered,
    isDriverUpdate,
  }) {
    try {
      await ticketsApi.createTicket({
        licensePlate,
        price,
        originCity,
        destinyCity,
        originState,
        destinyState,
        clientName,
        status,
        loadingDate,
        stopOver,
        product,
        isTrocaNota,
        driverName,
        isDriverRegistered,
        isDriverUpdate,
        taskId: task.id,
      });
      setMessage('Ticket Criado !');
      setOpenStatusSuccess(true);
      navigate('/dashboard');
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }
  const creatorMethods = useYupForm(creatorSchema, {
    isTrocaNota: false,
    isDriverRegistered: false,
    loadingDate: new Date(),
  });
  const TASKMAP = useMemo(() => {
    if (!manyTask) return;
    const map = new Map();
    for (const task of manyTask) {
      map.set(task.id, task);
    }
    return map;
  }, [manyTask]);
  if (!getCreatePerm) return <></>;
  if (!manyTask) return <></>;
  return (
    <>
      <FormProvider {...creatorMethods}>
        <Container
          maxWidth="md"
          spacing={6}
          sx={{
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper sx={{ p: 5, borderRadius: 6 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  fontSize={20}
                  py={1.5}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Criar ticket
                </Typography>
              </Grid>
              <Grid item spacing={2} xs={12} md={6}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <TextFieldInput name="driverName" label="Motorista" />
                  <TextFieldInput
                    mask="aaa-9*99"
                    label="Placa"
                    name={`licensePlate`}
                  />
                  <TextFieldInput name="price" label="Preço" />
                  <TextFieldInput name="originCity" label="Origem" />
                  <DropdownFieldInput
                    name="originState"
                    options={STATES}
                    label="Estado origem"
                    getOptionLabel={(option) => option}
                  />

                  <DatePickerInput
                    label="Carregamento"
                    name="loadingDate"
                    views={['day', 'month', 'year']}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <TextFieldInput name="destinyCity" label="Destino" />
                  <DropdownFieldInput
                    name="destinyState"
                    options={STATES}
                    label="Estado destino"
                    getOptionLabel={(option) => option}
                  />
                  <TextFieldInput name="clientName" label="Cliente" />
                  <TextFieldInput name="product" label="Produto" />
                  <TextFieldInput name="stopOver" label="Posto" />

                  <DropdownFieldInput
                    name="task"
                    options={getCreatePerm
                      .filter((perm) => perm.action == 'CREATE')
                      .map((perm) => TASKMAP.get(perm.taskId))}
                    label="Coluna"
                    getOptionLabel={(option) => option.title}
                  />
                </Stack>
              </Grid>

              <Grid item xs={6} md={4}>
                <CheckboxInput
                  name="isTrocaNota"
                  onClick={(e) => e.target.checked}
                  label="Troca de nota"
                />
              </Grid>

              <Grid item xs={6} md={4} display="flex" justifyContent="center">
                <CheckboxInput
                  name="isDriverRegistered"
                  onClick={(e) => e.target.checked}
                  label="Já cadastrado"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent={{ md: 'right' }}
              >
                <CheckboxInput
                  name="isDriverUpdate"
                  onClick={(e) => e.target.checked}
                  label="Atualização de cadastro"
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={() => navigate('/dashboard')}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  color="secondary"
                  onClick={creatorMethods.handleSubmit(onSubmit)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </FormProvider>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
