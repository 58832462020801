import React, { useEffect, useState, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const ThreeStates = ({ name, label, isDisabled }) => {
  const control = useController({ name });
  const value = control.field.value;
  const handleClick = () => {
    let newValue;

    if (value === null) newValue = false;
    else if (value === undefined) newValue = false;
    else if (value === false) newValue = true;
    else if (value === true) newValue = null;

    control.field.onChange({ target: { value: newValue } });
  };

  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          onClick={handleClick}
          disabled={isDisabled ? isDisabled : false}
          color={value === false ? 'error' : 'success'}
          checked={value === true}
          indeterminate={value === false}
        />
      }
    />
  );
};

export default function ThreeStateCheckbox({ name, ...props }) {
  return <ThreeStates name={name} isDisabled={props.isDisabled} />;
}
