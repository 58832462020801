import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import React, { useState } from 'react';
import ReportTableRows from './ReportTableRows';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import DetailBarChart from './DetailBarChart';
import useAuthSWR from '../../hooks/useAuthSWR';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import commercialApi from '../../api/commercial.api';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CommercialReport() {
  const location = useLocation();
  const reportParams = location.state.reportParams;

  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');

  const { data: report } = useAuthSWR('/reports/contacts', {
    date: reportParams.date,
    userId: reportParams.user?.id,
  });
  if (!report) return <></>;

  return (
    <>
      <Container maxWidth="md">
        <ReportTableRows report={report} />

        <Paper width="100%" sx={{ mt: 5 }}>
          <DetailBarChart
            reportDate={reportParams.date}
            periodDetails={report.weekly.details}
          />
        </Paper>
        <Box sx={{ width: '100%', bgcolor: 'background.paper', mt: '20px' }}>
          {report.missedInteractions.map((missed, index) => {
            let lastUser;

            if (missed.length === 0) return <></>;

            return (
              <>
                <Divider sx={{ mt: 4 }}>
                  <Typography>Semana {index + 1}</Typography>
                </Divider>

                <Grid container sx={{ width: '100%' }}>
                  {missed.map((contact, index) => {
                    if (lastUser == undefined)
                      lastUser = contact.responsibleUser.username;
                    const shouldBreak =
                      lastUser !== contact.responsibleUser.username &&
                      index % 2 == 1;
                    lastUser = contact.responsibleUser.username;

                    return (
                      <>
                        {shouldBreak ? (
                          <Grid item xs={6} key={-index} />
                        ) : (
                          <></>
                        )}

                        <Grid item xs={6} key={index}>
                          <ListItem>
                            <ListItemText
                              primary={contact.contact.name}
                              secondary={contact.responsibleUser.username}
                            />
                          </ListItem>
                          <Divider variant="middle" />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </>
            );
          })}
        </Box>
      </Container>
    </>
  );
}
