import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import useAuthSWR from '../../hooks/useAuthSWR';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import useYupForm from '../../hooks/useYupForm';
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import { FormProvider } from 'react-hook-form';
import { TextFieldInput } from '../Form';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import commercialApi from '../../api/commercial.api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ClientContactedHistory from './ClientContactedHistory';
import periodMap from './periodMap';
import { useEffect } from 'react';

const interactionSchema = yup.object({
  observation: yup
    .string()
    .label('Conteúdo')
    .min(10)
    .required('A observação é obrigatório'),
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomersToInteract({ me }) {
  const interactionMethod = useYupForm(interactionSchema);
  const [interacted, setInteracted] = useState(undefined);
  const [uniqPendentContacts, setUniqPendentContacts] = useState([]);

  const { data: pendentContacts } = useAuthSWR(`/users/${me?.id}/contacts`, {
    interacted,
  });

  //remove os contatos duplicados e gera um novo array de contatos
  useEffect(() => {
    if (pendentContacts) {
      const uniqPendentContacts = Array.from(
        new Set(pendentContacts.map((client) => client.id)),
        (id) => pendentContacts.find((client) => client.id === id)
      );
      setUniqPendentContacts(uniqPendentContacts);
    }
  }, [pendentContacts]);

  const [checked, setChecked] = useState([0]);
  const [contactData, setContactData] = useState();
  const [isInteractionDialogOpen, setIsInteractionDialogOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [checkboxState, setCheckboxState] = useState(false);
  const [contextMenu, setContextMenu] = React.useState(null);
  const [isContactHisotoryHidden, setIsContactHisotoryHidden] = useState(false);
  const [isDisasbleClientOpen, setIsDisasbleClientOpen] = useState(false);

  // Função para lidar com a seleção de checkboxes
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Função para lidar com a mudança no estado do checkbox "Liberou"
  const handleReleaseCheckbox = (event) => {
    setCheckboxState(event.target.checked);
  };

  // Função para registrar uma interação com o cliente
  async function registerInteraction({ observation }) {
    try {
      await commercialApi.registerInteraction({
        observation,
        released: checkboxState,
        contactId: contactData.id,
      });
      interactionMethod.resetField('observation', { defaultValue: '' });
      setCheckboxState(false);

      setMessage('Interação registrada !');
      setOpenStatusSuccess(true);
      setIsInteractionDialogOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  // Função para desativar um cliente
  async function inactiveContact() {
    try {
      await commercialApi.inactiveContact({
        contactId: contactData.id,
      });
      setMessage('Cliente desativado !');
      setOpenStatusSuccess(true);
      setIsDisasbleClientOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  // Função para lidar com o menu de contexto
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  // Função para fechar o menu de contexto
  const handleClose = () => {
    setContextMenu(null);
  };

  // Função para alternar a visibilidade do histórico de contato
  const handleContactHistoryVisibilityChange = () => {
    setIsContactHisotoryHidden(!isContactHisotoryHidden);
  };

  return (
    <>
      <Container>
        <Grid container mb={2}>
          <Grid item xs={3}>
            <Autocomplete
              fullWidth
              options={[
                { value: true, label: 'Contatado' },
                { value: false, label: 'Pendente' },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="situação" />
              )}
              onChange={(_event, option) => {
                setInteracted(option === null ? undefined : option.value);
              }}
            />
          </Grid>

          <Grid item xs={6} />
        </Grid>

        {uniqPendentContacts ? (
          <Grid
            container
            xs={12}
            columnSpacing={4}
            sx={{ bgcolor: 'background.paper' }}
          >
            {uniqPendentContacts.map((contact) => {
              const labelId = `checkbox-list-label-${contact}`;

              return (
                <Grid item xs={4} key={contact.id}>
                  <ListItem
                    disablePadding
                    onClick={() => {
                      setContactData(contact);
                      handleContactHistoryVisibilityChange();
                    }}
                    onContextMenu={(event) => {
                      handleContextMenu(event), setContactData(contact);
                    }}
                    disabled={contact.period === null}
                    sx={{
                      cursor: 'context-menu',
                    }}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(contact)}
                      dense
                    >
                      <ListItemText
                        id={labelId}
                        primary={contact.name}
                        secondary={contact.phoneNumber}
                      />

                      <Chip
                        label={periodMap.get(contact.period)}
                        color={
                          contact.isPendent
                            ? 'pendentContact'
                            : 'overduePendingContact'
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Container>

      <Dialog
        fullWidth={true}
        open={isDisasbleClientOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsDisasbleClientOpen(false)}
      >
        <DialogTitle>{'Desativar cliente'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja desativar o cliente {contactData?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDisasbleClientOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={() => inactiveContact()}>Desativar</Button>
        </DialogActions>
      </Dialog>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            setContextMenu(null);
            setIsInteractionDialogOpen(true);
          }}
        >
          Interação
        </MenuItem>
      </Menu>
      <Dialog
        fullWidth={true}
        open={isInteractionDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsInteractionDialogOpen(false)}
      >
        <DialogTitle>{'Interação'}</DialogTitle>
        <DialogContent>
          <FormProvider {...interactionMethod}>
            <Grid mt={1} alignItems="center" container columnSpacing={4}>
              <Grid item xs>
                <TextFieldInput
                  label="Observação"
                  fullWidth={true}
                  name="observation"
                  multiline={true}
                />
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="liberou"
                        onChange={handleReleaseCheckbox}
                        checked={checkboxState}
                      />
                    }
                    label="Liberou"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsInteractionDialogOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={interactionMethod.handleSubmit(registerInteraction)}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>

      <ClientContactedHistory
        isHiddend={isContactHisotoryHidden}
        contactId={contactData?.id}
        onHidden={handleContactHistoryVisibilityChange}
      />
    </>
  );
}
