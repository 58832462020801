import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import HorizontalListItem from '../../HorizontalImageList';
import ResourceImageCollapse from '../../WorkspaceResourceDialog/ResourceImageCollapse';

function AnttImageCollapse({ resource: antt, open }) {
  const isLegalPerson = antt.cnh.length === 0 && antt.addressProof.length === 0;

  return (
    <ResourceImageCollapse open={open}>
      {isLegalPerson && (
        <Alert severity="info">
          <AlertTitle>Esta ANTT refere-se à uma pessoa jurídica.</AlertTitle>
        </Alert>
      )}

      <HorizontalListItem title="RNTRC" images={antt.rntrc} />

      {!isLegalPerson && (
        <>
          <HorizontalListItem title="CNH" images={antt.cnh} />

          <HorizontalListItem
            title={
              <>
                COMPROVANTE <br /> DE RESIDÊNCIA
              </>
            }
            images={antt.addressProof}
          />
        </>
      )}
    </ResourceImageCollapse>
  );
}

export default React.memo(AnttImageCollapse);
