import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import HorizontalListItem from '../../HorizontalImageList';
import ResourceImageCollapse from '../../WorkspaceResourceDialog/ResourceImageCollapse';

function OwnerImageCollapse({ resource: owner, open }) {
  const isLegalPerson =
    owner.cnh.length === 0 && owner.addressProof.length === 0;

  return (
    <ResourceImageCollapse open={open}>
      {isLegalPerson && (
        <Alert severity="info">
          <AlertTitle>
            Este propríetário refere-se à uma pessoa jurídica.
          </AlertTitle>
        </Alert>
      )}

      {!isLegalPerson && (
        <>
          <HorizontalListItem title="CNH" images={owner.cnh} />

          <HorizontalListItem
            title={
              <>
                COMPROVANTE <br /> DE RESIDÊNCIA
              </>
            }
            images={owner.addressProof}
          />
        </>
      )}
    </ResourceImageCollapse>
  );
}

export default React.memo(OwnerImageCollapse);
