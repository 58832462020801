import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useWorkspaceOwners } from '../../../hooks/useWorkspaces';
import DeleteResourceDialog from '../WorkspaceResourceDialog/CreateResourceDialog';
import OwnerUpsertFormInput from './OwnerUpserFormInputs';
import { upsertOwnerSchema } from './OwnerUpsertForm';

export default function CreateOwnerDialog({ ...props }) {
  const { addOwner } = useWorkspaceOwners();
  const handleRequest = useSnackbar();

  async function createOwner({ phoneNumber, cnh, addressProof, legalPerson, cnhValidationDate, addressProofDate }) {

    async function main() {
      return await addOwner({
        phoneNumber,
        ...(legalPerson ? {} : { cnh, addressProof, cnhValidationDate, addressProofDate }),
      });
    }

    return handleRequest(main);
  }

  return (
    <DeleteResourceDialog
      title="Criar proprietário"
      createResource={createOwner}
      yupSchema={upsertOwnerSchema}
      resetValue={{
        phoneNumber: '',
        legalPerson: false,
        cnh: undefined,
        addressProof: [],
        cnhValidationDate: undefined,
        addressProofDate: undefined,
      }}
      {...props}
    >
      <OwnerUpsertFormInput />
    </DeleteResourceDialog>
  );
}
