import { useWorkspace } from '../../contexts/WorkspaceContext';

export default function useWorkspaceResource({
  getResource,
  setResource,
  getResourceId,
  api,
}) {
  const { workspace, error, isLoading, mutate } = useWorkspace();
  const resource = getResource(workspace);

  async function createManyResources(data) {
    async function createMany(currentWorkspace) {
      const newResources = await api.createMany(workspace.id, data);

      const currentResource = getResource(currentWorkspace);

      setResource(currentWorkspace, [...newResources, ...currentResource]);

      return {
        ...currentWorkspace,
      };
    }

    await mutate(createMany, { revalidate: false, rollbackOnError: true });
  }

  async function createResource(data) {
    let createdResource;
    async function create(currentWorkspace) {
      const newResource = await api.create(workspace.id, data);
      createdResource = newResource;
      const currentResource = getResource(currentWorkspace);
      setResource(currentWorkspace, [newResource, ...currentResource]);

      return {
        ...currentWorkspace,
      };
    }

    await mutate(create, {
      revalidate: false,
      rollbackOnError: true,
    });

    return createdResource;
  }

  async function deleteResource(resourceId) {
    async function deleteOne(currentWorkspace) {
      await api.delete(workspace.id, resourceId);

      const currentResource = getResource(currentWorkspace);
      setResource(
        currentWorkspace,
        currentResource.filter((r) => getResourceId(r) !== resourceId)
      );

      return {
        ...currentWorkspace,
      };
    }

    await mutate(deleteOne, { revalidate: false, rollbackOnError: true });
  }

  async function updateResource(resourceId, data) {
    async function updateOne(currentWorkspace) {
      const newResource = await api.update(workspace.id, resourceId, data);

      const currentResource = getResource(currentWorkspace);
      setResource(
        currentWorkspace,
        currentResource.map((r) =>
          getResourceId(r) !== resourceId ? r : newResource
        )
      );

      return {
        ...currentWorkspace,
      };
    }
    await mutate(updateOne, { revalidate: false, rollbackOnError: true });
  }

  function getResourceById(resourceId) {
    return resource?.find((resource) => getResourceId(resource) === resourceId);
  }

  return {
    resource,
    error,
    isLoading,
    createManyResources,
    createResource,
    deleteResource,
    updateResource,
    getResourceById,
  };
}
