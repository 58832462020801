import api from './api';
async function getAllReports({ day, taskId }) {
  const res = await api.get('/reports/timeline', {
    params: {
      day,
      taskId,
    },
  });

  for (const ticketTimeline of res.data.timeline) {
    for (const timeline of ticketTimeline.timeline) {
      timeline.timestamp = new Date(timeline.timestamp);
    }
  }

  return res.data;
}
async function getTimeReport({ fromDate, toDate, fromTask, toTask }) {
  const res = await api.get(`/reports/time`, {
    params: {
      fromDate,
      fromTaskSequence: fromTask,
      toDate,
      toTaskSequence: toTask,
    },
  });
  return res.data;
}
const reportsApi = {
  getAllReports,
  getTimeReport,
};
export default reportsApi;
