import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { FormProvider, useController } from 'react-hook-form';

import useYupForm from '../../../hooks/useYupForm';
import * as yup from 'yup';
import FileFieldInput from '../FileFieldInput';
import OwnerFieldInput from '../OwnerFieldInput';
import DropdownFieldInput from '../../Form/DropdownFieldInput';
import { LoadingButton } from '@mui/lab';
import { useBoolean } from 'react-hanger';
import OwnerUpsertFormInput from '../WorkspaceOwnerDialog/OwnerUpserFormInputs';

export const updateAnttSchema = yup.object({
  rntrc: yup.array().of(yup.string()).min(1).required().label('RNTRC'),

  owner: yup
    .object()
    .nullable()
    .when('type', { is: 'CNPJ', then: yup.object().required() })
    .label('Proprietário'),
  phoneNumber: yup
    .string()
    .min(14)
    .transform((v) => (v === '' ? undefined : v))
    .when('type', { is: 'CPF', then: yup.string().required() })
    .label('Telefone'),
  cnh: yup
    .array()
    .of(yup.string())
    .when('type', {
      is: 'CPF',
      then: yup.array().when('legalPerson', {
        is: false,
        then: yup.array().min(1).required(),
      }),
    })
    .label('CNH'),
  addressProof: yup
    .array()
    .of(yup.string())
    .when('type', {
      is: 'CPF',
      then: yup.array().when('legalPerson', {
        is: false,
        then: yup.array().min(1).required(),
      }),
    })
    .label('Comprovante de residência'),
    cnhValidationDate: yup
    .date()
    .when('type', {
      is:'CPF',
      then: yup.date().when('legalPerson', {
        is: true,
        then: yup.date().nullable(),
        otherwise: yup.date().required(),
      })
    })
    .typeError('Campo obrigatório')
    .min(new Date(), 'CNH expirada'), //validação par a CNH
    addressProofDate: yup
    .date()
    .when('type',{
      is: 'CPF',
      then: yup.date().when('legalPerson', {
        is: false,
        then: yup.date().required().test(
          'is-valid',
          'Comprovante inválido',
          addressProofDate => {
            if (!addressProofDate) return true;

            const currentDate = new Date(); 
            currentDate.setHours(0, 0, 0, 0);

            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(currentDate.getFullYear() - 1); 

            return addressProofDate <= currentDate && addressProofDate >= oneYearAgo;
          }
        ),
      })
    })
    .typeError('Campo obrigatório'),

  legalPerson: yup.boolean(),
  type: yup.string().required(),
});

export default function AnttUpsertForm({
  defaultValue,
  onSubmit,
  buttonTitle,
}) {
  const { handleSubmit, ...formMethods } = useYupForm(
    updateAnttSchema,
    defaultValue
  );

  const { field: dropdownField } = useController({
    name: 'type',
    control: formMethods.control,
  });

  const isLoading = useBoolean();

  async function submitForm(values) {
    isLoading.setTrue();
    try {
      await onSubmit(values, formMethods.reset);
    } finally {
      isLoading.setFalse();
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Grid container columnSpacing={3} px={5} my={2}>
        <Grid item xs={5} sx={{ flexGrow: 1 }}>
          <Stack sx={{ height: '100%' }} spacing={2} display="flex">
            <FileFieldInput label="RNTRC" name="rntrc" />

            <DropdownFieldInput
              options={['CPF', 'CNPJ']}
              label="Tipo"
              name="type"
            />

            <LoadingButton
              fullWidth
              sx={{ flexGrow: 1, maxHeight: 36 }}
              loading={isLoading.value}
              onClick={handleSubmit(submitForm)}
              variant="contained"
              >
              {buttonTitle}
            </LoadingButton>
          </Stack>
        </Grid>

        <Grid item xs={7}>
          <Stack spacing={2}>
            {dropdownField.value === 'CPF' && <OwnerUpsertFormInput />}
            {dropdownField.value === 'CNPJ' && (
              <OwnerFieldInput label="Proprietário" name="owner" />
            )}
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
