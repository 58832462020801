import workspaceApi from '../../api/workspace.api';
import useWorkspaceResource from './useWorkspaceResource';

export default function useWorkspaceImages() {
  const {
    resource,
    error,
    isLoading,
    createManyResources,
    deleteResource,
    getResourceById,
  } = useWorkspaceResource({
    getResource: (workspace) => workspace.images,
    api: workspaceApi.images,
    getResourceId: (image) => image.name,
    setResource: (workspace, images) => (workspace.images = images),
  });

  return {
    images: resource,
    error,
    isLoading,
    uploadImages: createManyResources,
    deleteImage: deleteResource,
    getImage: getResourceById,
  };
}
