import '../../../shared/yup-locale';

import TruckComponentInput, {
  truckTrailerSchema,
} from '../TruckComponentInput';

import * as yup from 'yup';
import ObjectArrayInput from '../ObjectArrayInput';

export const truckTrailerFormSchema = yup
  .array()
  .of(truckTrailerSchema.required());

export default function TruckTrailerForm() {
  return (
    <>

      <ObjectArrayInput
        name="truckTrailers"
        itemDefaultValue={{
          antt:         undefined,
          crlv:         undefined,
          owner:        undefined,
          crlvExercise: undefined,
          crlvState:    undefined,
        }}
        formLabelPrefix="Carreta"
        buttonLabelSufix="Carreta"
        ItemForm={TruckComponentInput}
        ItemFormProps={{ hideLicense: true }}
        min={0}
        max={Infinity}
      />
    </>
  );
}
