import useYupForm from '../../hooks/useYupForm';
import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { DropdownFieldInput, TextFieldInput } from '../../components/Form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import RegistrationFormComponentInput from './DocumentsComponentInput';
import DocumentForm from './DocumentsForm';
import CompanyReferencesComponentInput from './CompanyReferencesComponentInput';
import PersonalReferenceComponentInput from './PersonalReferenceComponentInput';
import TextField from '@mui/material/TextField';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import CheckboxInput from '../../components/Form/CheckboxInput';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ThreeStateCheckbox from '../../components/Form/ThreeStateCheckbox';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import formApi from '../../api/form.api';
import useAuthSWR from '../../hooks/useAuthSWR';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import RadioComponentInput from '../../components/Form/RadioComponentInput';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import ExperienceTimeComponent from './ExperienceTimeComponent';
import Paper from '@mui/material/Paper';
const options = new Map([
  ['AGGREGATED', 'Agregado'],
  ['REGISTERED', 'Registrado'],
  ['COMISSION', 'Comissão'],
]);

const dataSchema = yup.object({
  approved: yup.boolean().nullable(),
  reprovedReason: yup.string().nullable(),
  score: yup.number().nullable(),
  isCnpj: yup.boolean().nullable(),
  driverInfo: yup.object({
    name: yup.string().nullable(),
    licensePlate: yup.string().nullable(),
    origin: yup.string().nullable(),
    destiny: yup.string().nullable(),
  }),
  documents: yup.array().of(
    yup.object({
      nameOfPrimary: yup.string().nullable(),
      cityOfPrimary: yup.string().nullable(),
      nameOfSecondary: yup.string().nullable(),
      cityOfSecondary: yup.string().nullable(),
    })
  ),
  companyReferences: yup.array().of(
    yup.object({
      name: yup.string().nullable(),
      licensePlate: yup.boolean().nullable(),
      travelQuantity: yup.number().nullable(),
      product: yup.string().nullable(),
      date: yup.string().nullable(),
    })
  ),
  personalReferences: yup.array().of(
    yup.object({
      referenceName: yup.string().nullable(),
      kinship: yup.string().nullable(),
      name: yup.boolean().nullable(),
      birthDate: yup.boolean().nullable(),
      address: yup.boolean().nullable(),
      parents: yup.boolean().nullable(),
      color: yup.boolean().nullable(),
    })
  ),
  extras: yup.object({
    pis: yup.boolean().nullable(),
    blackList: yup.boolean().nullable(),
    photos: yup.boolean().nullable(),
    pix: yup.boolean().nullable(),
    bankAccount: yup.boolean().nullable(),
  }),
  driverReference: yup.object({
    reference: yup.string().nullable(),
    experienceTime: yup.number().nullable(),
    anttDate: yup.string().nullable(),
    shaftsQuantity: yup.number().nullable(),
  }),
  responsibleReferences: yup.object({
    name: yup.string().nullable(),
    type: yup.string().nullable(),
    experienceTime: yup.number().nullable(),
    truckQuantity: yup.number().nullable(),
  }),
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function ViewForm() {
  const pathArray = window.location.pathname.split('/');
  const { user: me } = useAuth();
  const [translated, setTranslated] = useState('');
  const [experienceObject, setExperienceObject] = useState({});
  const [yearsState, setYearsState] = useState();
  const [monthsState, setMonthsState] = useState();
  const [daysState, setDaysState] = useState();
  const { data: formData } = useAuthSWR(
    `registration-form/${pathArray[2]}`,
    {},
    {
      refreshInterval: 1000 * 15,
      revalidateIfStale: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      revalidateOnMountComplete: false,
    },
    pathArray === undefined
  );

  const documentMethod = useYupForm(dataSchema);

  const enableReasonTextField =
    documentMethod.watch('reprovedReason') === 'Outros';
  const isApprovedChecked = documentMethod.watch('approved') === false;
  const isGRChecked = documentMethod.watch('gr') === true;
  const isPJChecked = documentMethod.watch('pj') === true;
  useEffect(() => {
    if (!formData) return;

    if (formData.responsibleReference.type == 'AGGREGATED')
      setTranslated('Agregado');
    else if (formData.responsibleReference.type == 'REGISTERED')
      setTranslated('Registrado');
    else if (formData.responsibleReference.type == 'COMISSION')
      setTranslated('Comissão');
    else setTranslated(null);

    const years = Math.floor(
      formData.responsibleReference.experienceTime / 365
    );
    const months = Math.floor(
      (formData.responsibleReference.experienceTime % 365) / 30
    );
    const days = Math.floor(
      (formData.responsibleReference.experienceTime % 365) % 30
    );

    setYearsState(years);
    setMonthsState(months);
    setDaysState(days);
    setExperienceObject({ years, months, days });
    documentMethod.reset(formData);

    /* if (formData.responsibleReference.type == 'AGGREGATED')
      setTranslated('Agregado');
    else if (formData.responsibleReference.type == 'REGISTERED')
      setTranslated('Registrado');
    else if (formData.responsibleReference.type == 'COMISSION')
      setTranslated('Comissão');
    else setTranslated(null); */
  }, [formData]);

  const navigate = useNavigate();

  const validator = me?.sectors.some(
    (e) =>
      e.id == '4c8bc476-9288-4c91-a6a6-e7a18e1691b9' ||
      e.id == 'af0d731f-0d7f-461e-9bb1-b6a4a7730321' ||
      e.id == '5994a850-8ad2-45ac-accf-337c7f58a2b7'
  );

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            color="linearProgress"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.primary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
        {validator == true ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/registrationForm/${pathArray[2]}`)}
          >
            Editar
          </Button>
        ) : (
          <></>
        )}{' '}
      </Box>
    );
  }

 

  return (
    <>
      <FormProvider {...documentMethod}>
        <Grid container spacing={2}>
          <Box sx={{ width: '75%', ml: 'auto', mr: 'auto' }}>
            <LinearProgressWithLabel value={formData?.progress} />
          </Box>
          <Grid item xs={12} xl={5} lg={12}>
            <Stack
              direction="row"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Stack>
                <TextFieldInput
                  size="small"
                  label="Score"
                  name="score"
                  type="number"
                  disabled
                />

                <RadioComponentInput
                  name="approved"
                  title={undefined}
                  firstLabel="Aprovado"
                  secondLabel="Reprovado"
                  row={true}
                  isDisabled={true}
                />

                {isApprovedChecked ? (
                  <DropdownFieldInput
                    size="small"
                    fullWidth
                    name="reprovedReason"
                    options={['Transportadora', 'Pessoal', 'Outros']}
                    label="Motivo"
                    disabled={true}
                  />
                ) : (
                  <></>
                )}
                {enableReasonTextField && isApprovedChecked ? (
                  <TextFieldInput
                    sx={{ mt: '10px' }}
                    size="small"
                    name="other"
                    label="Outros"
                    disabled
                  />
                ) : (
                  <></>
                )}
              </Stack>
              <Divider orientation="vertical" flexItem sx={{ marginX: 5 }} />

              <Stack spacing={2}>
                <RadioComponentInput
                  name="gr"
                  title="GR"
                  firstLabel="Aprovado"
                  secondLabel="Reprovado"
                  row={false}
                  isDisabled={true}
                />
                <RadioComponentInput
                  name="isCnpj"
                  title="PJ"
                  firstLabel="Sim"
                  secondLabel="Não"
                  row={false}
                  isDisabled={true}
                />
              </Stack>
              <Stack alignItems="center" sx={{ ml: '10px' }}>
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Outros
                </Typography>
                <FormGroup name="reprovedReason">
                  <FormControlLabel
                    label="PIS"
                    control={
                      <ThreeStateCheckbox
                        isDisabled={true}
                        name="extras.pis"
                        label="PIS"
                        indeterminate={true}
                        onClick={(e) => e.target.checked}
                      />
                    }
                  />
                  <FormControlLabel
                    label="BlackList"
                    control={
                      <ThreeStateCheckbox
                        isDisabled={true}
                        name="extras.blacklist"
                        label="BlackList"
                        onClick={(e) => e.target.checked}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Fotos"
                    control={
                      <ThreeStateCheckbox
                        isDisabled={true}
                        name="extras.photos"
                        label="Fotos"
                        onClick={(e) => e.target.checked}
                      />
                    }
                  />
                  <FormControlLabel
                    label="PIX"
                    control={
                      <ThreeStateCheckbox isDisabled={true} name="extras.pix" />
                    }
                  />

                  <FormControlLabel
                    label="Conta Bancária"
                    control={
                      <ThreeStateCheckbox
                        isDisabled={true}
                        name="extras.bankAccount"
                      />
                    }
                  />
                </FormGroup>
              </Stack>
              <Stack
                sx={{
                  width: '25%',
                  p: 2,
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
                spacing={1}
                component={Paper}
              >
                <Typography textAlign="center" sx={{ width: '100%' }}>
                  Observações
                </Typography>

                <TextFieldInput
                  size="small"
                  disabled={true}
                  name="observations.documents"
                  label="Documentos"
                />

                <TextFieldInput
                  size="small"
                  disabled
                  name="observations.form"
                  label="Geral"
                />

                <TextFieldInput
                  disabled
                  size="small"
                  name="observations.bankAccount"
                  label="Conta bancária"
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} xl={6} sx={{ marginX: '15px' }}>
            <Stack
              spacing={2}
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              direction="row"
            >
              <Stack
                sx={{
                  width: '25%',
                  p: 2,
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
                spacing={1}
                component={Paper}
              >
                <FormLabel
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  Motorista
                </FormLabel>

                <TextFieldInput
                  size="small"
                  name="driverInfo.name"
                  label="Nome do motorista"
                  disabled
                />
                <TextFieldInput
                  size="small"
                  name="driverInfo.licensePlate"
                  label="Placa"
                  disabled={true}
                />
                <TextFieldInput
                  size="small"
                  name="driverInfo.origin"
                  label="Origem"
                  disabled
                />
                <TextFieldInput
                  size="small"
                  name="driverInfo.destiny"
                  label="Destino"
                  disabled
                />
              </Stack>

              <Stack
                sx={{
                  width: '25%',
                  p: 2,
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
                spacing={1}
                component={Paper}
              >
                <FormLabel
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  Referência do motorista
                </FormLabel>
                <TextFieldInput
                  size="small"
                  name="driverReference.reference"
                  label="Como chegou até nós"
                  disabled
                />
                <TextFieldInput
                  size="small"
                  name="driverReference.experienceTime"
                  label="Experiência"
                  type="number"
                  disabled
                />
                <DatePickerInput
                  sx={{ width: '100%' }}
                  name="driverReference.anttDate"
                  label={'Data ANTT'}
                  isDisabled={true}
                  renderInput={(params) => (
                    <TextField size="small" disabled {...params} />
                  )}
                />
                <TextFieldInput
                  size="small"
                  name="driverReference.shaftsQuantity"
                  label="Eixos"
                  type="number"
                  disabled
                />
              </Stack>
              <Stack
                sx={{
                  width: '25%',
                  display: ' flex',
                  p: 2,
                  justifyContent: 'space-around',
                }}
                spacing={1}
                component={Paper}
              >
                <FormLabel
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  Último manifesto
                </FormLabel>
                <TextFieldInput
                  size="small"
                  name="lastManifest.companyName"
                  label="Empresa"
                  disabled
                />
                <DatePickerInput
                  name="lastManifest.date"
                  label={'Data'}
                  isDisabled={true}
                  renderInput={(params) => (
                    <TextField size="small" disabled {...params} />
                  )}
                />
                <TextFieldInput
                  size="small"
                  name="lastManifest.origin"
                  label="Origem"
                  disabled
                />
                <TextFieldInput
                  size="small"
                  name="lastManifest.destiny"
                  label="Destino"
                  disabled
                />
                <TextFieldInput
                  size="small"
                  name="lastManifest.product"
                  label="Produto"
                  disabled
                />
              </Stack>
              <Stack
                textAlign="center"
                sx={{
                  width: '25%',
                  display: ' flex',
                  p: 2,
                  justifyContent: 'space-around',
                }}
                spacing={1}
                component={Paper}
              >
                <FormLabel
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  Referência do responsável
                </FormLabel>
                <TextFieldInput
                  size="small"
                  name="responsibleReference.name"
                  label="Nome"
                  disabled
                />
                {/* <TextFieldInput
                  size="small"
                  fullWidth
                    name="responsibleReference.type" 
                  value={translatedType(formData).substring(1) || ''}
                  disabled
                /> */}
                <TextField
                  value={translated ?? ''}
                  size="small"
                  label="Tipo"
                  disabled
                />
                <TextField
                  value={daysState ?? ''}
                  size="small"
                  label="Dias"
                  disabled
                />
                <TextField
                  value={monthsState ?? ''}
                  size="small"
                  label="Mêses"
                  disabled
                />
                <TextField
                  label="Anos"
                  value={yearsState ?? ''}
                  size="small"
                  disabled
                />
                {/*
                experienceTime:
                  Number(experieceTime.years) * 360 +
                  Number(experieceTime.months) * 30 +
                  Number(experieceTime.days),
              */}

                {/*  <ExperienceTimeComponent
                  name="responsibleReference.experieceTime"
                  isDisabled={true}
                /> */}

                <TextFieldInput
                  size="small"
                  disabled
                  name="responsibleReference.truckQuantity"
                  label="Quantidade de caminhões"
                  type="number"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid container xs={12} justifyContent="space-around" sx={{ mt: 5 }}>
            <Grid item xs={3} component={Paper} sx={{ p: 3 }}>
              <DocumentForm
                name="documents"
                itemDefaultValue={{
                  primaryName: undefined,
                  secondaryName: undefined,
                  secondaryCity: undefined,
                  primaryCity: undefined,
                }}
                isDisabled={true}
                formLabelPrefix="Documento"
                buttonLabelSufix="documentação"
                ItemForm={RegistrationFormComponentInput}
                isRemovible={false}
                isAddable={false}
                ItemFormProps={{
                  isDisabled: true,
                  label: { primary: 'Doc carreta', secondary: 'ANTT carreta' },
                }}
                min={1}
                max={Infinity}
              />
            </Grid>
            <Grid item xs={3} component={Paper} sx={{ p: 3 }}>
              <DocumentForm
                name="companyReferences"
                itemDefaultValue={{
                  name: undefined,
                  licensePlate: null,
                  travelQuantity: undefined,
                  product: undefined,
                  date: new Date(),
                }}
                isRemovible={false}
                isAddable={false}
                formLabelPrefix="Referência empresarial"
                buttonLabelSufix="ref. empresarial"
                ItemForm={CompanyReferencesComponentInput}
                ItemFormProps={{ isDisabled: true }}
                min={3}
                component={Paper}
                sx={{ p: 3 }}
                max={Infinity}
              />
            </Grid>
            <Grid item xs={3} component={Paper} sx={{ p: 3 }}>
              <DocumentForm
                name="personalReference"
                itemDefaultValue={{
                  name: undefined,
                  licensePlate: undefined,
                  travelQuantity: undefined,
                  product: undefined,
                  date: new Date(),
                }}
                formLabelPrefix="Referência Pessoal"
                buttonLabelSufix="ref. pessoal"
                ItemForm={PersonalReferenceComponentInput}
                ItemFormProps={{ isDisabled: true }}
                min={3}
                isRemovible={false}
                isAddable={false}
                max={Infinity}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
