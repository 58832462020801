import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import notificationApi from '../../../api/notification.api';
import { ticketsApi } from '../../../api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useAuth } from '../../../contexts/AuthContext';

const TicketCardText = (props) => (
  <Typography
    fontSize={12}
    textTransform="uppercase"
    my={0.5}
    textAlign="left"
    variant="body1"
    component="div"
    {...props}
  >
    {props.children}
  </Typography>
);

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export default function CondensedTicket({
  id,
  driverName,
  clientName,
  licensePlate,
  originCity,
  destinyCity,
  status,
  task,
  ticketId,
  position,
  isPriority,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const open = Boolean(anchorEl);
  const { user } = useAuth();

  const sendSolicitation = async () => {
    try {
      await notificationApi.createSolicitation({ ticketId });

      setAnchorEl(null);

      setMessage('Pedido enviado !');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 400) {
        setMessage('Pedido já feito !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    } finally {
      setAnchorEl(null);
    }
  };

  async function handleClose() {
    setAnchorEl(null);
  }

  async function changePriority({ value }) {
    try {
      await ticketsApi.setPriority({ ticketId, value });
      setAnchorEl(null);
      setMessage('Prioridade Alterada.');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Ticket não existe !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    } finally {
      setAnchorEl(null);
    }
  }

  let color;

  if (status == 'DECLINED') color = '#FFA07A';
  if (status == 'IN_PROGRESS') color = '#EEE8AA';
  if (status == 'NOT_STARTED') color = '#FFFFFF';
  if (status == 'SEND_SECTOR') color = '#ffff7a';
  if (status == 'WAITING_RETURN') color = '#ffc26c';
  if (status == 'WAITING_ADJUSTMENT') color = '#ff6347';
  if (status == 'FINISHED') color = '#84fa84';
  let borderColor = isPriority ? '#FF0000' : '#ccc';
  let borderWidth = isPriority ? 2.5 : 1;

  return (
    <>
      <Card variant="outlined" sx={{ borderColor: borderColor, borderWidth }}>
        <CardActionArea
          ref={anchorEl}
          onClick={() => navigate(`/tickets/${id}`, { state: { task } })}
          onContextMenu={(e) => {
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}
          sx={{ p: 0 }}
        >
          <CardContent
            sx={{
              minHeight: '9em',
              px: 1,
              py: 0.5,
              backgroundColor: color,
            }}
          >
            <TicketCardText>{driverName}</TicketCardText>
            <TicketCardText>{licensePlate}</TicketCardText>
            <TicketCardText>{originCity}</TicketCardText>
            <TicketCardText>{destinyCity}</TicketCardText>
            <TicketCardText>{clientName}</TicketCardText>
            <TicketCardText textAlign="right" fontSize={10}>
              {position}
            </TicketCardText>
          </CardContent>
        </CardActionArea>
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ color: '#ffdf23' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={sendSolicitation}>Pedir Prioridade</MenuItem>
        {user?.sectors.some(
          (s) => s.name.startsWith('Lder') || s.name.startsWith('Gerente')
        ) && (
          <>
            <MenuItem onClick={() => changePriority({ value: true })}>
              Atribuir Prioridade
            </MenuItem>
            <MenuItem onClick={() => changePriority({ value: false })}>
              Remover Prioridade
            </MenuItem>
          </>
        )}
      </Menu>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
