import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Container from '@mui/material/Container';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import PrivateRoute from '../../hoc/PrivateRoute';

import { Outlet } from 'react-router-dom';
import { useEditWorkspace } from '../../contexts/EditWorkspaceContext';

export const steps = [
  'Motorista',
  'Cavalo',
  'Carreta',
  'Conta Bancária',
  'Referências',
  'Fotos',
];

function EditWorkspaceForm() {
  const { step } = useEditWorkspace();

  return (
    <Container maxWidth="lg">
      <Stepper activeStep={step - 1} alternativeLabel sx={{ mb: 3 }}>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Container maxWidth="sm">
        <Paper sx={{ mb: 4, pt: 2, pb: 3, px: 4, mx: 3 }}>
          <Typography
            sx={{ mt: 1.5, mb: 2.5 }}
            fontSize={23}
            align="center"
            textTransform="uppercase"
          >
            {steps[step - 1]}
          </Typography>
          <Stack spacing={2}>
            <Outlet />
          </Stack>
        </Paper>
      </Container>
    </Container>
  );
}

export default PrivateRoute(EditWorkspaceForm);
