import { useWorkspaceAntts } from '../../../hooks/useWorkspaces';
import ResourceList from '../WorkspaceResourceDialog/ResourceList';
import DeleteAnttDialog from './DeleteAnttDialog';
import AnttListItem from './AnttListItem/AnttListItem';

export default function AnttList({ selected, onSelect, onDelete }) {
  const { antts, isLoading } = useWorkspaceAntts();

  if (isLoading) return <></>;
  return (
    <>
      <ResourceList
        resources={antts}
        selected={selected}
        onSelect={onSelect}
        onDelete={onDelete}
        ResourceListItem={AnttListItem}
        DeleteResourceDialog={DeleteAnttDialog}
        alertText="Nenhuma ANTT foi criada ainda."
      />
    </>
  );
}
