import Checkbox from '@mui/material/Checkbox';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import { useWorkspace } from '../../../contexts/WorkspaceContext';
import { useModalImage } from '../../../contexts/ModalImageContext';
import ImageMoreButton from './ImageMoreButton';
import Box from '@mui/material/Box';
import PdfIcon from '../../../pages/Drivers/PdfIcon';

export default function ImageListInput({
  value,
  onSelect,
  onRemove,
  onDelete,
}) {
  const { workspace, isLoading } = useWorkspace();
  const selectImage = useModalImage();

  if (isLoading) return <></>;
  return (
    <ImageList cols={3} sx={{ height: '70vh', margin: 0, padding: 0 }}>
      {workspace.images.map(({ name, uploadedAt }) => {
        const parts = name.split('.');
        const extension = parts[parts.length - 1];
        const isPdf = extension === 'pdf';

        return (
          <ImageListItem key={name}>
            {isPdf ? (
              <PdfIcon />
            ) : (
              /* eslint-disable-next-line */
              <img
                onClick={() => isPdf && selectImage(name)}
                alt={name}
                src={`${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${workspace.id}/images/${name}`}
              />
            )}

            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              title={name}
              subtitle={new Date(uploadedAt).toLocaleDateString('pt-BR')}
              position="top"
              actionIcon={
                <Box display="flex" flexDirection="row">
                  <Checkbox
                    checked={value.has(name)}
                    onChange={(e, checked) =>
                      checked ? onSelect(name) : onRemove(name)
                    }
                    sx={{ color: '#fff' }}
                    color="secondary"
                  />
                  <ImageMoreButton
                    name={name}
                    onDelete={onDelete}
                    imageUrl={`${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${workspace.id}/images/${name}`}
                  />
                </Box>
              }
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}
