import workspaceApi from '../../api/workspace.api';
import useWorkspaceResource from './useWorkspaceResource';

export default function useWorkspaceOwners() {
  const {
    resource,
    error,
    isLoading,
    createResource,
    deleteResource,
    updateResource,
    getResourceById,
  } = useWorkspaceResource({
    getResource: (workspace) => workspace.owners,
    api: workspaceApi.owners,
    getResourceId: (owner) => owner.id,
    setResource: (workspace, owners) => (workspace.owners = owners),
  });

  return {
    owners: resource,
    driver: resource?.find((owner) => owner.name !== undefined),
    error,
    isLoading,
    updateOwner: updateResource,
    addOwner: createResource,
    deleteOwner: deleteResource,
    getOwner: getResourceById,
  };
}
