import WorkspaceFormButtons from '../WorkspaceFormButtons';
import '../../../shared/yup-locale';

import TruckComponentInput, {
  truckTractorSchema,
} from '../TruckComponentInput';

export const truckTractorFormSchema = truckTractorSchema;

export default function TruckTractorForm() {
  return (
    <>
      <TruckComponentInput name="truckTractor" />
    </>
  );
}
