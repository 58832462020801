import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ImageItem from './ImageItem';

export default function ModalDriverComponent({
  content,
  images,
  children,
  modal,
}) {
  const imagesMap = [];

  images.forEach((item) => {
    item.img.forEach((i) => {
      imagesMap.push(<ImageItem key={i} name={i} onClick={modal} />);
    });
  });

  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#EDEDED',
          borderRadius: 0,
        }}
        elevation={0}
      >
        <Grid container xs={12}>
          <Grid item xs={8}>
            <ImageList
              sx={{
                width: '100%',
                height: 450,
                ml: 2,
                '&::-webkit-scrollbar': {
                  backgroundColor: '#D9E3E8',
                },
              }}
              cols={3}
              rowHeight={164}
            >
              {imagesMap}
            </ImageList>
          </Grid>
          <Grid item xs={4}>
            {content ? (
              <>
                <Paper
                  sx={{
                    p: 2.5,
                    m: 3,
                    width: 'auto',
                    height: 'auto',
                  }}
                >
                  {content.map((a) => {
                    return (
                      <Typography key={a} sx={{ py: 1 }}>
                        {' '}
                        <strong>{a.name}</strong>
                        {a.text}
                      </Typography>
                    );
                  })}
                </Paper>
                {children}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
