import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import useAuthSWR from '../../hooks/useAuthSWR';
import CreateClientForm from './CreateClientDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import { FormProvider } from 'react-hook-form';
import Slide from '@mui/material/Slide';
import commercialApi from '../../api/commercial.api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditClientForm from './EditClientForm';
import ContactReportForm from './ContactReportForm';
import usePagination from '../../hooks/usePagination';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Pagination from '../Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ClientContactedHistory from './ClientContactedHistory';
import periodMap from './periodMap';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const createClientSchema = yup.object({
  name: yup.string().label('Conteúdo').min(2).required('Cliente é obrigatório'),
  phonenumber: yup.string().required('número de telefone é obrigatório'),
  period: yup.string(),
});

const reportSchema = yup.object({
  date: yup.string(),
  user: yup.object().nullable(),
});

export default function CommercialList() {
  const resetValue = {
    name: '',
    phonenumber: '',
    user: null,
    period: null,
  };

  const { handleSubmit, reset, ...formMethods } = useYupForm(
    createClientSchema,
    resetValue
  );
  const reportMethod = useYupForm(reportSchema, { date: new Date() });
  const pagination = usePagination({});
  const [interacted, setInteracted] = useState(undefined);
  const [selectedUserToFilter, setSelectedUserToFilter] = useState(undefined);
  const [uniqClients, setUniqClients] = useState([]);

  const { data: clients, mutate } = useAuthSWR('/contacts', {
    interacted,
    responsibleUserId: selectedUserToFilter,
    page: pagination.page,
    limit: 20,
  });

  //remove os clientes duplicados e gera um novo array de clientes
  useEffect(() => {
    if (clients) {
      const uniqClients = Array.from(
        new Set(clients.map((client) => client.id)),
        (id) => clients.find((client) => client.id === id)
      );
      setUniqClients(uniqClients);
    }
  }, [clients]);

  const { data: users } = useAuthSWR('/users');
  const navigate = useNavigate();
  const [isEditClientDialogOpen, setIsEditClientDialogOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [contactId, setContactId] = useState();
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const [isDisasbleClientOpen, setIsDisasbleClientOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientData, setClientData] = useState();
  const [reportParamsDialog, setReportParamsDialog] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [contactData, setContactData] = useState();
  const [isContactHisotoryHidden, setIsContactHisotoryHidden] = useState(false);

  if (!users) return <></>;
  //criar clientes
  async function onSubmit({ name, phonenumber, period, user }) {
    try {
      const existingClient = clients.find(
        (client) => client.name === name && client.phonenumber === phonenumber
      );

      if (existingClient) {
        console.log('cliente ja existe');
      }

      await mutate(
        async (client) => {
          await commercialApi.createClient({
            name,
            phonenumber,
            period,
            userId: user.id,
          });
          return client;
        },
        { revalidate: true, rollbackOnError: true }
      );

      setIsOpen(false);
      reset(resetValue);
      setMessage('Cliente Criado !');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  //desativar cliente
  async function inactiveContact() {
    try {
      await commercialApi.inactiveContact({
        contactId,
      });
      setMessage('Cliente desativado !');
      setOpenStatusSuccess(true);
      setIsDisasbleClientOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  //liberar relatório
  async function generateContactReport({ date, user }) {
    try {
      const data = await commercialApi.generateContactReport({
        date: new Date(Date.parse(date)),
        userId: user?.id,
      });

      setMessage('Relatórido Liberado !');
      setOpenStatusSuccess(true);
      setIsDisasbleClientOpen(false);
      navigate('/modules/commercial/report', {
        state: { report: data, reportDate: new Date(date) },
      });
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }
  //ação quando o usuário clica com o botão direito do mouse no cliente
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  //fecha o contextMenu
  const handleClose = () => {
    setContextMenu(null);
  };
  //manipula a visibilidade do do histórico de contatos
  const handleContactHistoryVisibilityChange = () => {
    setIsContactHisotoryHidden(!isContactHisotoryHidden);
  };

  return (
    <>
      <Container>
        <Grid container mb={2}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Pagination
              sx={{ width: '40%' }}
              {...pagination.register()}
              disableRight={clients?.length < 20}
            />
          </Grid>

          <Grid item container xs={6} pl={2} columnSpacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={[
                  { value: true, label: 'Contatado' },
                  { value: false, label: 'Pendente' },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Situação" />
                )}
                onChange={(_event, option) => {
                  setInteracted(option === null ? undefined : option.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={users}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Usuário" />
                )}
                onChange={(_event, option, reason) => {
                  if (reason == 'clear') setSelectedUserToFilter(undefined);
                  else setSelectedUserToFilter(option.id);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {uniqClients ? (
          <Grid container columnSpacing={4}>
            {uniqClients.map((value) => {
              const labelId = `${value}`;

              return (
                <Grid item xs={6} key={value.id}>
                  <ListItem
                    key={value.id}
                    disablePadding
                    onClick={() => {
                      setContactData(value);
                      handleContactHistoryVisibilityChange();
                    }}
                    onContextMenu={() => {
                      handleContextMenu(event);
                      setContactId(value.id);
                      setClientName(value.name);
                      setClientData(value);
                    }}
                    style={{ cursor: 'context-menu' }}
                    disabled={value.period === null}
                  >
                    <ListItemButton>
                      <Grid container>
                        <Grid item xs={5}>
                          <ListItemText
                            id={labelId}
                            primary={value.name}
                            secondary={value.phoneNumber}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={5}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <ListItemText
                            primary={value.responsibleUser.username}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          backgroundColor="red"
                        >
                          <Chip
                            label={periodMap.get(value.period)}
                            color={
                              value.isPendent
                                ? 'pendentContact'
                                : 'overduePendingContact'
                            }
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </ListItem>

                  <Divider />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Container>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            setContextMenu(null);
            setIsDisasbleClientOpen(true);
          }}
        >
          Desativar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setContextMenu(null);
            setIsEditClientDialogOpen(true);
          }}
        >
          Editar cliente
        </MenuItem>
      </Menu>

      <Dialog
        fullWidth={true}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>{'Criar um novo cliente'}</DialogTitle>
        <DialogContent>
          <FormProvider {...formMethods}>
            <CreateClientForm users={users} />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancelar</Button>
          <Button onClick={handleSubmit(onSubmit)}>Criar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={isDisasbleClientOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsDisasbleClientOpen(false)}
      >
        <DialogTitle>{'Desativar cliente'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja desativar o cliente {clientName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDisasbleClientOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={() => inactiveContact()}>Desativar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={isEditClientDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsEditClientDialogOpen(false)}
      >
        <DialogTitle>Editar cliente</DialogTitle>
        <DialogContent>
          <br />
          <EditClientForm users={users} clientData={clientData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditClientDialogOpen(false)}>Sair</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={reportParamsDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setReportParamsDialog(false)}
      >
        <DialogTitle>Gerar relatório</DialogTitle>
        <DialogContent>
          <br />
          <FormProvider {...reportMethod}>
            <ContactReportForm users={users} />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReportParamsDialog(false)}>Sair</Button>

          <Button
            onClick={() => {
              const reportParams = reportMethod.getValues();
              reportParams.date.setHours(0, 0, 0, 0);

              navigate('/modules/commercial/report', {
                state: {
                  reportParams,
                },
              });
            }}
          >
            Gerar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>

      <SpeedDial
        ariaLabel="admin privilegies"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          icon={<QueryStatsIcon />}
          tooltipTitle={'Gerar relatório'}
          onClick={() => setReportParamsDialog(true)}
        />
        <SpeedDialAction
          icon={<ContactPhoneIcon />}
          tooltipTitle={'Criar cliente'}
          onClick={() => setIsOpen(true)}
        />
      </SpeedDial>
      <ClientContactedHistory
        isHiddend={isContactHisotoryHidden}
        contactId={contactData?.id}
        onHidden={handleContactHistoryVisibilityChange}
      />
    </>
  );
}
