import Stack from '@mui/material/Stack';
import { useController } from 'react-hook-form';
import TruckComponentInput from './TruckComponentInput';

export default function Cavalo({ name }) {
  const {
    field: { onChange, value },
  } = useController({
    name,
    defaultValue: { ispropriety: false, isLegalPerson: false },
  });

  return (
    <Stack spacing={1}>
      <TruckComponentInput
        value={value}
        onChange={(key, newValue) => {
          value[key] = newValue;

          onChange({ target: { value: { ...value } } });
        }}
      />
    </Stack>
  );
}
