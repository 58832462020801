import api from './api';

async function createSector({ name }) {
  const res = await api.post('/sectors', {
    name,
  });
  return res.data;
}

async function updateSector({ name, sectorId }) {
  const res = await api.put(`/sectors/${sectorId}/info`, {
    name,
  });
  return res.data;
}
async function deleteSector({ sectorId }) {
  const res = await api.delete(`/sectors/${sectorId}`);

  return res.data;
}
async function setTaskPermission({ permissions, sectorId }) {
  const res = await api.put(`/sectors/${sectorId}/task-permissions`, {
    permissions,
  });
  return res.data;
}
async function getSectors() {
  const res = await api.get('/sectors');

  return res.data;
}
async function getTaskPermissionsOfSector({ sectorId }) {
  const res = await api.get(`/sectors/${sectorId}/task-permissions`);

  return res.data;
}

async function getAllSolicitations({ sectorId }) {
  const res = await api.get(`/sectors/${sectorId}/solicitations`);

  return res.data;
}
async function SetTasksSectorIsResponsibleFor({ sectorId, tasksIds }) {
  const res = await api.put(`sectors/${sectorId}/tasks`, {
    tasksIds,
  });
  return res.data;
}
async function setSectorShifts({ shifts, sectorId }) {
  const res = await api.put(`/sectors/${sectorId}/shifts`, {
    shifts,
  });
  return res.data;
}
const sectorApi = {
  createSector,
  deleteSector,
  updateSector,

  getSectors,
  setTaskPermission,
  getTaskPermissionsOfSector,
  getAllSolicitations,
  SetTasksSectorIsResponsibleFor,
  setSectorShifts,
};
export default sectorApi;
