import { useState, useMemo, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import useAuthSWR from '../hooks/useAuthSWR';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DatePickerInput from '../components/Form/DatePickerComponent';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../hooks/useYupForm';
import TextField from '@mui/material/TextField';
import NewReboundsComponent from './NewReboundsComponent';
import DriverFeedbackComponent from './DriverFeedbackComponent';
import ShipperRanking from './ShipperRanking';
import recommendationApi from '../api/recommendation.api';
import { api } from '../api';
import RoomsRanking from './RoomsRanking';
import BoardingReboundReport from './BoardingReboundReport';
import ShipperReport from './ShipperReport';
import BaseLayout from '../layouts/BaseLayout';
const dateToGetReboundsSchema = yup.object({
  date: yup.string(),
});

export default function FlowOfTrucks() {
  const dateToGetReboundsMethod = useYupForm(dateToGetReboundsSchema);
  const getDateInISO_8601 = () => {
    const date = new Date();
    const year = '' + date.getFullYear();
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return year + '-' + month + '%';
  };
  const d = new Date();
  const month = d.getMonth() + 1;
  const dateField =
    dateToGetReboundsMethod.watch('date') == undefined
      ? new Date()
      : dateToGetReboundsMethod.watch('date');

  const YearFormatedToField = '' + dateField.getFullYear();
  let MonthFormatedToField = '' + (dateField.getMonth() + 1);
  if (MonthFormatedToField.length < 2)
    MonthFormatedToField = '0' + MonthFormatedToField;
  const formated = YearFormatedToField + '-' + MonthFormatedToField;
  const finished = formated + '%';
  const date = dateField !== undefined ? finished : getDateInISO_8601();

  const { data: rebounds } = useAuthSWR(
    `/boarding-rebounds/${date.substring(0, date.length - 1)}/rebounds`,
    {},
    { refreshInterval: 1000 * 30 }
  );
  const { data: shipperReport } = useAuthSWR(
    `/recommendations/${date.substring(0, date.length - 1)}/ShipperReport`,
    {},
    { refreshInterval: 1000 * 30 }
  );
  const { data: manyUsers } = useAuthSWR('/users');

  const [checked, setChecked] = useState([0]);

  const { data: report } = useAuthSWR(
    `/recommendations/${date.substring(0, date.length - 1)}/Report`
  );

  const { data: boardingRecommendations } = useAuthSWR(
    `/recommendations/ReboundRecommendation/${date.substring(
      0,
      date.length - 1
    )}`,
    {},
    { refreshInterval: 1000 * 30 }
  );

  const usersMap = new Map();
  if (manyUsers === undefined) return <></>;
  for (const user of manyUsers) {
    usersMap.set(user.id, user.username);
  }
  if (!rebounds) return <></>;
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <Grid
      container
      xs={12}
      spacing={2}
      sx={{
        display: 'flex',
        minHeight: '70vh',
        justifyContent: 'space-around',
      }}
    >
      <Grid
        item
        xs={9}
        direction="column"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <FormProvider {...dateToGetReboundsMethod}>
          <Box>
            <DatePickerInput
              sx={{ width: '100%', alignItems: 'center' }}
              name="date"
              label={'Data'}
              views={['month', 'year']}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box sx={{ width: '100%', alignItems: 'center' }}>
            <BoardingReboundReport report={report} />

            <ShipperReport report={shipperReport} />
          </Box>
        </FormProvider>
      </Grid>

      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <DriverFeedbackComponent rebounds={boardingRecommendations} />
      </Grid>
    </Grid>
  );
}
