import { Controller, useController, useFormContext } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioComponentInput(props) {
  const { setValue } = useFormContext();
  const { field, fieldState, formState } = useController({ name: props.name });

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>{props.title}</FormLabel>
      <RadioGroup row={props.row}>
        <FormControlLabel
          label={props.firstLabel}
          checked={field.value === true}
          disabled={props.isDisabled ? props.isDisabled : false}
          onClick={() => setValue(props.name, true)}
          control={<Radio />}
        />

        <FormControlLabel
          label={props.secondLabel}
          checked={field.value === false}
          disabled={props.isDisabled ? props.isDisabled : false}
          onClick={() => setValue(props.name, false)}
          control={<Radio />}
        />
      </RadioGroup>
    </FormControl>
  );
}
