import Paper from '@mui/material/Paper';

export default function TicketInfoSurface({ children }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2,
        p: 5,
        minHeight: '100%',
      }}
    >
      {children}
    </Paper>
  );
}
