import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import PdfIcon from './PdfIcon';
import { Link, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
export default function ImageItem({ name, onClick }) {
  const { cpf } = useParams();

  const extension = useMemo(() => {
    const parts = name.split('.');
    const extension = parts[parts.length - 1];

    return extension;
  }, [name]);
  const isPdf = extension === 'pdf';

  return (
    <Tooltip
      placement="bottom"
      title={<Typography fontSize={16}>{name}</Typography>}
    >
      <ImageListItem
        sx={{ overflow: 'hidden' }}
        onClick={isPdf ? undefined : () => onClick(name)}
      >
        {isPdf ? (
          <PdfIcon />
        ) : (
          <InnerImageZoom
            zoomScale="2"
            zoomType="hover"
            zoomPreload={true}
            alt={name}
            src={`${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${cpf}/images/${name}`}
          />
        )}

        {isPdf && (
          <ImageListItemBar
            sx={{ background: 'transparent' }}
            position="top"
            actionIcon={
              <Stack direction="row" alignItems="center" display="flex">
                <IconButton
                  onClick={() => {
                    const a = document.createElement('a');
                    a.target = '_blank';
                    a.href = `${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${cpf}/images/${name}/viewer`;
                    a.click();
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const a = document.createElement('a');
                    a.download = true;
                    a.target = '_blank';
                    a.href = `${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${cpf}/images/${name}`;
                    a.click();
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Stack>
            }
          />
        )}
      </ImageListItem>
    </Tooltip>
  );
}
