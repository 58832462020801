import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Campo obrigatório.',
  },
  number: {
    min: ({ label, min }) => `${label} deve ser maior que ${min}`,
    max: ({ label, max }) => `${label} deve ser menor que ${max}`,
  },
  object: {},
  array: {
    min: ({ label, min }) => `${label} deve ter no mínimo ${min} itens`,
    max: ({ label, max }) => `${label} deve ter no máximo ${max} itens`,
  },
  boolean: {},
  date: {},
  string: {
    min: ({ label, min }) => `${label} deve ter pelo menos ${min} caracteres`,
    max: ({ label, max }) => `${label} deve ter no máximo ${max} caracteres`,
    length: ({ label, length }) =>
      `${label} deve ter exatamente ${length} caracteres`,
  },
});
