import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DriverList from '../Drivers/DriverControlled';
import Pagination from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import PrivateRoute from '../../hoc/PrivateRoute';

import { useState } from 'react';
import usePagination from '../../hooks/usePagination';

function ViewWorkspaces() {
  const [query, setQuery] = useState('');
  const pagination = usePagination({ defaultLimit: 10, defaultPage: 0 });
  const [isFinished, setIsFinished] = useState(false);

  return (
    <Container maxWidth="lg">
      <Paper
        sx={{
          py: 2,
          px: '16px',
          mb: '24px',
          ml: 'auto',
          mr: 'auto',
          pt: '16px',
          width: '50%',
        }}
      >
        <Grid container>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={4}
          >
            <Pagination {...pagination.register()} disableRight={isFinished} />
          </Grid>
          <Grid item xs>
            <SearchBar fullWidth onChange={setQuery} delay={1000} />
          </Grid>
        </Grid>
      </Paper>
      <Stack spacing={2}>
        <DriverList
          query={query}
          page={pagination.page}
          limit={pagination.limit}
          setIsFinished={setIsFinished}
        />
      </Stack>
    </Container>
  );
}

export default PrivateRoute(ViewWorkspaces);
