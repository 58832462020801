import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import useAuthSWR from '../../hooks/useAuthSWR';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import reportsApi from '../../api/reports.api';
import DetailsChart from './DetailsChart';
import AverageChart from './AverageChart';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export default function GenerateTimeReport() {
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [fromTask, setFromTask] = useState();
  const [toTask, setToTask] = useState();
  const [pieData, setPieData] = useState();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const { data: tasks } = useAuthSWR('/tasks');
  if (!tasks) return <>Carregando...</>;

  const handleChangeFromDate = (newValue) => {
    setFromDate(newValue);
  };
  const handleChangeToDate = (newValue) => {
    setToDate(newValue);
  };

  async function generateReport() {
    try {
      const data = await reportsApi.getTimeReport({
        fromDate,
        toDate,
        fromTask,
        toTask,
      });
      setPieData(data);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }

  return (
    <>
      <Container>
        <Paper elevation={1} sx={{ p: '10px' }}>
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Grid item xs={2}>
              <DateTimePicker
                label="Início"
                value={fromDate}
                onChange={handleChangeFromDate}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <DateTimePicker
                label="Fim"
                value={toDate}
                onChange={handleChangeToDate}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                size="medium"
                disableClearable
                options={tasks}
                onChange={(_event, option) => {
                  setFromTask(option.sequence);
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="Da coluna..." />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                size="medium"
                disableClearable
                options={tasks}
                getOptionLabel={(option) => option.title}
                onChange={(_event, option) => {
                  setToTask(option.sequence);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Para coluna..." />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                onClick={() => generateReport()}
              >
                Gerar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <Container sx={{ marginTop: '50px' }}>
        <AverageChart data={pieData} manyTask={tasks} />
      </Container>
      <Container sx={{ marginTop: '50px' }}>
        <DetailsChart data={pieData} manyTask={tasks} id="barChart" />
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
