import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FileDropInput from './FileDropInput';
import ImageListInput from './ImageListInput';

import { useSet } from 'react-hanger';
import React, { useEffect } from 'react';
import useWorkspaceImages from '../../../hooks/useWorkspaces/useWorkspaceImages';
import { useSnackbar } from '../../../contexts/SnackbarContext';

function WorkspacesImagesDialog({ value, onChange, onClose, open }) {
  const selectedImages = useSet(new Set());
  const { deleteImage } = useWorkspaceImages();
  const handleRequest = useSnackbar();

  useEffect(() => {
    selectedImages.setValue(new Set(value));
  }, [value]);

  function onConfirm() {
    const selectedImagesArray = Array.from(selectedImages.value);

    onChange(selectedImagesArray);
    onClose();
  }

  async function onDelete(name) {
    async function main() {
      await deleteImage(name);
      selectedImages.remove(name);
      onChange(value.filter((img) => img !== name));
    }

    return handleRequest(main);
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle>Escolher fotos</DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <FileDropInput />
          </Grid>
          <Grid item xs>
            <ImageListInput
              value={selectedImages.value}
              onRemove={selectedImages.remove}
              onSelect={selectedImages.add}
              onDelete={onDelete}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button disabled={selectedImages.value.size === 0} onClick={onConfirm}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(WorkspacesImagesDialog);
