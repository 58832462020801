import OwnerImageCollapse from './OwnerImageCollapse';
import OwnerEditCollapse from './OwnerEditCollapse';
import ResourceListItem from '../../WorkspaceResourceDialog/ResourceListItem';
import { useWorkspaceOwners } from '../../../../hooks/useWorkspaces';

export default function OwnerListItem({
  resource: owner,
  selected,
  expanded,
  onSelect,
  onExpand,
  editing,
  onEdit,
  onDelete,
}) {
  const { driver } = useWorkspaceOwners();

  return (
    <ResourceListItem
      resource={owner}
      selected={selected}
      expanded={expanded}
      onSelect={onSelect}
      onExpand={onExpand}
      editing={editing}
      onEdit={driver?.id !== owner.id ? onEdit : undefined}
      onDelete={driver?.id !== owner.id ? onDelete : undefined}
      ResourceEditCollapse={OwnerEditCollapse}
      ResourceImageCollapse={OwnerImageCollapse}
    />
  );
}
