import WorkspaceImagesDialog from './WorkspaceImagesDialog';
// import ResourceFieldInput from './ResourceFieldInput';
import TextField from '@mui/material/TextField';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import * as yup from 'yup';
import { useBoolean } from 'react-hanger';
import { useController } from 'react-hook-form';
import { IconButton, InputAdornment } from '@mui/material';

export const imageSchema = yup.array().of(yup.string().required());

export default function FileFieldInput({ name, ...props }) {
  const open = useBoolean(false);
  const { field, fieldState } = useController({ name });

  return (
    <>
      <TextField
        value={field.value}
        InputLabelProps={{
          shrink: field.value != '' && field.value != undefined,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={props.disabled}
                onClick={open.setTrue}
                edge="end"
              >
                <AddAPhotoIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        error={Boolean(fieldState.error)}
        helperText={fieldState.error?.message}
        {...props}
      />

      <WorkspaceImagesDialog
        value={field.value}
        onChange={(value) => field.onChange({ target: { value } })}
        open={open.value}
        onClose={open.setFalse}
      />
    </>
  );
}
