import { useWorkspaceOwners } from '../../../hooks/useWorkspaces';
import ResourceList from '../WorkspaceResourceDialog/ResourceList';
import DeleteOwnerDialog from './DeleteOwnerDialog';
import OwnerListItem from './OwnerListItem/OwnerListItem';

export default function OwnerList({ selected, onSelect, onDelete }) {
  const { owners, isLoading } = useWorkspaceOwners();

  if (isLoading) return <></>;
  return (
    <ResourceList
      resources={owners}
      selected={selected}
      onSelect={onSelect}
      onDelete={onDelete}
      ResourceListItem={OwnerListItem}
      DeleteResourceDialog={DeleteOwnerDialog}
    />
  );
}
