import TextFieldInput from '../Form/TextFieldInput';

function CpfCnpjInput(props) {
  function createMask(value) {
    if (!value || value.length === 0) return '999999999999999999';

    if (value.replace(/\D/g, '').length < 12) {
      return '999.999.999-999';
    } else {
      return '99.999.999/9999-99';
    }
  }

  return <TextFieldInput label="CPF/CNPJ" getMask={createMask} {...props} />;
}

export default CpfCnpjInput;
