import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from '../../api/api';

export default function Acomp() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userReports, setUserReports] = useState([]);

  useEffect(() => {
    async function execute() {
      if (selectedDate == undefined) return;

      const res = await api.get('/reports/calls', {
        params: {
          date: selectedDate,
        },
      });

      setUserReports(res.data);
    }

    execute();
  }, [selectedDate]);

  const download = function (data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const d = new Date();
    const today = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    a.setAttribute('download', `${today}-acomp.csv`);
    a.click();
  };

  const csvmaker = function (data) {
    const csvRows = [];

    csvRows.push(
      'Nome,' +
        Array(31)
          .fill(0)
          .map((v, i) => `Dia ${i + 1}`)
          .join(',') +
        ', Acumulado'
    );

    for (const obj of data) {
      const values = Object.values(obj).join(',');
      csvRows.push(values);
    }
    return csvRows.join('\n');
  };

  const get = async function () {
    const data = userReports.map((obj) => obj);
    const csvdata = csvmaker(data);
    download(csvdata);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: '20px',
          }}
        >
          <DatePicker
            sx={{ width: '25%' }}
            value={selectedDate}
            onChange={setSelectedDate}
            views={['year', 'month']}
            disableFuture
            disableHighlightToday
            renderInput={(params) => <TextField {...params} />}
          />
          <Button
            sx={{ width: '10%' }}
            variant="contained"
            color="secondary"
            onClick={() => get()}
          >
            Exportar
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: 'black',}}>
              <TableRow sx={{ py: 2}}>
                <TableCell sx={{ color: 'white', py: 2 }}>Usuário</TableCell>
                <TableCell sx={{ color: 'white', py: 2 }} align="left">
                  Acumulado
                </TableCell>
                {Array(31)
                  .fill(0)
                  .map((v, index) => (
                    <TableCell
                      align="center"
                      sx={{ color: 'white', py: 2 }}
                      key={index}
                    >
                      {index + 1}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userReports.map((user, index) => (
                <TableRow
                  sx={{
                    backgroundColor: (theme) =>
                      index % 2 ? theme.palette.action.hover : undefined,
                  }}
                  key={user.name}
                >
                  <TableCell>{user.name}</TableCell>
                  <TableCell align="left">{user.acc}</TableCell>
                  {user.points.map((point, index) => (
                    <TableCell align="center" key={index}>
                      {point}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
