import PrivateRoute from '../hoc/PrivateRoute';
import WorkspaceProvider from '../contexts/WorkspaceContext';

import { useParams } from 'react-router-dom';
import EditWorkspaceForm from '../components/EditWorkspaceForm';
import EditWorkspaceProvider from '../contexts/EditWorkspaceContext';
import ModalImageProvider from '../contexts/ModalImageContext';

function EditWorkspace() {
  const { workspaceId } = useParams();

  return (
    <WorkspaceProvider workspaceId={workspaceId}>
      <EditWorkspaceProvider>
        <ModalImageProvider>
          <EditWorkspaceForm />
        </ModalImageProvider>
      </EditWorkspaceProvider>
    </WorkspaceProvider>
  );
}

export default PrivateRoute(EditWorkspace);
