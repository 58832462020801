import Checkbox from '@mui/material/Checkbox';
import sectorApi from '../../api/sector.api';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useState, useMemo } from 'react';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import {
  CustomTable,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  TableTitle,
} from '../../components/Table';
import LoadingButton from '@mui/lab/LoadingButton';

const creatorSchema = yup.object({
  sector: yup.string(),
  permissions: yup.string(),
});
function transformDataToApi(permissions) {
  const result = [];

  for (let perm of permissions) {
    for (let action of perm.actions) {
      result.push({ action, taskId: perm.taskId });
    }
  }

  return result;
}

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
// const transform = sectorId.
export default function PermissionForm({ sector, tasks }) {
  async function onSubmit() {
    setIsLoading(true);
    try {
      await sectorApi.setTaskPermission({
        sectorId: sector.id,
        permissions: transformDataToApi(taskPermissions),
      });

      setMessage('Salvo !');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  }
  const creatorMethods = useYupForm(creatorSchema, {
    ...sector,
  });
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const taskPermissions = useMemo(() => {
    return tasks.map((task) => ({
      taskId: task.id,
      actions: [],
    }));
  }, [tasks]);

  return (
    <>
      <Card sx={{ width: '100%', mb: 2 }}>
        <FormProvider {...creatorMethods}>
          <CardHeader title={sector.name} />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              timeout="auto"
              unmountOnExit
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmounOnExit>
            <CardContent>
              {expanded ? (
                <CustomTable>
                  <CustomTableHead>
                    {tasks.map((task) => (
                      <CustomTableCell align="center" key={task.id}>
                        <TableTitle>{task.title}</TableTitle>
                      </CustomTableCell>
                    ))}
                  </CustomTableHead>
                  <TableBody>
                    <CustomTableRow>
                      {taskPermissions.map((permission) => (
                        <CustomTableCell key={permission.id}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('VIEW');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'VIEW'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Visualizar"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('DELETE');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'DELETE'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Apagar"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('PASS');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'PASS'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Passar"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('UPDATE');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'UPDATE'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Editar"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('RETURN');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'RETURN'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Voltar"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('UPDATE_STATUS');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'UPDATE_STATUS'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Atribuir estado"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    const value = e.target.checked;

                                    if (value) {
                                      permission.actions.push('CREATE');
                                    } else {
                                      permission.actions =
                                        permission.actions.filter(
                                          (curr) => curr !== 'CREATE'
                                        );
                                    }
                                  }}
                                />
                              }
                              label="Criar"
                            />
                          </FormGroup>
                        </CustomTableCell>
                      ))}
                    </CustomTableRow>
                  </TableBody>
                </CustomTable>
              ) : (
                <></>
              )}

              <Grid item xs={12}>
                <Stack
                  spacing={4}
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    mt: 2.5,
                  }}
                >
                  <Grid item xs={5} md={2}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      xs={2}
                      md={4}
                      onClick={() => setExpanded(false)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={5} md={2}>
                    <LoadingButton
                      loading={isLoading}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={onSubmit}
                    >
                      Salvar
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Grid>
            </CardContent>
          </Collapse>
        </FormProvider>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition()}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
