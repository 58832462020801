import React from 'react';
import Collapse from '@mui/material/Collapse';
import { useWorkspaceAntts } from '../../../../hooks/useWorkspaces';
import AnttUpsertForm from '../AnttUpsertForm';
import { useSnackbar } from '../../../../contexts/SnackbarContext';

function AnttEditCollapse({ resource: antt, open }) {
  const { updateAntt } = useWorkspaceAntts();
  const isSpecific = antt.ownerId == undefined;
  const isLegalPerson = antt.cnh.length === 0 && antt.addressProof.length === 0;
  const handleRequest = useSnackbar();

  async function submit({
    rntrc,
    phoneNumber,
    cnh,
    addressProof,
    cnhValidationDate,
    addressProofDate,
    legalPerson,
    type,
    owner,
  }) {
    const newOwnerInput = {
      phoneNumber,
      ...(legalPerson ? {} : { cnh, addressProof, cnhValidationDate, addressProofDate}),
    };
    console.log(`recebi newOwnerINput ${newOwnerInput}`)

    await handleRequest(
      async () =>
        await updateAntt(antt.id, {
          rntrc,
          owner: type === 'CPF' ? newOwnerInput : owner.id,
        })
    );
  }

  return (
    <Collapse in={open}>
      <AnttUpsertForm
        buttonTitle="Salvar"
        defaultValue={{
          ...antt,
          owner: {
            id: antt.ownerId,
            ...antt,
          },
          type: isSpecific ? 'CPF' : 'CNPJ',
          legalPerson: isLegalPerson,
        }}
        onSubmit={submit}
      />
    </Collapse>
  );
}

export default React.memo(AnttEditCollapse);
