import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useState } from 'react';

export default function ResourceList({
  alertText,
  selected,
  onSelect,
  onDelete,
  resources,
  ResourceListItem,
  DeleteResourceDialog,
}) {
  const [expandedId, setExpandedId] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [deleting, setDeleting] = useState(null);

  function onExpand({ id }, expand) {
    expand ? setExpandedId(id) : setExpandedId(null);
    setEditingId(null);
  }

  function onEdit({ id }) {
    setEditingId(id);
    setExpandedId(null);
  }

  return (
    <>
      <List>
        {resources.length === 0 && (
          <Alert severity="info">
            <AlertTitle>{alertText}</AlertTitle>
          </Alert>
        )}

        {resources.map((resource) => (
          <ResourceListItem
            key={resource.id}
            resource={resource}
            selected={selected?.id === resource.id}
            onSelect={onSelect}
            expanded={expandedId === resource.id}
            onExpand={onExpand}
            editing={editingId === resource.id}
            onEdit={onEdit}
            onDelete={setDeleting}
          />
        ))}
      </List>

      <DeleteResourceDialog
        resource={deleting}
        onDelete={onDelete}
        onClose={() => setDeleting(null)}
      />
    </>
  );
}
