import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function TaskTitle({ children }) {
  return (
    <Paper
      sx={{
        p: 1.5,
        color: '#fff',
        backgroundColor: '#303030',
      }}
    >
      <Typography textAlign="center" fontSize={12}>
        {children}
      </Typography>
    </Paper>
  );
}
