import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import ExcludeTicketDialog from './ExcludeTicketDialog';
import { forwardRef, useState } from 'react';
import { ticketsApi } from '../../../api';
import useAuthSWR from '../../../hooks/useAuthSWR';
import DropdownFieldInput from '../../../components/Form/DropdownFieldInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../../hooks/useYupForm';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { usePermissions } from '../../../contexts/PermissionsContext';

const creatorSchema = yup.object({
  taskId: yup.object(),
  ticketId: yup.number(),
  status: yup.object(),
  nearestBranch: yup.object(),
});
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function TicketInfoButtons({ ticket }) {
  const ticketId = ticket.id;
  const task = ticket.task;
  const ticketStatus =
    ticket.task === null
      ? 'COMPLETED'
      : ticket.deletedAt !== null
      ? 'DELETED'
      : 'ACTIVE';

  const [returnOpen, setReturnOpen] = useState(false);
  const [excludeOpen, setExcludeOpen] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const [isIndictableDialogOpen, setIsIndictableDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleReturn = () => {
    setReturnOpen(true);
  };
  const handleReturnClose = () => {
    setReturnOpen(false);
  };
  const hasPermissions = usePermissions();

  function checkPermission(action) {
    if (ticketStatus === 'DELETED') return false;
    else if (ticketStatus === 'COMPLETED') {
      return false;
    } else return hasPermissions({ action, taskId: task.id });
  }

  async function conferTaskIsIndictableRequest() {
    try {
      if (task.indictable === true) setIsIndictableDialogOpen(true);
      else {
        await ticketsApi.passTicket({
          ticketId,
          completable: task.completable,
        });

        setMessage('Salvo !');
        setOpenStatusSuccess(true);
        navigate(`/dashboard`);
      }
    } catch (error) {
      if (error.response === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  async function passTicketWithNearestBranch({ nearestBranch }) {
    try {
      await ticketsApi.passTicket({
        ticketId,
        completable: task.completable,
        nearestBranch: nearestBranch.id,
        ticketReferenced: ticketId,
      });

      setMessage('Salvo !');
      setOpenStatusSuccess(true);
      navigate(`/dashboard`);
    } catch (error) {
      if (error.response === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  async function changeStatus({
    licensePlate,
    price,
    originCity,
    clientName,
    status,
    loadingDate,
    stopOver,
    product,
    isTrocaNota,
    driverName,
  }) {
    try {
      await ticketsApi.updateTicket({
        licensePlate,
        price,
        originCity,
        clientName,
        status: status.value,
        loadingDate,
        stopOver,
        product,
        isTrocaNota,
        driverName,
        ticketId,
      });
      setMessage('Salvo !');
      setOpenStatusSuccess(true);
      navigate('/dashboard');
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de alteração !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direciton="up" ref={ref} {...props} />;
  });
  const { data: sequence } = useAuthSWR(
    '/tasks',
    ticketStatus === 'ACTIVE'
      ? {
          'sequence[lt]': task.sequence,
        }
      : {}
  );
  const { data: rooms } = useAuthSWR('/rooms');
  async function returnTicket({ task }) {
    try {
      await ticketsApi.returnTicket({
        ticketId,
        taskId: task.id,
      });

      setMessage('Salvo !');
      setOpenStatusSuccess(true);
      navigate(`/dashboard`);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  const creatorMethods = useYupForm(creatorSchema);

  if (!ticketId) return <>Loading...</>;
  return (
    <>
      <Grid
        container
        columnSpacing={[2, 4]}
        rowSpacing={[2, 4]}
        display={ticketStatus === 'DELETED' ? 'none' : 'flex'}
        sx={{ pt: 4 }}
      >
        <FormProvider {...creatorMethods}>
          {checkPermission('RETURN') ? (
            <Grid item xs={6} sm={3}>
              <Button
                loadingPosition="end"
                startIcon={<ArrowBackIcon />}
                variant="contained"
                color="success"
                fullWidth
                onClick={handleReturn}
              >
                Voltar
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {checkPermission('DELETE') ? (
            <Grid item xs={6} sm={3}>
              <Button
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="contained"
                color="error"
                fullWidth
                onClick={() => setExcludeOpen(true)}
              >
                Deletar
              </Button>
            </Grid>
          ) : (
            <></>
          )}

          {checkPermission('UPDATE') ? (
            <Grid item xs={6} sm={3}>
              <Button
                loadingPosition="end"
                startIcon={<EditIcon />}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => navigate(`/editTicket/${ticketId}`)}
              >
                Editar
              </Button>
            </Grid>
          ) : (
            <></>
          )}

          {checkPermission('PASS') ? (
            <Grid item xs={6} sm={3}>
              <Button
                loadingPosition="end"
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                color="success"
                fullWidth
                onClick={conferTaskIsIndictableRequest}
              >
                {task.completable ? 'Arquivar' : 'Passar'}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {checkPermission('UPDATE_STATUS') ? (
            <Grid item xs={6} sm={3}>
              <Button
                color="secondary"
                onClick={() => setStatusDialog(true)}
                variant="contained"
                fullWidth
              >
                status
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <ExcludeTicketDialog
            open={excludeOpen}
            handleClose={() => setExcludeOpen(false)}
            ticketId={ticketId}
            fullWidth
            maxWidth="xs"
            sx={{ p: 5 }}
          />
          <Dialog
            open={returnOpen}
            fullWidth
            sx={{ p: 10 }}
            transitionComponent={Transition}
            keepMounted
            maxWidth="xs"
            onClose={handleReturnClose}
          >
            <DialogTitle>Escolha a Task</DialogTitle>
            <DialogContent>
              <DropdownFieldInput
                name="task"
                options={sequence ?? []}
                label="Tasks"
                sx={{ p: 1 }}
                getOptionLabel={(option) => option.title}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReturnClose}>Cancelar</Button>
              <Button onClick={creatorMethods.handleSubmit(returnTicket)}>
                Salvar
              </Button>
            </DialogActions>
          </Dialog>

          {/* recommendation DIALOG HERE */}

          <Dialog
            open={isIndictableDialogOpen}
            fullWidth
            sx={{ p: 10 }}
            transitionComponent={Transition}
            keepMounted
            maxWidth="xs"
            onClose={() => setIsIndictableDialogOpen(false)}
          >
            <DialogTitle>Indique uma filial</DialogTitle>
            <DialogContent>
              <DropdownFieldInput
                name="nearestBranch"
                options={rooms ?? []}
                label="Filiais"
                sx={{ p: 1 }}
                getOptionLabel={(option) => option.name}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsIndictableDialogOpen(false)}>
                Cancelar
              </Button>
              <Button
                onClick={creatorMethods.handleSubmit(
                  passTicketWithNearestBranch
                )}
              >
                Salvar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            open={statusDialog}
            transitionComponent={Transition}
            keepMounted
            onClose={() => setStatusDialog(false)}
          >
            <DialogTitle>Alterar status</DialogTitle>
            <DialogContent>
              <DropdownFieldInput
                sx={{ mt: 1 }}
                name="status"
                options={[
                  { value: 'DECLINED', label: 'Recusado' },
                  { value: 'IN_PROGRESS', label: 'Iniciado' },
                  { value: 'NOT_STARTED', label: 'Não iniciado' },
                  { value: 'SEND_SECTOR', label: 'Enviado ao Setor Responsável' },
                  { value: 'WAITING_RETURN', label: 'Aguardando Retorno do Cliente' },
                  { value: 'WAITING_ADJUSTMENT', label: 'Pendente-Aguardando Ajuste' },
                  { value: 'FINISHED', label: 'Finalizado-Aguardando Resposta do Líder' },
                ]}
                label="Status"
                //getOptionLabel={(option) => option}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setStatusDialog(false)}>Cancelar</Button>
              <Button onClick={creatorMethods.handleSubmit(changeStatus)}>
                Salvar
              </Button>
            </DialogActions>
          </Dialog>
        </FormProvider>
      </Grid>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
