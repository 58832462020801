import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import { capitilizeInitialLetters } from './capitilizeInitialLetters';

export default function LinkClipboardButton({ ticket }) {
  return (
    <IconButton
      onClick={() => {
        const client = capitilizeInitialLetters(ticket.clientName);
        const originCity = ticket.originCity;
        const destinyCity = ticket.destinyCity;
        const driverName = capitilizeInitialLetters(
          ticket.driverName.split(' ')[0]
        );
        const licensePlate = ticket.licensePlate;
        const link = `${process.env.REACT_APP_FRONTEND_URL}/tickets/${ticket.id}`;

        navigator.clipboard.writeText(
          `${driverName} - ${licensePlate}\n${originCity} X ${destinyCity}\n${client}\n${link}`
        );
      }}
    >
      <LinkIcon />
    </IconButton>
  );
}
