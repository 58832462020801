import { createContext, useContext } from 'react';
import useSWR from 'swr';
import workspaceApi from '../api/workspace.api';

const WorkspaceContext = createContext({});

function WorkspaceProvider({ workspaceId, children }) {
  const { data, error, mutate } = useSWR(workspaceId, workspaceApi.getOne, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const isLoading = !data;
  const workspace = {
    id: workspaceId,
    ...data,
    driver: data?.owners.filter((owner) => owner.name === undefined)[0],
  };

  return (
    <WorkspaceContext.Provider
      value={{
        workspace,
        error,
        isLoading,
        mutate,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

function useWorkspace() {
  return useContext(WorkspaceContext);
}

export default WorkspaceProvider;
export { useWorkspace };
