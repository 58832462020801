import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBoolean } from 'react-hanger';

export default function DeleteResourceDialog({
  onClose,
  onDelete,
  open,
  title,
  desc,
}) {
  const loading = useBoolean();

  async function onDeleteAction() {
    loading.setTrue();

    try {
      await onDelete();
    } finally {
      loading.setFalse();
    }

    onClose();
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{desc}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={loading.value} onClick={onClose}>
          Cancelar
        </LoadingButton>
        <LoadingButton loading={loading.value} onClick={onDeleteAction}>
          Deletar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
