import React, { useMemo, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import useAuthSWR from '../../hooks/useAuthSWR';
import recommendationApi from '../../api/recommendation.api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import useYupForm from '../../hooks/useYupForm';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextFieldInput from '../../components/Form/TextFieldInput';
import RadioComponentInput from '../../components/Form/RadioComponentInput';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Grid from '@mui/material/Grid';
import DropdownFieldInput from '../../components/Form/DropdownFieldInput';
import { useAuth } from '../../contexts/AuthContext';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ticketsApi } from '../../api';
import Paper from '@mui/material/Paper';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const creatorSchema = yup.object({
  reembarked: yup.boolean(),
  driverFeedback: yup.string(),
});
const updateSchema = yup.object({
  nearestBranch: yup.object(),
});

function transformTicketToRecommendationTicket(ticket) {
  return {
    liscensePlate: ticket.licensePlate,
    origin: ticket.originCity,
    destiny: ticket.destinyCity,
  };
}

export default function NearbyTrucks({
  shouldDialogOpen,
  handleRecommendationState,
}) {
  const { user: me } = useAuth();
  const { data: manyRooms } = useAuthSWR('/rooms');
  const { data: recommendations, mutate } = useAuthSWR('/recommendations', {});

  const resetValue = {
    driverFeedback: '',
  };
  const { handleSubmit, reset, ...creatorMethods } = useYupForm(
    creatorSchema,
    resetValue
  );
  const updateMethod = useYupForm(updateSchema);
  const enableFeedbackTextField = creatorMethods?.watch('reembarked') === false;

  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isDriverReturnDialogOpen, setIsDriverDialogOpen] = useState(false);
  const [recommendationId, setRecommendationId] = useState();
  const [
    isUpdateRecommendationDialogOpen,
    setIsUpdateRecommendationDialogOpen,
  ] = useState(false);

  async function onSubmit({ reembarked, driverFeedback }) {
    try {
      await recommendationApi.createBoardingRebound({
        recommendationId,
        reembarked,
        driverFeedback,
      });
      handleRecommendationState();
      mutate((recommendations) => {
        recommendations.map((recommendation) => {
          if (recommendation.id !== recommendationId) return recommendation;
          return {
            ...recommendation,
            recommendations:
              recommendations?.filter(({ id }) => id !== recommendationId) ??
              [],
          };
        });
      });
      setMessage('Salvo !');
      setIsDriverDialogOpen(false);

      setOpenSuccess(true);
      /* */
      reset(resetValue);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Tipo de dado errado !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.response.status === 422) {
        setMessage('Task ja existe !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    }
  }

  async function onUpdate({ nearestBranch }) {
    try {
      await recommendationApi.updateRecommendation({
        recommendationId,
        nearestBranch: nearestBranch.id,
      });

      setMessage('Salvo !');
      setIsUpdateRecommendationDialogOpen(false);
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Tipo de dado errado !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.response.status === 422) {
        setMessage('Task ja existe !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    }
  }

  const userBelongSuportOrOperationalManagement = me.sectors.some(
    (sector) =>
      sector.name == 'Suporte' ||
      sector.name == 'Lder de Suporte' ||
      sector.name == 'Gerente Operacional'
  );
  /* fazer a filtragem para mostrar se for embarcador, a funcao de validação funciiona, precisa ir no backend e adicionar manage_recommendations no permissionsEnum */
  const filteredRecommendations = recommendations?.filter((r) =>
    userBelongSuportOrOperationalManagement
      ? true
      : r.nearestBranchId === me.room.id
  );

  if (filteredRecommendations === undefined)
    return (
      <Drawer
        open={shouldDialogOpen}
        anchor="right"
        keepMounted={true}
        onClose={() => handleRecommendationState()}
      >
        <Typography
          sx={{
            m: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Typography>
      </Drawer>
    );

  return (
    <>
      <FormProvider {...creatorMethods}>
        <Container maxWidth="sm">
          <Drawer
            open={shouldDialogOpen}
            anchor="right"
            keepMounted={true}
            onClose={() => handleRecommendationState()}
          >
            {userBelongSuportOrOperationalManagement ? (
              <TransitionGroup>
                {filteredRecommendations.map((recommendation) => {
                  const phrase = `Caminhão ${recommendation.ticketReferenced.licensePlate} indo de ${recommendation.ticketReferenced.originCity} para ${recommendation.ticketReferenced.destinyCity}, indicado por ${recommendation.recommendedBy.username}`;
                  const transform = new Date(recommendation.date);
                  const yyyy = transform.getFullYear();
                  const mm = transform.getMonth() + 1;
                  const dd = transform.getDate();
                  const date = dd + '/' + mm + '/' + yyyy;

                  return (
                    <Collapse key={recommendation.id}>
                      <ListItem>
                        <Grid
                          container
                          key={recommendation.id}
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Grid item xs={1}>
                            <ListItemIcon>
                              <LocalShippingIcon
                                color="warning"
                                onClick={() => {
                                  setRecommendationId(recommendation.id);
                                  setIsUpdateRecommendationDialogOpen(true);
                                }}
                              />
                            </ListItemIcon>
                          </Grid>
                          <Grid item xs={10}>
                            <ListItemText
                              primary={phrase}
                              secondary={date}
                              onClick={() => {
                                setRecommendationId(recommendation.id);
                                setIsUpdateRecommendationDialogOpen(true);
                              }}
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <ListItemIcon>
                              <ModeEditIcon
                                color="warning"
                                onClick={() => {
                                  setRecommendationId(recommendation.id);
                                  setIsUpdateRecommendationDialogOpen(true);
                                }}
                              />
                            </ListItemIcon>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider />
                    </Collapse>
                  );
                })}
              </TransitionGroup>
            ) : (
              filteredRecommendations.map((recommendation) => {
                const phrase = `Caminhão ${recommendation.ticketReferenced.licensePlate} indo de ${recommendation.ticketReferenced.originCity} para ${recommendation.ticketReferenced.destinyCity}, indicado por ${recommendation.recommendedBy.username}`;
                const transform = new Date(recommendation.date);
                const yyyy = transform.getFullYear();
                const mm = transform.getMonth() + 1;
                const dd = transform.getDate();
                const date = dd + '/' + mm + '/' + yyyy;

                return (
                  <>
                    <ListItem key={recommendation.id}>
                      <Grid
                        container
                        key={recommendation.id}
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <Grid item xs={1}>
                          <ListItemIcon>
                            <LocalShippingIcon
                              color="warning"
                              onClick={() => {
                                setRecommendationId(recommendation.id);
                                setIsDriverDialogOpen(true);
                              }}
                            />
                          </ListItemIcon>
                        </Grid>
                        <Grid item xs={10}>
                          <ListItemText
                            primary={phrase}
                            secondary={date}
                            onClick={() => {
                              setRecommendationId(recommendation.id);
                              setIsDriverDialogOpen(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
            )}
          </Drawer>
        </Container>

        <Dialog
          open={isDriverReturnDialogOpen}
          onClose={() => setIsDriverDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Retorno do motorista</DialogTitle>

          <DialogContent>
            <Grid container display="flex" alignItems="flex-end" xs={12}>
              <Grid item xs={3}>
                <RadioComponentInput
                  name="reembarked"
                  title="Reembarcou?"
                  firstLabel="Sim"
                  secondLabel="Não"
                  row={false}
                />
              </Grid>
              <Grid item xs={9}>
                {enableFeedbackTextField ? (
                  <TextFieldInput
                    sx={{ mt: 1 }}
                    fullWidth
                    name="driverFeedback"
                    label="Feedback"
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setIsDriverDialogOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>Salvar</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <FormProvider {...updateMethod}>
        <Dialog
          open={isUpdateRecommendationDialogOpen}
          onClose={() => setIsUpdateRecommendationDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Modiciar filial indicada</DialogTitle>

          <DialogContent>
            <DropdownFieldInput
              fullWidth
              sx={{ mt: 1 }}
              name="nearestBranch"
              options={manyRooms ?? []}
              label="Filiais"
              getOptionLabel={(option) => option.name}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setIsUpdateRecommendationDialogOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={updateMethod.handleSubmit(onUpdate)}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
