import api from './api';

async function createClient({ name, phonenumber, period, userId }) {
  await api.post('/contacts', {
    name,
    phoneNumber: phonenumber,
    period,
    userId,
  });
}

async function registerInteraction({ observation, released, contactId }) {
  const res = await api.post(`/contacts/${contactId}/interactions`, {
    observation,
    released,
  });
  console.log(`contact_id API: ${contactId}`);
  return res.data;
}
async function inactiveContact({ contactId }) {
  await api.delete(`/contacts/${contactId}/period`);
}
async function updateContact({ contactId, name, phoneNumber }) {
  const res = await api.patch(`/contacts/${contactId}`, {
    name,
    phoneNumber,
  });
  return res.data;
}
async function assignContactToAnUser({ contactId, userId }) {
  const res = await api.put(`/users/${userId}/contacts/${contactId}`);
  return res.data;
}

async function updateContactPeriod({ contactId, value }) {
  const res = await api.put(`contacts/${contactId}/period`, {
    value,
  });
  return res.data;
}

async function generateContactReport({ date, userId }) {
  const res = await api.get(`/reports/contacts`, { params: { date, userId } });
  return res.data;
}
const commercialApi = {
  createClient,
  registerInteraction,
  inactiveContact,
  updateContact,
  assignContactToAnUser,
  updateContactPeriod,
  generateContactReport,
};

export default commercialApi;
