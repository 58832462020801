import { Component } from 'react';

import Chart from 'react-apexcharts';

class DetailBarChart extends Component {
  constructor(props) {
    super(props);

    const detailsSizes = [];
    const targetSizes = [];
    const periodIndex = [];
    let i = 0;
    let max = 0;

    for (const { target, current } of this.props.periodDetails) {
      if (target > max) max = target;
      if (current > max) max = current;

      detailsSizes.push(current);
      targetSizes.push(target);
      periodIndex.push(`${++i}° semana`);
    }

    const currentDate = new Date();
    const currentMonth = new Date(currentDate);
    currentMonth.setDate(1);
    const currentMonthWeek =
      getWeekNumber(currentDate) - getWeekNumber(currentMonth) + 1;

    const reportMonth = new Date(props.reportDate);
    reportMonth.getMonth();

    const isReportOfCurrentMonth =
      props.reportDate.getMonth() == currentMonth.getMonth() &&
      props.reportDate.getFullYear() == currentMonth.getFullYear();

    this.state = {
      options: {
        title: {
          text: 'Atingimento Semanal',
        },
        chart: {
          type: 'line',
        },
        stroke: {
          colors: ['#ccc'],
          width: [0, 4],
        },
        dataLabels: {
          enabled: true,
          offsetY: -8,
          offsetX: 4,
          enabledOnSeries: [1],
          style: {
            colors: ['#000'],
          },
        },
        yaxis: {
          min: 0,
          max: max + 1,
          tickAmount: max + 1,
        },
        grid: {
          padding: {
            top: 10,
          },
        },
        legend: {
          show: false,
        },
        labels: periodIndex,
        annotations: isReportOfCurrentMonth
          ? {
              xaxis: [
                {
                  x: `${currentMonthWeek}° semana`,
                  orientation: 'horizontal',
                  borderColor: '#444',
                  label: {
                    borderColor: '#444',
                    style: {
                      color: '#FFF',
                      background: '#444',
                    },
                    text: 'Semana Atual',
                  },
                },
              ],
            }
          : undefined,
      },
      series: [
        {
          name: 'Real',
          type: 'column',
          data: detailsSizes,
        },
        {
          name: 'Meta',
          type: 'line',
          data: targetSizes,
        },
      ],
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        width="100%"
        height={400}
      />
    );
  }
}

function getWeekNumber(date) {
  const year = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - year) / (24 * 60 * 60 * 1000));

  return Math.ceil(days / 7);
}

export default DetailBarChart;
