import Paper from '@mui/material/Paper';

function BaseLayout({ sx, children }) {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundImage: 'url("background/background-dashboard.svg")',
        backgroundSize: '100vh',
        minHeight: '100vh',
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

export default BaseLayout;
