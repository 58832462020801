import useAuthSWR from '../../hooks/useAuthSWR';
import PermissionForm from './PermissionForm';
import Container from '@mui/material/Container';
export default function SetPermission() {
  const { data: sectors } = useAuthSWR('/sectors');
  const { data: tasks } = useAuthSWR('/tasks');
  if (!sectors) return <></>;
  if (!tasks) return <></>;

  return (
    <Container>
      {sectors.map((sector) => (
        <PermissionForm sector={sector} tasks={tasks} key={sector.id} />
      ))}
    </Container>
  );
}
