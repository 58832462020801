import { useContext, useEffect } from 'react';
import { createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWorkspace } from './WorkspaceContext';

import * as yup from 'yup';
import useYupForm from '../hooks/useYupForm';
import {
  bankAccountFormSchema,
  truckTractorFormSchema,
  truckTrailerFormSchema,
  referencesFormSchema,
  lastManifestSchema,
  observationSchema,
  picturesSchema,
} from '../components/EditWorkspaceForm/Steps';
import { FormProvider } from 'react-hook-form';
import workspaceApi from '../api/workspace.api';
import { useWorkspaceOwners } from '../hooks/useWorkspaces';

const EditWorkspaceContext = createContext({});
const editWorkspaceFormSchema = yup.object({
  truckTractor: yup.mixed().step(3, truckTractorFormSchema),
  truckTrailers: yup.mixed().step(4, truckTrailerFormSchema),
  bankAccount: yup.mixed().step(4, bankAccountFormSchema),
  references: yup.mixed().step(5, referencesFormSchema),
  lastManifest: yup.mixed().step(6, lastManifestSchema),
  observation: yup.mixed().step(6, observationSchema),
  pictures: yup.mixed().step(6, picturesSchema),

  step: yup.number().required(),
});

function EditWorkspaceProvider({ children }) {
  const {
    workspace: { id, result },
    mutate,
  } = useWorkspace();
  const { driver } = useWorkspaceOwners();

  const navigate = useNavigate();
  const step = Number(useLocation().pathname.split('/')[3] ?? 1);

  const formMethods = useYupForm(editWorkspaceFormSchema, {
    references: {
      personalContacts: new Array(3),
      companyContacts: new Array(3),
    },
  });

  useEffect(() => formMethods.setValue('step', step), [step, formMethods]);
  useEffect(() => {
    if (result) {
      const resetValue = { ...result };
      resetValue.observation = resetValue.observation ?? undefined;
      resetValue.bankAccount.pixNumber =
        resetValue.bankAccount.pixNumber ?? undefined;
      formMethods.reset(resetValue);
    }
  }, [result, formMethods]);

  function prevStep() {
    if (step !== 1) navigate(`/editWorkspaces/${id}/${step - 1}`);
  }

  function nextStep() {
    navigate(`/editWorkspaces/${id}/${step + 1}`);
  }

  async function saveEdit({
    truckTractor,
    truckTrailers,
    bankAccount,
    references,
    lastManifest,
    observation,
    pictures,
  }) {
    async function save(workspace) {
      await workspaceApi.result.upsert(id, {
        driver: driver.id,
        truckTractor: flattenTruckComponent(truckTractor),
        truckTrailers: truckTrailers
          ? truckTrailers.map(flattenTruckComponent)
          : [],
        bankAccount,
        references,
        lastManifest,
        observation,
        pictures,
      });

      return {
        ...workspace,
        result: {
          truckTractor,
          truckTrailers,
          bankAccount,
          references,
          lastManifest,
          observation,
          pictures,
        },
      };
    }

    await mutate(save, { revalidate: false });

    navigate('/viewDrivers');
  }

  return (
    <EditWorkspaceContext.Provider
      value={{
        step,
        prevStep,
        nextStep,
        handleSubmit: formMethods.handleSubmit,
        saveEdit,
      }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </EditWorkspaceContext.Provider>
  );
}

function flattenTruckComponent(truck) {
  return {
    ...truck,
    antt: truck.antt.id,
    owner: truck.owner.id,
  };
}

function useEditWorkspace() {
  return useContext(EditWorkspaceContext);
}

export default EditWorkspaceProvider;
export { useEditWorkspace };
