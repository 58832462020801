import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import roomApi from '../../api/room.api';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

const creatorSchema = yup.object({
  roomName: yup
    .string()
    .label('Nome da sala')
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function ManipulateRoomForm({ room }) {
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [roomNameState, setRoomNameState] = useState('');

  const [ancoraMenu, setAncoraMenu] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const isMenuOpen = Boolean(ancoraMenu);
  const handleClick = (event) => {
    setAncoraMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAncoraMenu(null);
  };
  async function updateRoom() {
    try {
      await roomApi.UpdateRoom({
        roomId: room.id,
        name: roomNameState,
      });
      setMessage('Alterado !');
      setOpenSuccess(true);
      setUpdateDialog(false);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }
  async function deleteRoom({ roomId }) {
    try {
      await roomApi.DeleteRoom({ roomId });
      setMessage('Deletado !');
      setOpenSuccess(true);
      setUpdateDialog(false);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }
  const creatorMethods = useYupForm(creatorSchema, {
    ...room,
  });

  return (
    <FormProvider {...creatorMethods}>
      <ListItem>
        <ListItemButton>
          <ListItemText primary={room.name} />
          <ListItemIcon>
            <MoreVertIcon onClick={handleClick} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>

      <Divider />
      <Menu anchorEl={ancoraMenu} open={isMenuOpen} onClose={handleCloseMenu}>
        <MenuItem onClick={() => (setUpdateDialog(true), handleCloseMenu())}>
          <Typography>Alterar</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => (setConfirmationDialog(true), handleCloseMenu())}
        >
          <Typography>Deletar</Typography>
        </MenuItem>
      </Menu>

      <Dialog
        open={confirmationDialog}
        TransitionComponent={SlideTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setConfirmationDialog(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja excluir permanentemente a filial
            <b> {room.name} </b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialog(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              deleteRoom({ roomId: room.id });
              setConfirmationDialog(false);
            }}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateDialog}
        TransitionComponent={SlideTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setUpdateDialog(false)}
      >
        <DialogTitle>Alterar Nome</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite o novo nome que será atribuído a sala <b>{room.name}</b>
          </DialogContentText>
          <TextField
            label="Nome"
            name="roomName"
            fullWidth={true}
            onChange={(e) => setRoomNameState(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateDialog(false)}>Cancelar</Button>
          <Button onClick={() => updateRoom()}>Alterar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
