import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import registrationFormApi from '../../api/registrationForm.api';
import { TextFieldInput, DropdownFieldInput } from '../../components/Form';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useYupForm from '../../hooks/useYupForm';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import useAuthSWR from '../../hooks/useAuthSWR';

function Transition(props) {
  return <Slide {...props} direction="up" />;
}

function snackbarTransition(props) {
  return <Slide {...props} direction="down" />;
}

const createRegistrationFormSchema = yup.object({
  referencedByObject: yup.object(),
});

export default function DriverComponent({ driver }) {
  const createRegistrationFormMethod = useYupForm(createRegistrationFormSchema);

  const { data: users } = useAuthSWR('/users');

  const navigate = useNavigate();
  const [isHiddend, setIsHiddend] = useState(false);
  const [message, setMessage] = useState('');

  const [open, setOpen] = useState(false);
  if (!users) return <></>;
  const handleVisibility = () => {
    setIsHiddend(!isHiddend);
  };
  async function create({ referencedByObject }) {
    try {
      await registrationFormApi.createRegistrationForm({
        referencedBy: referencedByObject.id,
        workspaceId: driver.cpf,
      });
      navigate(`/registrationForm/${driver.cpf}`, {
        state: { driverCPF: driver.cpf },
      });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Tipo de dado errado !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.response.status === 422) {
        setMessage('Task ja existe !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    }
  }

  return (
    <>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={driver.cpf}
          subheader={
            <>
              {' '}
              {driver.licensePlate} <br /> {driver.phoneNumber}{' '}
            </>
          }
        />

        <CardActions disableSpacing>
          <Button
            disabled={!driver.phoneNumber}
            onClick={() =>
              navigate(`/DriverDetails/${driver.cpf}`, {
                state: { workspaceId: driver.cpf },
              })
            }
          >
            Visualizar
          </Button>

          <Button onClick={() => navigate(`/editWorkspaces/${driver.cpf}`)}>
            Editar
          </Button>
          <Button
            onClick={() => {
              if (driver.hasRegistrationForm) {
                navigate(`/record/${driver.cpf}`);
              } else {
                setIsHiddend(true);
              }
            }}
          >
            Ficha
          </Button>
        </CardActions>
      </Card>
      <FormProvider {...createRegistrationFormMethod}>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={isHiddend}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleVisibility}
        >
          <DialogTitle>{'Criação de ficha'}</DialogTitle>
          <DialogContent>
            <DropdownFieldInput
              fullWidth
              name="referencedByObject"
              options={
                users.filter(({ sectors }) => sectors.name !== 'Embarques') ??
                []
              }
              label="Embarcador"
              getOptionLabel={(option) => option.name}
            />
            {/*Mostrar somente os embarcadores comprometedo a dinamicidade do software ou mostrar todos os usuários? */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleVisibility}>Cancelar</Button>
            <Button onClick={createRegistrationFormMethod.handleSubmit(create)}>
              Criar
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={snackbarTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
