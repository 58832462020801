import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import useAuthSWR from '../hooks/useAuthSWR';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { TransitionGroup } from 'react-transition-group';
import { ticketsApi } from '../api';
import FeedbackField from './FeedbackField';

export default function DriverFeedbackComponent({ rebounds }) {
  if (!rebounds) return <></>;

  if (rebounds.length == 0)
    return (
      <Paper
        sx={{
          height: '80vh',
          overflow: 'scroll',
          display: ' flex',
          width: '100%',
          justifyContent: 'center',
          border: '1px solid #e2e2e2',
          '::-webkit-scrollbar': { display: 'none' },
          '::-webkit-scrollbar-corner': { display: 'none' },
          '::-webkit-scrollbar-thumb': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
            Sem dados
          </Typography>
        </Box>
      </Paper>
    );
  return (
    <Paper
      sx={{
        height: '70vh',
        overflow: 'scroll',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        border: '1px solid #e2e2e2',
        '::-webkit-scrollbar': { display: 'none' },
        '::-webkit-scrollbar-corner': { display: 'none' },
        '::-webkit-scrollbar-thumb': {
          display: 'none',
        },
      }}
    >
      <TransitionGroup>
        {rebounds.map((rebound) => (
          <Collapse key={rebound.recommendationId}>
            <FeedbackField
              feedback={rebound.feedback}
              liscensePlate={rebound.liscensePlate}
            />
          </Collapse>
        ))}
      </TransitionGroup>
    </Paper>
  );
}
