import { DataGrid, nlNL } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function ReportTableRows({ report }) {
  if (!report) return <></>;

  const newRows = [
    { period: 'Semanal', ...report.weekly },
    { period: 'Mensal', ...report.monthly },
    { period: 'Total', ...report },
  ];

  const columns = [
    {
      field: 'period',
      headerName: 'Período',
      width: 100,
      editable: false,
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      editable: false,
      valueGetter: (params) => params.row.goal.target,
    },
    {
      field: 'current',
      headerName: 'Atual',
      width: 100,
      editable: false,
      valueGetter: (params) => params.row.goal.current,
    },
    {
      field: 'percentage',
      headerName: 'Porcentagem',
      width: 100,
      editable: false,
      valueGetter: (params) => params.row.goal.percentage.toFixed(2),
    },
  ];

  const positivizationColumns = [
    {
      field: 'period',
      headerName: 'Período',
      width: 100,
      editable: false,
    },
    {
      field: 'goal',
      headerName: 'Meta',
      width: 100,
      editable: false,
      valueGetter: (params) => params.row.positivization.target,
    },
    {
      field: 'pcurrent',
      headerName: 'Atual',
      width: 100,
      editable: false,
      valueGetter: (params) => params.row.positivization.current,
    },
    {
      field: 'pPercentage',
      headerName: 'Porcentagem',
      width: 100,
      editable: false,
      valueGetter: (params) => params.row.positivization.percentage.toFixed(2),
    },
  ];

  return (
    <>
      <Grid mb={2} container columnSpacing={2}>
        <Grid item xs={6}>
          <Typography fontSize={20}>Meta</Typography>
          <Box sx={{ height: 213.5 }}>
            <DataGrid
              getRowId={(row) => row.period}
              sx={{ bgcolor: 'background.paper' }}
              localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              rows={newRows}
              columns={columns}
              aria-label="Contato"
              la
              disableRowSelectionOnClick
              hideFooter
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={20}>Positivação</Typography>
          <Box sx={{ height: 213.5 }}>
            <DataGrid
              sx={{ bgcolor: 'background.paper' }}
              localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              getRowId={(row) => row.period}
              rows={newRows}
              columns={positivizationColumns}
              disableRowSelectionOnClick
              hideFooter
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
