import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '@mui/material/TextField';

import useBoolean from 'react-hanger/useBoolean';
import { useController } from 'react-hook-form';
import { useRef } from 'react';

export default function   ResourceFieldInput({
  name,
  WorkspaceResourceDialog,
  WorkspaceCreateDialog,
  InputIcon,
  getValue,
  getShrink,
  ...props
}) {
  const selectOpen = useBoolean(false);
  const createOpen = useBoolean(false);

  const menuOpen = useBoolean(false);
  const { field, fieldState } = useController({ name });
  const buttonRef = useRef();

  return (
    <>
      <TextField
        value={getValue ? getValue(field.value) : field.value}
        InputLabelProps={{
          shrink: getShrink
            ? getShrink(field.value)
            : field.value != '' && field.value != undefined,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                ref={buttonRef}
                disabled={props.disabled}
                onClick={menuOpen.setTrue}
                edge="end"
              >
                <InputIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        error={Boolean(fieldState.error)}
        helperText={fieldState.error?.message}
        {...props}
      />

      <Menu
        open={menuOpen.value}
        onClose={menuOpen.setFalse}
        anchorEl={buttonRef.current}
      >
        <MenuItem
          onClick={() => {
            selectOpen.setTrue();
            menuOpen.setFalse();
          }}
        >
          <ListItemText>Selecionar existente</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            createOpen.setTrue();
            menuOpen.setFalse();
          }}
        >
          <ListItemText>Criar novo</ListItemText>
        </MenuItem>
      </Menu>

      <WorkspaceResourceDialog
        value={field.value}
        onChange={(value) => field.onChange({ target: { value } })}
        open={selectOpen.value}
        onClose={selectOpen.setFalse}
      />

      {WorkspaceCreateDialog && (
        <WorkspaceCreateDialog
          onChange={(value) => field.onChange({ target: { value } })}
          open={createOpen.value}
          onClose={createOpen.setFalse}
        />
      )}
    </>
  );
}
