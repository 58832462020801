import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextFieldInput from './Form/TextFieldInput';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from 'yup';
import useYupForm from '../hooks/useYupForm';
import { usersApi } from '../api';
import { FormProvider } from 'react-hook-form';
import { DialogActions } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useAuthSWR from '../hooks/useAuthSWR';
import DropdownFieldInput from '../components/Form/DropdownFieldInput';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useForm, useController } from 'react-hook-form';
const createUserSchema = yup.object({
  username: yup
    .string()
    .label('Nome do usuário')
    .min(2, 'Minimo de 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
  fullName: yup.string().required('Campo obrigatório'),
  password: yup.string().required('Campo obrigatório'),
  sectors: yup.array().of(yup.string()),
});

export default function CreateUserDialog({ mutate, open, setOpen }) {
  const formMethods = useYupForm(createUserSchema);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setValue, register, getValues, reset } = useForm();

  const { data: sectors } = useAuthSWR('/sectors');
  console.log(getValues('sectors'));
  async function onSubmit({ username, fullName, password }) {
    console.log({ username, fullName, password, sectors });
    try {
      setIsLoading(true);
      const { id } = await usersApi.createNewUser({
        username,
        name: fullName,
        password,
        sectors: getValues('sectors'),
      });

      mutate(
        (users) => {
          return [
            {
              id,
              name: fullName,
              username,
              phoneNumber: null,
              active: true,
              sectors: [],
              room: null,
            },
            ...users,
          ];
        },
        { revalidate: false }
      );

      enqueueSnackbar('Criado!', { variant: 'success' });

      setOpen(false);
      reset({ sectors: '' });
      formMethods.reset({
        fullName: '',
        username: '',
        password: '',
      });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (
        error.response.status === 422 &&
        error.response.data.code == 'NON_UNIQUE_FIELD'
      ) {
        if (error.response.data.fieldName == 'username')
          enqueueSnackbar('Nome de Usuário já utilizado', { variant: 'error' });

        if (error.response.data.fieldName == 'name')
          enqueueSnackbar('Nome Completo já utilizado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (!sectors) return <></>;
  return (
    <Dialog
      keepMounted={true}
      onClose={() => setOpen(false)}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Criar Usuário</DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <Stack spacing={2} sx={{ width: '100%', pt: 1 }}>
            <TextFieldInput name="fullName" label="Nome Completo" fullWidth />

            <TextFieldInput name="username" label="Nome de Usuário" fullWidth />

            <TextFieldInput
              name="password"
              type="password"
              label="Senha"
              fullWidth
            />
            <Autocomplete
              multiple
              options={sectors}
              {...register('sectors')}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(e, value) => {
                setValue(
                  'sectors',
                  value.map((obj) => obj.id)
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Setores" placeholder="Setores" />
              )}
            />
          </Stack>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancelar</Button>
        <LoadingButton
          loading={isLoading}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
