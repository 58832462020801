import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

export default function TicketTable({ children, ...props }) {
  return (
    <TableContainer {...props} component={Paper}>
      <Table>{children}</Table>
    </TableContainer>
  );
}
