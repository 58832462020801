import React, { useEffect, useState } from 'react';
import TextFieldInput from '../Form/TextFieldInput';
import Stack from '@mui/material/Stack';
import useYupForm from '../../hooks/useYupForm';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import commercialApi from '../../api/commercial.api';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import PeriodComponent from './PeriodComponent';

import DialogContentText from '@mui/material/DialogContentText';

import DialogActions from '@mui/material/DialogActions';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import periodMap from './periodMap';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const alterSchema = yup.object({
  name: yup
    .string()
    .label('Nome do cliente')
    .min(3)
    .required('O nome do cliente é obrigatório'),
  phoneNumber: yup
    .string()
    .min(10)
    .required('o número de contato é obrigatório'),
});

export default function EditClientForm({ clientData, users }) {
  const alterClientMethod = useYupForm(alterSchema);
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [usersMenu, setUsersMenu] = useState(null);
  const [openRelationateDialog, setOpenRelationateDialog] = useState(false);
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(undefined);
  const [contactPeriod, setContactPeriod] = useState(clientData?.period);

  const [username, setUsername] = useState();
  const [userIdState, setUserIdState] = useState();

  // Atualiza os dados do formulário quando o cliente é alterado
  useEffect(() => {
    if (!clientData) return;

    setContactPeriod(clientData.period);
    alterClientMethod.reset({
      name: clientData.name,
      phoneNumber: clientData.phoneNumber,
      responsibleUser: clientData.responsibleUser.id,
    });
  }, [alterClientMethod, clientData]);

  // Renderização condicional enquanto os dados do cliente não são carregados
  if (!clientData) return <></>;

  // Abre o menu de usuários ao clicar no ícone de configurações
  const openUsersMenu = (event) => {
    setUsersMenu(event.currentTarget);
  };

  // Fecha o menu de usuários
  const closeUsersMenu = () => {
    setUsersMenu(null);
  };

  // Verifica se o menu de usuários está aberto
  const isUsersMenuOpen = Boolean(usersMenu);

  // Função para atualizar os dados do cliente
  async function updateClient({ name, phoneNumber }) {
    try {
      await commercialApi.updateContact({
        contactId: clientData.id,
        name,
        phoneNumber,
      });
      setMessage('Cliente Alterado !');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  // Função para atualizar o período do cliente
  async function updateClientPeriod() {
    try {
      await commercialApi.updateContactPeriod({
        contactId: clientData.id,
        value: selectedPeriod,
      });
      setMessage('Período Alterado !');
      setSelectedPeriod(undefined);
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  // Função para atribuir um usuário ao cliente
  async function assignContact() {
    try {
      await commercialApi.assignContactToAnUser({
        contactId: clientData.id,
        userId: userIdState,
      });
      setMessage('Usuário atribuído !');
      setOpenRelationateDialog(false);
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  return (
    <>
      <FormProvider {...alterClientMethod}>
        <Stack spacing={2}>
          <TextFieldInput
            sx={{ width: '100%' }}
            name="name"
            label="Nome do cliente"
          />
          <TextFieldInput
            sx={{ width: '100%' }}
            name="phoneNumber"
            label="Contato"
          />
          <Button onClick={alterClientMethod.handleSubmit(updateClient)}>
            Salvar
          </Button>
          <Divider />

          <TextField
            fullWidth
            label="Usuário atribuído"
            sx={{ pb: '10px', mt: '10px' }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment>
                  <SettingsIcon
                    edge="end"
                    color="#000"
                    sx={{ cursor: 'pointer' }}
                    onClick={openUsersMenu}
                    position="end"
                  />
                </InputAdornment>
              ),
            }}
            defaultValue={clientData.responsibleUser.username}
          />

          <PeriodComponent
            name="period"
            value={contactPeriod}
            onChange={(e) => {
              setSelectedPeriod(e.target.value);
            }}
          />
        </Stack>
        <Dialog open={openRelationateDialog} TransitionComponent={Transition}>
          <DialogTitle>Confirmar atribuição</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                Deseja atribuir o usuário {username} ao cliente{' '}
                {clientData.name}?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenRelationateDialog(false)}>
              Cancelar
            </Button>
            <Button onClick={() => assignContact()}>Alterar</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={selectedPeriod !== undefined}
          TransitionComponent={Transition}
        >
          <DialogTitle>Confirmar Alteração</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                Deseja alterar o período para
                {`"${periodMap.get(selectedPeriod)}"`}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedPeriod(undefined);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setContactPeriod(selectedPeriod);
                updateClientPeriod();
              }}
            >
              Alterar
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={usersMenu}
          open={isUsersMenuOpen}
          onClose={() => setUsersMenu(false)}
        >
          {users.map((user) => {
            return (
              <>
                <MenuItem
                  key={user.id}
                  onClick={() => {
                    setUserIdState(user.id);
                    setUsername(user.username);
                    setOpenRelationateDialog(true);
                    closeUsersMenu();
                  }}
                >
                  <ListItemText inset>{user.username}</ListItemText>
                </MenuItem>
                <Divider inset />
              </>
            );
          })}
        </Menu>
      </FormProvider>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
