import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Tab({ active, label, onClick }) {
  return (
    <Paper
      onClick={onClick}
      elevation={0}
      sx={{
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 6.5,
        borderTopRightRadius: 6.5,
        backgroundColor: active ? '#EDEDED' : '#595959',
        color: active ? '#060709' : '#E3E3E3',
        borderBottomColor: '#EDEDED',

        cursor: 'pointer',
        py: 1.5,
        px: 2.5,
      }}
    >
      <Typography textAlign="center">{label}</Typography>
    </Paper>
  );
}
