import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TicketInfo from './TicketInfo';
import TicketHistory from './TicketHistory';
import TicketNotes from './TicketNotes';
import { useParams } from 'react-router-dom';
import PrivateRoute from '../../hoc/PrivateRoute';

function Ticket() {
  const { ticketId } = useParams();

  return (
    <Container
      maxWidth="md"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'row',
      }}
    >
      <Stack spacing={8} pb={4}>
        <TicketInfo ticketId={ticketId} />
        <TicketNotes ticketId={ticketId} />
        <TicketHistory ticketId={ticketId} />
      </Stack>
    </Container>
  );
}

export default PrivateRoute(Ticket);
