import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';

export default function DropdownFieldInput({
  name,
  label,
  variant,
  ...autoCompleteProps
}) {
  // setValue is necessary because,
  // it does not follow the same structure
  // from other input components
  const { control, setValue } = useFormContext();
  const { field, fieldState } = useController({
    name: name ?? '',
    control,
  });
  //console.log(autoCompleteProps);
  return (
    <Autocomplete
      {...autoCompleteProps}
      name={field.name}
      onBlur={field.onBlur}
      ref={field.ref}
      value={field.value ? field.value : null}
      defaultValue={field.value ? field.value : null}
      // Can not be field.onChange, since the event type
      // is different for Autocomplete components.
      isOptionEqualToValue={(v, t) => v == t}
      onChange={(e, value) => setValue(name, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
}
