import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useEditWorkspace } from '../../contexts/EditWorkspaceContext';
import { steps } from './EditWorkspaceForm';
import { useNavigate } from 'react-router-dom';

function WorkspaceFormButtons({ onBack, onNext, loading, valid }) {
  const handleRequest = useSnackbar();

  const { step, prevStep, nextStep, handleSubmit, saveEdit } =
    useEditWorkspace();

  const isLastStep = step === steps.length;
  const isFirstStep = step === 1;

  const navigate = useNavigate();

  async function save(data) {
    await handleRequest(async () => await saveEdit(data));
  }

  function onNextHandler(data) {
    if (isLastStep) return save(data);
    else nextStep();
  }

  return (
    <Stack direction="row" spacing={1}>
      <Button
        onClick={
          isFirstStep ? () => navigate('/viewDrivers') : onBack ?? prevStep
        }
        fullWidth
        variant="contained"
      >
        Voltar
      </Button>
      <LoadingButton
        loading={loading}
        fullWidth
        variant="contained"
        onClick={onNext ?? handleSubmit(onNextHandler)}
        disabled={valid === false} 
      >
        {isLastStep ? 'Concluir' : 'Próximo'}
      </LoadingButton>
    </Stack>
  );
}

export default WorkspaceFormButtons;
