import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useEffect } from 'react';

export const options = [  
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
    'DF',
];

export default function CrlvStateInput(props) {
  const { field, fieldState } = useController({ name: props.name });

  const [stateValue, setStateValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setStateValue(field.value); // Define o estado inicial com base no valor pré-selecionado
  }, [field.value]);

  //atualiza o estado
  const handleStateChange = value => {
    field.onChange(value);
    setStateValue(value)
    props.handleStateValue(value);
  }

  return (
    <>
      <Autocomplete
        {...props}
        {...field}
        value={stateValue}
        onChange={(event, newValue) => {
          handleStateChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        error={Boolean(fieldState.error)}
        helperText={fieldState.error?.message}
        id="controllable-states-demo"
        options={options}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label="Estado da CRLV" />
      }
      />
    </>
  );
}
