import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DropdownFieldInput from '../../../components/Form/DropdownFieldInput';
import { FormProvider } from 'react-hook-form';
import useYupForm from '../../../hooks/useYupForm';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ticketsApi } from '../../../api';
const createOptionSchema = yup.object({
  option: yup.string(),
  ticketId: yup.number(),
});
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export default function ExcludeTicketDialog({ open, handleClose, ticketId }) {
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const navigate = useNavigate();
  const options = useYupForm(createOptionSchema, {
    option: '',
    ticketId,
  });
  async function deleteTickt({ option }) {
    try {
      await ticketsApi.deleteTicket({
        ticketId,
        reason: option,
      });

      setMessage('Excluído !');
      setOpenStatusSuccess(true);
      navigate(`/dashboard`);
    } catch (error) {
      if (error.response === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }
  return (
    <>
      <FormProvider {...options}>
        <Dialog
          fullWidth
          sx={{ p: 10 }}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
        >
          <DialogTitle sx={{ justifyContent: 'center' }}>
            Selecione o motivo
          </DialogTitle>
          <DialogContent>
            <DropdownFieldInput
              name="option"
              label="Motivos"
              sx={{ mt: 1 }}
              fullWidth
              options={['Desistência', 'Cliente', 'Incorreto']}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={options.handleSubmit(deleteTickt)}>Excluir</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
