import { useState } from 'react';
import Pagination from '../../components/Pagination';
import ModalDriverComponent from './ModalDriverComponent';
import usePagination from '../../hooks/usePagination';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ImageItem from './ImageItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #000',

  p: 4,
};
export default function TruckParts({
  havePagination,
  truckData,
  workspaceId,
  images,
  modal,
}) {
  const [openOwnerModal, setOpenOwnerModal] = useState(false);
  const [openAnttModal, setOpenAnttModal] = useState(false);

  const handleOpenAnttModal = () => setOpenAnttModal(true);
  const handleCloseAnttModal = () => setOpenAnttModal(false);
  const handleOpenOwnerModal = () => setOpenOwnerModal(true);
  const handleCloseOwnerModal = () => setOpenOwnerModal(false);

  const pagination = usePagination({});

//formatando data da CNH (CAVALO)
const cnhValidationDateOwnerTractorTruck = truckData.owner && truckData.owner.cnhValidationDate ? new Date(truckData.owner.cnhValidationDate) : null;
const formattedCnhValidationDateOwnerTractorTruck = cnhValidationDateOwnerTractorTruck ? cnhValidationDateOwnerTractorTruck.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data do comprovante de residência (CAVALO)
const addressProofDateTractorTruck = truckData.owner && truckData.owner.addressProofDate ? new Date(truckData.owner.addressProofDate): null;
const formattedAddressProofDateTractorTruck = addressProofDateTractorTruck ? addressProofDateTractorTruck.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data da CNH (CAVALO)
const cnhValidationDateTractorTruckAntt = truckData.antt && truckData.antt.cnhValidationDate ? new Date (truckData.antt.cnhValidationDate) : null;
const formattedCnhValidationDateTractorTruckAntt = cnhValidationDateTractorTruckAntt ? cnhValidationDateTractorTruckAntt.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data do comprovante de residência (CAVALO)
const addressProofDateTractorTruckAntt = truckData.antt && truckData.antt.addressProofDate ? new Date (truckData.antt.addressProofDate) : null;
const formattedAddressProofDateTractorTruckAntt = addressProofDateTractorTruckAntt ? addressProofDateTractorTruckAntt.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data da CNH (CARRETA)
const cnhValidationDateOwnerCart = truckData[pagination.page]?.owner && truckData[pagination.page]?.owner?.cnhValidationDate ? new Date(truckData[pagination.page].owner.cnhValidationDate) : null;
const formattedCnhValidationDateOwnerCart = cnhValidationDateOwnerCart ? cnhValidationDateOwnerCart.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data do comprovante de residência (CARRETA)
const addressProofDateOwnerCart = truckData[pagination.page]?.owner && truckData[pagination.page]?.owner?.addressProofDate ? new Date(truckData[pagination.page].owner.addressProofDate) : null;
const formattedAddressProofDateCart = addressProofDateOwnerCart ? addressProofDateOwnerCart.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data do comprovante de residência (CAVALO)
const cnhValidationDateCartAntt = truckData[pagination.page]?.antt && truckData[pagination.page]?.antt.cnhValidationDate ? new Date (truckData[pagination.page]?.antt.cnhValidationDate) : null;
const formattedCnhValidationDateCartAntt = cnhValidationDateCartAntt ? cnhValidationDateCartAntt.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

//formatando data do comprovante de residência (CAVALO)
const addressProofDateCartAntt = truckData[pagination.page]?.antt && truckData[pagination.page]?.antt.addressProofDate ? new Date (truckData[pagination.page]?.antt.addressProofDate) : null;
const formattedAddressProofDateCartAntt = addressProofDateCartAntt ? addressProofDateCartAntt.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "sem data";

  return havePagination ? (
    <ModalDriverComponent
      workspaceId={workspaceId}
      images={[{ alt: 'crlv', img: truckData[pagination.page].crlv }]}
      modal={modal}
      content={[
        {
          name: 'Placa: ',
          text: truckData[pagination.page].licensePlate
            ? truckData[pagination.page].licensePlate
            : 'Sem placa',
        },
        {
          name: 'Exercício: ',
          text: truckData[pagination.page].crlvExercise
            ? truckData[pagination.page].crlvExercise
            : 'Sem Exercício',
        },
        {
          name: 'Estado: ',
          text: truckData[pagination.page].crlvState
            ? truckData[pagination.page].crlvState
            : 'Sem estado',
        }
      ]}
    >
      {truckData[pagination.page].owner ? (
        <>
          <Paper
            sx={{
              p: 2.5,
              m: 3,
            }}
          >
            <Typography sx={{ py: 1 }}>
              <strong>Proprietário</strong>
            </Typography>
            <Button onClick={handleOpenOwnerModal}>Ver mais</Button>
          </Paper>

          <Modal open={openOwnerModal} onClose={handleCloseOwnerModal}>
            <Box sx={style}>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <ImageList
                    sx={{
                      /* width: '100%', */
                      height: 450,

                      '&::-webkit-scrollbar': {
                        backgroundColor: '#D9E3E8',
                        margin: -10,
                      },
                    }}
                    cols={3}
                    rowHeight={164}
                  >
                    {[
                      ...truckData[pagination.page].owner.cnh,
                      ...truckData[pagination.page].owner.addressProof,
                    ].map((item) => (
                      <ImageItem key={item} name={item} onClick={modal} />
                    ))}
                  </ImageList>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      p: 2.5,
                      m: 3,
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    <Typography>
                      {' '}
                        <strong>Telefone: </strong>
                        {truckData[pagination.page].owner.phoneNumber}
                    </Typography>
                    <Typography>
                      {' '}
                        <strong>Data de validade da CNH: </strong>
                        {formattedCnhValidationDateOwnerCart}
                    </Typography>
                    <Typography>
                      {' '}
                        <strong>Data do comprovante de residência: </strong>
                        {formattedAddressProofDateCart}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}

      {truckData[pagination.page].antt ? (
        <>
          <Paper
            sx={{
              p: 2.5,
              m: 3,
            }}
          >
            <Typography sx={{ py: 1 }}>
              <strong>ANTT</strong>
            </Typography>
            <Button onClick={handleOpenAnttModal}>Ver mais</Button>
          </Paper>
          <Modal open={openAnttModal} onClose={handleCloseAnttModal}>
            <Box sx={style}>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <ImageList
                    sx={{
                      width: '100%',
                      height: 450,
                      ml: 2,
                      '&::-webkit-scrollbar': {
                        backgroundColor: '#D9E3E8',
                      },
                    }}
                    cols={3}
                    rowHeight={164}
                  >
                    {[
                      ...truckData[pagination.page].antt.rntrc,
                      ...truckData[pagination.page].antt.cnh,
                      ...truckData[pagination.page].antt.addressProof,
                    ].map((item) => (
                      <ImageItem key={item} name={item} onClick={modal} />
                    ))}
                  </ImageList>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      p: 2.5,
                      m: 3,
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    <Typography>
                      {' '}
                      <strong>Telefone: </strong>
                      {truckData[pagination.page].antt.phoneNumber}
                    </Typography>
                    <Typography>
                      {' '}
                      <strong>Data de validade da CNH: </strong>
                      {formattedCnhValidationDateCartAntt}
                    </Typography>
                    <Typography>
                      {' '}
                      <strong>Data do comprovante de residência: </strong>
                      {formattedAddressProofDateCartAntt}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}

      <Pagination
        sx={{ m: 3 }}
        {...pagination.register()}
        disableRight={pagination.page == truckData.length - 1}
      />
    </ModalDriverComponent>
  ) : (
    <ModalDriverComponent
      workspaceId={workspaceId}
      images={images}
      modal={modal}
      content={[
        {
          name: 'Placa: ',
          text: truckData.licensePlate ? truckData.licensePlate : 'Sem Placa',
        },
        {
          name: 'Exercício: ',
          text: truckData.crlvExercise ? truckData.crlvExercise : 'Sem Exercício',
        },
        {
          name: 'Estado: ',
          text: truckData.crlvState ? truckData.crlvState : 'Sem estado',
        }
      ]}
    >
      {truckData.owner ? (
        <>
          <Paper
            sx={{
              p: 2.5,
              m: 3,
            }}
          >
            <Typography sx={{ py: 1 }}>
              <strong>Proprietário</strong>
            </Typography>
            <Button onClick={handleOpenOwnerModal}>Ver mais</Button>
          </Paper>

          <Modal open={openOwnerModal} onClose={handleCloseOwnerModal}>
            <Box sx={style}>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <ImageList
                    sx={{
                      /* width: '100%', */
                      height: 450,

                      '&::-webkit-scrollbar': {
                        backgroundColor: '#D9E3E8',
                        margin: -10,
                      },
                    }}
                    cols={3}
                    rowHeight={164}
                  >
                    {[
                      ...truckData.owner.cnh,
                      ...truckData.owner.addressProof,
                    ].map((item) => (
                      <ImageItem key={item} name={item} onClick={modal} />
                    ))}
                  </ImageList>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      p: 2.5,
                      m: 3,
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    {/* dados exibidos do proprietário do cavalo*/}
                    <Typography>
                      {' '}
                      <strong>Telefone: </strong>
                      {truckData.owner.phoneNumber}
                      <div>
                        <strong>Data de validade da CNH: </strong>
                        {formattedCnhValidationDateOwnerTractorTruck}
                      </div>
                      <div>
                        <strong>Data do comprovante de residência: </strong>
                        {formattedAddressProofDateTractorTruck}
                      </div>
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}

      {truckData.antt ? (
        <>
          <Paper
            sx={{
              p: 2.5,
              m: 3,
            }}
          >
            <Typography sx={{ py: 1 }}>
              <strong>ANTT</strong>
            </Typography>
            <Button onClick={handleOpenAnttModal}>Ver mais</Button>
          </Paper>
          <Modal open={openAnttModal} onClose={handleCloseAnttModal}>
            <Box sx={style}>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <ImageList
                    sx={{
                      width: '100%',
                      height: 450,
                      ml: 2,
                      '&::-webkit-scrollbar': {
                        backgroundColor: '#D9E3E8',
                      },
                    }}
                    cols={3}
                    rowHeight={164}
                  >
                    {[
                      ...truckData.antt.rntrc,
                      ...truckData.antt.cnh,
                      ...truckData.antt.addressProof,
                    ].map((item) => (
                      <ImageItem key={item} name={item} onClick={modal} />
                    ))}
                  </ImageList>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    sx={{
                      p: 2.5,
                      m: 3,
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    <Typography>
                      {' '}
                      <strong>Telefone: </strong>
                      {truckData.antt.phoneNumber}
                    </Typography>
                    <Typography>
                      {' '}
                      <strong>Data de validade da CNH: </strong>
                      {formattedCnhValidationDateTractorTruckAntt}
                    </Typography>
                    <Typography>
                      {' '}
                      <strong>Data do comprovante de residência: </strong>
                      {formattedAddressProofDateTractorTruckAntt}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </ModalDriverComponent>
  );
}
