import * as React from 'react';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

const declinedColor = '#FFA07A'
const inProgressColor = '#EEE8AA'
const notStardedColor = '#FFFFFF'
const sendSectorColor = '#ffff7a'
const waitingReturnColor = '#ffc26c'
const waitingAdjustmentColor = '#ff6347'
const finishedColor = "#84fa84"

const theme = createTheme({
    palette: {
        colorStatus: {
            DECLINED: declinedColor,
            IN_PROGRESS: inProgressColor,
            NOT_STARTED: notStardedColor,
            SEND_SECTOR: sendSectorColor,
            WAITING_RETURN: waitingReturnColor,
            WAITING_ADJUSTMENT: waitingAdjustmentColor,
            FINISHED: finishedColor,
        },
    },
});

export default function SquareColor() {
    return(
        <ThemeProvider theme={theme}>
            <Stack Stack gap={2} alignItems="center">
                <Stack direction="column" gap={1} marginRight={'0.5%'}>
                    <Stack flexDirection={'row'} gap={1} marginTop={'180%'}>

                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px', 
                            bgcolor: 'colorStatus.DECLINED', 
                            width: 40, 
                            height: 20 
                        }}/>
                        <Typography variant="body2">
                            Recusado
                        </Typography>
                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px', 
                            bgcolor: 'colorStatus.IN_PROGRESS', 
                            width: 40, 
                            height: 20  
                            }}/>
                        <Typography variant="body2">
                            Iniciado
                        </Typography>
                        
                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px', 
                            bgcolor: 'colorStatus.NOT_STARTED', 
                            width: 40, 
                            height: 20 
                            }}/>
                        <Typography variant="body2">
                            Não iniciado
                        </Typography>
                        
                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px', 
                            bgcolor: 'colorStatus.SEND_SECTOR', 
                            width: 40, 
                            height: 20 
                            }}/>
                        <Typography variant="body2">
                            Enviado ao setor responsável
                        </Typography>
                        
                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px', 
                            bgcolor: 'colorStatus.WAITING_RETURN', 
                            width: 40, 
                            height: 20 }} />
                        <Typography variant="body2">
                            Aguardando Retorno do Cliente</Typography>

                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px',
                            bgcolor: 'colorStatus.WAITING_ADJUSTMENT'
                            , width: 40, 
                            height: 20 }} />
                        <Typography variant="body2">
                            Pendente-Aguardando Ajuste
                        </Typography>

                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Box 
                        sx={{ 
                            borderColor:'#0000', 
                            border:'solid 1px', 
                            bgcolor: 'colorStatus.FINISHED', 
                            width: 40, 
                            height: 20 }} />
                        <Typography variant="body2">Finalizado-Aguardando Resposta do Líder
                    </Typography>

                    </Stack>
                </Stack>
            </Stack>
    </ThemeProvider>
    );
}