import WorkspaceFormButtons from '../WorkspaceFormButtons';
import '../../../shared/yup-locale';

import * as yup from 'yup';
import ContactsFieldInput from '../ContactsFieldInput';

export const referencesFormSchema = yup.object({
  personalContacts: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required().label('Nome'),
        phoneNumber: yup.string().required().min(14).label('Telefone'),
      })
    )
    .min(2)
    .required()
    .label('Contatos'),
  companyContacts: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required().label('Nome'),
        phoneNumber: yup
          .string()
          .min(14)
          .transform((v) => {
            return v == '' ? undefined : v;
          })
          .label('Telefone'),
      })
    )
    .min(3)
    .required()
    .label('Contatos'),
});

export default function ReferencesForm() {
  return (
    <>
      <ContactsFieldInput
        required
        name="references.personalContacts"
        label="Contatos Pessoais"
        prefix="Contato Pessoal"
        min={2}
        max={Infinity}
      />

      <ContactsFieldInput
        name="references.companyContacts"
        label="Contatos Empresariais"
        prefix="Contato Empresarial"
        min={3}
        max={Infinity}
      />

      <WorkspaceFormButtons />
    </>
  );
}
