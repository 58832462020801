import React, { useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextFieldInput from '../../components/Form/TextFieldInput';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import useAuthSWR from '../../hooks/useAuthSWR';
import { ROLE_NAME_MAP } from '../../consts/dropdownRoles';
import Button from '@mui/material/Button';
import { usersApi } from '../../api';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const creatorSchema = yup.object({
  name: yup
    .string()
    .label('Nome do usuário')
    .min(2, 'minimo 2 caracteres')
    .max(50),
  username: yup
    .string()
    .label('Nome do usuário')
    .min(2, 'minimo 2 caracteres')
    .max(50),
  phoneNumber: yup.string(),
  roleName: yup.object(),
  sector: yup.object().nullable(),
  password: yup.string(),
  room: yup.object().nullable(),
  from: yup.object().nullable(),
  to: yup.object().nullable(),
  fromRoom: yup.object().nullable(),
  toRoom: yup.object().nullable(),
});

export default function UserCard({ user, mutateUser }) {
  const creatorMethods = useYupForm(creatorSchema, {
    username: user.username,
    name: user.name,
    roleName: ROLE_NAME_MAP[user.role],
    sector: user.sectors,
    cellphone: user.cellphone,
    password: user.password,
    room: user.room,
    from: user.sector,
    to: user.sector,
    fromRoom: user.room,
    toRoom: user.room,
  });
  const { enqueueSnackbar } = useSnackbar();

  const { data: sectors } = useAuthSWR('/sectors');
  const { data: rooms } = useAuthSWR('/rooms');

  const [isCardExpanded, setIsCardExpanded] = useState(false);

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [isSectorMenuOpen, setIsSectorMenuOpen] = useState(false);
  const [selectedSectorToAdd, setSelectedSectorToAdd] = useState(null);
  const [selectedSectorToRemove, setSelectedSectorToRemove] = useState(null);

  const sectorMenuAnchor = useRef(null);

  const sectorsNotAttributedToUser = useMemo(() => {
    if (!sectors || !user) return [];

    return sectors.filter(
      (sector) =>
        user.sectors.findIndex((userSector) => userSector.id === sector.id) ===
        -1
    );
  }, [sectors, user]);

  const openSectorMenu = () => setIsSectorMenuOpen(true);
  const closeSectorMenu = () => setIsSectorMenuOpen(false);
  const openPasswordDialog = () => setIsPasswordDialogOpen(true);
  const closePasswordDialog = () => setIsPasswordDialogOpen(false);
  const closeSectorRemoveDialog = () => setSelectedSectorToRemove(null);
  const closeSectorAdditionDialog = () => setSelectedSectorToAdd(null);
  const closeRoomUpdateDialog = () => setSelectedRoom(null);

  async function updateUserInfo({ username, name }) {
    try {
      await usersApi.updateUserInfo({
        id: user.id,
        username,
        name,
      });

      mutateUser({
        ...user,
        name,
        username,
      });

      enqueueSnackbar('Usuário atualizado', { variant: 'success' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (
        error.response.status === 422 &&
        error.response.data.code == 'NON_UNIQUE_FIELD'
      ) {
        if (error.response.data.fieldName == 'username')
          enqueueSnackbar('Nome de Usuário já utilizado', { variant: 'error' });

        if (error.response.data.fieldName == 'name')
          enqueueSnackbar('Nome Completo já utilizado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    }
  }

  async function updateUserPassword({ password }) {
    try {
      await usersApi.changeUserPassword({
        password,
        id: user.id,
      });

      enqueueSnackbar('Senha atualizada', { variant: 'success' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    }
  }

  async function addUserToSector({ sector }) {
    try {
      await usersApi.addUserToSector({
        id: user.id,
        sectorId: sector.id,
      });

      mutateUser({
        ...user,
        sectors: [...user.sectors, sector],
      });

      enqueueSnackbar('Usuário adicionado ao setor', { variant: 'success' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    }
  }

  async function removeUserFromSector({ sector }) {
    try {
      await usersApi.removeUserFromSector({
        id: user.id,
        sectorId: sector.id,
      });

      mutateUser({
        ...user,
        sectors: user.sectors.filter(({ id }) => id !== sector.id),
      });

      enqueueSnackbar('Usuário removido do setor', { variant: 'success' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    }
  }

  async function addUserToRoom({ room }) {
    try {
      await usersApi.addUserToRoom({
        id: user.id,
        roomId: room.id,
      });

      mutateUser({
        ...user,
        room,
      });

      enqueueSnackbar('Usuário adcionado à sala', { variant: 'success' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    }
  }

  async function deleteUser() {
    try {
      await usersApi.deleteUserById({ id: user.id });

      enqueueSnackbar('Usuário removido', { variant: 'success' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        enqueueSnackbar('Não autorizado', { variant: 'error' });
      } else if (error.response.status === 403) {
        enqueueSnackbar('Sem direito de acesso', { variant: 'error' });
      } else if (error.response.status === 404) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'error' });
      } else if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar('Erro de conexão', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro desconhecido', { variant: 'error' });
      }
    }
  }

  if (!sectors) return <></>;
  if (!rooms) return <></>;

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <FormProvider {...creatorMethods}>
          <CardHeader
            title={user.name}
            subheader={user.username}
            action={
              <ExpandMore
                expand={isCardExpanded}
                onClick={() => setIsCardExpanded((v) => !v)}
                aria-expanded={isCardExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          />

          <Collapse in={isCardExpanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container columnSpacing={1} rowSpacing={2}>
                <Grid item xs={6}>
                  <TextFieldInput name="name" label="Nome Completo" fullWidth />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    options={rooms}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    value={user.room}
                    fullWidth
                    onChange={(e, value) => setSelectedRoom(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        label="Sala"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldInput
                    name="username"
                    label="Nome de Usuário"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    options={sectorsNotAttributedToUser}
                    getOptionLabel={(option) => option.name}
                    value={user.sectors}
                    readOnly
                    fullWidth
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={index}
                          label={option.name}
                          color="secondary"
                          onDelete={() => setSelectedSectorToRemove(option)}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment
                              onClick={openSectorMenu}
                              ref={sectorMenuAnchor}
                            >
                              <IconButton>
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label="Setores"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} display="flex" justifyContent="space-between">
                  <Link
                    component="button"
                    variant="body1"
                    onClick={openPasswordDialog}
                  >
                    Alterar senha
                  </Link>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={creatorMethods.handleSubmit(updateUserInfo)}
                  >
                    Salvar
                  </Button>
                </Grid>

                <Grid item xs={6} display="flex" justifyContent="right">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={deleteUser}
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>

          <Menu
            anchorEl={sectorMenuAnchor.current}
            open={isSectorMenuOpen}
            onClose={closeSectorMenu}
          >
            {sectorsNotAttributedToUser.map((sector) => {
              return (
                <MenuItem
                  key={sector.id}
                  onClick={() => {
                    setSelectedSectorToAdd(sector);
                    closeSectorMenu();
                  }}
                >
                  <ListItemText>{sector.name}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>

          <Dialog
            open={isPasswordDialogOpen}
            maxWidth="xs"
            fullWidth
            TransitionComponent={Transition}
          >
            <DialogTitle>Alterar senha</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <Typography>
                  Digite no campo a nova senha do usuário {user.name}:
                </Typography>
              </DialogContentText>

              <TextFieldInput
                sx={{ mt: '10px' }}
                name="password"
                label="Senha"
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={closePasswordDialog}>Cancelar</Button>
              <Button onClick={creatorMethods.handleSubmit(updateUserPassword)}>
                Alterar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={Boolean(selectedSectorToRemove)}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="sm"
            onClose={closeSectorRemoveDialog}
          >
            <DialogTitle>Confirmar remoção</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Deseja remover o usuário
                <b> {user.username} </b>
                do setor <b>{selectedSectorToRemove?.name}</b>?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeSectorRemoveDialog}>Cancelar</Button>
              <Button
                onClick={() => {
                  removeUserFromSector({ sector: selectedSectorToRemove });
                  closeSectorRemoveDialog();
                }}
              >
                Remover
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={Boolean(selectedSectorToAdd)}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="sm"
            onClose={closeSectorAdditionDialog}
          >
            <DialogTitle>Confirmar alteração</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Deseja adicionar o usuário
                <b> {user.username} </b>
                ao setor <b>{selectedSectorToAdd?.name}</b>?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeSectorAdditionDialog}>Cancelar</Button>

              <Button
                onClick={() => {
                  addUserToSector({
                    sector: selectedSectorToAdd,
                  });
                  closeSectorAdditionDialog();
                }}
              >
                Adicionar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={Boolean(selectedRoom)}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="sm"
            onClose={closeRoomUpdateDialog}
          >
            <DialogTitle>Confirmar alteração</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Deseja alocar o usuário
                <b> {user.username} </b>
                na sala <b>{selectedRoom?.name}</b>?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeRoomUpdateDialog}>Cancelar</Button>
              <Button
                onClick={() => {
                  addUserToRoom({
                    room: selectedRoom,
                  });
                  closeRoomUpdateDialog();
                }}
              >
                Adicionar
              </Button>
            </DialogActions>
          </Dialog>
        </FormProvider>
      </Card>
    </>
  );
}
