import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useController } from 'react-hook-form';

export default function CheckboxInput({ FormControlLabelProps, ...props }) {
  const { field } = useController({ name: props.name });

  return (
    <FormControlLabel
      control={<Checkbox {...props} {...field} ckecked={field.value}/>}
      checked={field.value}
      label={props.label}
      {...FormControlLabelProps}
    />
  );
}
