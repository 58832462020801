import { addMethod, mixed } from 'yup';

[mixed].forEach((type) => {
  addMethod(type, 'step', function step(step, schema) {
    return this.when('step', {
      is: step,
      then: schema,
      else: mixed(),
    });
  });
});
