import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ResourceListItem({
  resource,
  selected,
  expanded,
  onSelect,
  onExpand,
  editing,
  onEdit,
  onDelete,
  ResourceImageCollapse,
  ResourceEditCollapse,
}) {
  return (
    <>
      <ListItem disablePadding>
        <Checkbox
          edge="start"
          checked={selected}
          onChange={(e, v) => {
            e.stopPropagation();
            onSelect(resource, v);
          }}
        />

        <ListItemButton onClick={() => onExpand(resource, !expanded)}>
          <ListItemText primary={resource.phoneNumber} />

          {onEdit && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onEdit(resource);
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {onDelete && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete(resource);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}

          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>

      <ResourceImageCollapse open={expanded} resource={resource} />
      <ResourceEditCollapse open={editing} resource={resource} />
    </>
  );
}
