import AnttImageCollapse from './AnttImageCollapse';
import AnttEditCollapse from './AnttEditCollapse';
import ResourceListItem from '../../WorkspaceResourceDialog/ResourceListItem';

export default function AnttListItem({
  resource: antts,
  selected,
  expanded,
  onSelect,
  onExpand,
  editing,
  onEdit,
  onDelete,
}) {
  return (
    <ResourceListItem
      resource={antts}
      selected={selected}
      expanded={expanded}
      onSelect={onSelect}
      onExpand={onExpand}
      editing={editing}
      onEdit={onEdit}
      onDelete={onDelete}
      ResourceEditCollapse={AnttEditCollapse}
      ResourceImageCollapse={AnttImageCollapse}
    />
  );
}
