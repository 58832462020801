import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Skeleton from '@mui/material/Skeleton';
import useAuthSWR from '../hooks/useAuthSWR';
import Menu from '@mui/material/Menu';
import notificationApi from '../api/notification.api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import AlarmIcon from '@mui/icons-material/Alarm';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import GavelIcon from '@mui/icons-material/Gavel';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Button from '@mui/material/Button';
import BuildIcon from '@mui/icons-material/Build';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useAuth } from '../contexts/AuthContext';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Collapse from '@mui/material/Collapse';
import ConstructionIcon from '@mui/icons-material/Construction';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Link from '@mui/material/Link';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import ViewListIcon from '@mui/icons-material/ViewList';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Grid from '@mui/material/Grid';
import NearbyTrucks from '../pages/Dashboard/NearbyTrucks';
import DialogActions from '@mui/material/DialogActions';
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FormProvider } from 'react-hook-form';
import TextFieldInput from './Form/TextFieldInput';
import CallIcon from '@mui/icons-material/Call';

import useYupForm from '../hooks/useYupForm';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const hotkeysMap = new Map([['1', '/login']]);
const driverFeedbackSchema = yup.object({
  feedback: yup.string().required(),
});
export default function NavBar() {
  const { user: me, logout } = useAuth();
  /* logout() + navigate */
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const MySector = me?.sector?.at(0)?.id;
  const { data: sol } = useAuthSWR(
    `/solicitations?${me?.id}`,
    {},
    {},
    MySector == undefined
  );
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const result = String(month).padStart(2, '0') + '-' + year;

  const { data: recommendationStatus } = useAuthSWR(
    '/recommendations/users/me/recommendation-status',
    { date: result },
    { refreshInterval: 1000 * 30 }
  );

  const driverFeedbackMethod = useYupForm(driverFeedbackSchema);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (!event.ctrlKey) return;

      if (event.key === 'm') {
        event.preventDefault();
        setIsDrawerOpen((v) => !v);
      }

      if (hotkeysMap.get(event.key) !== undefined) {
        event.preventDefault();
        setIsDrawerOpen(false);
        navigate(hotkeysMap.get(event.key));
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [setIsDrawerOpen, navigate]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const [openPageNavigator, setOpenPageNavigator] = useState(false);
  const [openTicketHistoricOptions, setOpenTicketHistoricOptions] =
    useState(false);
  const [openReportOptions, setOpenReportOptions] = useState(false);
  const [isDriverDialogOpen, setIsDriverDialogOpen] = useState(false);
  const [commercialOptionsOpen, setCommercialOptionsOpen] = useState(false);
  const [downRegisterFormOpen, setDownRegisterFormOpen] = useState(false);
  const [boardingOptionsOpen, setBoardingOptionsOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleState = () => {
    setIsDriverDialogOpen(!isDriverDialogOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const down = () => {
    setOpenPageNavigator((v) => !v);
  };
  const downTicketOptions = () => {
    setOpenTicketHistoricOptions((v) => !v);
  };
  const downReportOptions = () => {
    setOpenReportOptions((v) => !v);
  };

  const downCommercialOptions = () => {
    setCommercialOptionsOpen((v) => !v);
  };
  const downRegisterFormOptions = () => {
    setDownRegisterFormOpen((v) => !v);
  };
  const downBoardingOptionsOpen = () => {
    setBoardingOptionsOpen((v) => !v);
  };
  if (!me) return <></>;

  async function analize({ solicitationId, approved }) {
    try {
      await notificationApi.analizeSolicitation({
        solicitationId,
        approved,
      });

      setMessage('Solicitado !');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }

  const list = () => (
    <Box>
      <List sx={{ postion: 'absolute' }}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => (navigate('/dashboard'), setIsDrawerOpen(false))}
          >
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <Divider variant="middle" />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => (navigate('/createTicket'), setIsDrawerOpen(false))}
          >
            <ListItemIcon>
              <ConfirmationNumberIcon />
            </ListItemIcon>
            <ListItemText primary="Ticket" />
          </ListItemButton>
        </ListItem>
        <Divider variant="middle" />

        <ListItem onClick={downBoardingOptionsOpen} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <CallIcon />
            </ListItemIcon>
            <ListItemText sx={{ pr: 2 }} primary="Embarques" />
            {boardingOptionsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={boardingOptionsOpen} timeout="auto" unmountOnExit>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (navigate('/acomp'), setIsDrawerOpen(false))}
            >
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText primary="Acomp" />
            </ListItemButton>
            <Divider variant="middle" />

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/FlowOfTrucks'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="Reembarques" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider variant="middle" />
        <ListItem onClick={downRegisterFormOptions} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText sx={{ pr: 2 }} primary="Cadastros" />
            {downRegisterFormOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={downRegisterFormOpen} timeout="auto" unmountOnExit>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (navigate('/ViewDrivers'), setIsDrawerOpen(false))}
            >
              <ListItemIcon>
                <DriveFolderUploadIcon />
              </ListItemIcon>
              <ListItemText primary="Pastas" />
            </ListItemButton>
            <Divider variant="middle" />

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/registrations'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <PlaylistAddCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Fichas" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider variant="middle" />

        <ListItem onClick={downCommercialOptions} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <AddIcCallIcon />
            </ListItemIcon>
            <ListItemText sx={{ pr: 2 }} primary="Comercial" />
            {commercialOptionsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={commercialOptionsOpen} timeout="auto" unmountOnExit>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/modules/commercial'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <FactCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Meus" />
            </ListItemButton>
            <Divider variant="middle" />

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/modules/commercial/all'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <RecentActorsIcon />
              </ListItemIcon>
              <ListItemText primary="Todos" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider variant="middle" />

        <ListItem onClick={down} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ConstructionIcon />
            </ListItemIcon>
            <ListItemText sx={{ pr: 2 }} primary="Configurações" />
            {openPageNavigator ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openPageNavigator} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/viewUsers'), setIsDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (navigate('/editTask'), setIsDrawerOpen(false))}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Task" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/manipulateRoom'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Filiais" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/assignPermissions'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Permissões" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/AssignTaskReponsability'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <EngineeringIcon />
              </ListItemIcon>
              <ListItemText primary="Responsabilidade" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
        </Collapse>
        <ListItem onClick={downTicketOptions} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Histórico" />
            {openTicketHistoricOptions ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openTicketHistoricOptions} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/completed-tickets'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText primary="Concluídos" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (
                navigate('/deleted-tickets'), setIsDrawerOpen(false)
              )}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Excluídos" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
        </Collapse>
        <ListItem onClick={downReportOptions} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
            {openReportOptions ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Divider variant="middle" />
        <Collapse in={openReportOptions} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (navigate('/reports'), setIsDrawerOpen(false))}
            >
              <ListItemIcon>
                <AlarmIcon />
              </ListItemIcon>
              <ListItemText primary="Produtividade" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (navigate('/timeReport'), setIsDrawerOpen(false))}
            >
              <ListItemIcon>
                <AlignVerticalBottomIcon />
              </ListItemIcon>
              <ListItemText primary="Tempo" />
            </ListItemButton>
          </List>
          <Divider variant="middle" />
        </Collapse>
      </List>
      <Link
        component="button"
        variant="body1"
        underline="hover"
        onClick={() => {
          logout(), navigate('/login');
        }}
        color="blue"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          bottom: '5px',
          pl: '10px',
        }}
      >
        Sair
      </Link>
    </Box>
  );

  const Notification = () => {
    return (
      <>
        <Menu
          open={open}
          onClose={() => handleClose()}
          onOpen={() => handleClick()}
          anchorEl={anchorEl}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Solicitações
          </Typography>

          {sol ? (
            sol.map((solicitation) => (
              <Alert
                key={solicitation.id}
                severity="info"
                icon={false}
                sx={{ background: '#DEE2E6', m: '10px', width: '500px' }}
                action={
                  <>
                    <Button
                      variant="text"
                      content
                      color="error"
                      onClick={() =>
                        analize({
                          solicitationId: solicitation.id,
                          approved: false,
                        })
                      }
                    >
                      Rejeitar
                    </Button>
                    <Button
                      color="success"
                      variant="text"
                      onClick={() =>
                        analize({
                          solicitationId: solicitation.id,
                          approved: true,
                        })
                      }
                    >
                      Aceitar
                    </Button>
                  </>
                }
              >
                {' '}
                {solicitation.createdBy.username}
              </Alert>
            ))
          ) : (
            <Skeleton variant="rectangular" height="100%" />
          )}
        </Menu>
      </>
    );
  };

  return (
    <>
      <AppBar position="fixed" sx={{ mb: '25px' }}>
        <Toolbar>
          <Grid container xs={12}>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              xs={11}
            >
              <IconButton onClick={() => setIsDrawerOpen(true)}>
                <MenuIcon sx={{ color: '#fff' }} />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color: '#fff' }}
              >
                Mervil
              </Typography>
            </Grid>

            <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              {list()}
            </Drawer>

            <Grid
              item
              xs={1}
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <IconButton>
                <Badge
                  badgeContent={recommendationStatus?.active}
                  color="warning"
                >
                  <Badge
                    badgeContent={recommendationStatus?.contacted}
                    color="success"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Badge
                      badgeContent={recommendationStatus?.losted}
                      color="error"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <LocalShippingIcon
                        sx={{ m: 0.5 }}
                        onClick={handleState}
                        color="secondary"
                      />
                    </Badge>
                  </Badge>
                </Badge>
              </IconButton>
              <Badge sx={{ color: '#fff' }}>
                <NotificationsIcon
                  ref={anchorEl}
                  color="secondary"
                  onClick={handleClick}
                />
                {Notification()}
              </Badge>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <NearbyTrucks
        shouldDialogOpen={isDriverDialogOpen}
        handleRecommendationState={handleState}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Retorno do motorista</DialogTitle>

        <DialogContent>
          <FormProvider {...driverFeedbackMethod}>
            <TextFieldInput
              sx={{ mt: 1 }}
              fullWidth
              name="feedback"
              label="Feedback"
            />
          </FormProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={() => setOpenDialog(false)}>Criar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
