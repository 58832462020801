import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuthSWR from '../../hooks/useAuthSWR';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Tab from './Tab';
import ImageComponent from './ImageComponent';
import ModalDriverComponent from './ModalDriverComponent';
import TruckParts from './TruckParts';
import ImageItem from './ImageItem';

const steps = [
  'Motorista',
  'Cavalo',
  'Carreta',
  'Banco',
  'Fotos',
  'Referências',
];

export default function DriverDetails() {
  const [activeStep, setActiveStep] = useState('Motorista');
  const [openModal, setOpenModal] = useState(false);
  const [imageName, setImageName] = useState('');
  const { cpf: workspaceId } = useParams();

  const handleCloseModal = () => setOpenModal(false);

  const { data: driver } = useAuthSWR(
    `/driver-workspaces/${workspaceId}/result`
  );
  if (!driver) return <></>;

  const handleModal = (name) => {
    setImageName(name);
    setOpenModal(true);
  };

  //data de validade da CNH do motorista
  const cnhValidationDate = driver.driver.cnhValidationDate === null ? null : new Date(driver.driver.cnhValidationDate)
  const formattedCnhValidationDate = cnhValidationDate === null ? 'sem data' : cnhValidationDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });

  //data do comprovante de residencia do motorista
  const addressProofDate = driver.driver.addressProofDate === null ? null : new Date(driver.driver.addressProofDate)
  const formattedAddressProofDate = addressProofDate === null ? 'sem data' : addressProofDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });

  return (
    <>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between">
          {steps.map((step) => {
            return (
              <Grid key={steps} item sx={{ justifyContent: 'space-between' }}>
                <Tab
                  active={step == activeStep}
                  label={step}
                  onClick={() => setActiveStep(step)}
                />
              </Grid>
            );
          })}
        </Grid>
        {activeStep == 'Motorista' ? (
          <>
            <ModalDriverComponent
              content={[
                { name: 'Nome: ', text: driver.driver.name },
                { name: 'CPF: ', text: driver.driver.cpf },
                { name: 'Telefone: ', text: driver.driver.phoneNumber },
                { name: 'Data de validade da CNH: ', text: formattedCnhValidationDate },
                { name: 'Data do comprovante de residência: ', text: formattedAddressProofDate },
              ]}
              images={[
                { alt: 'CNH', img: driver.driver.cnh },
                { alt: 'Residencia', img: driver.driver.addressProof },
                { alt: 'manifesto', img: driver.lastManifest },
              ]}
              workspaceId={workspaceId}
              modal={handleModal}
            />
          </>
        ) : (
          <></>
        )}
        {activeStep == 'Cavalo' ? (
          <>
            <TruckParts
              havePagination={false}
              workspaceId={workspaceId}
              truckData={driver.truckTractor}
              images={[{ alt: 'crlv', img: driver.truckTractor.crlv }]}
              modal={handleModal}
            />
          </>
        ) : (
          <></>
        )}
        {activeStep == 'Carreta' ? (
          <>
            {driver.truckTrailers.length > 0 ? (
              <TruckParts
                workspaceId={workspaceId}
                havePagination={true}
                truckData={driver.truckTrailers}
                modal={handleModal}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {activeStep == 'Banco' ? (
          <>
            <Paper
              sx={{
                backgroundColor: '#EDEDED',
                borderRadius: 0,
                minHeight: '72.5vh',
                display: 'flex',
              }}
              elevation={0}
            >
              <Grid
                container
                xs={12}
                sx={{
                  height: '100%',
                  display: 'flex',
                  minHeight: '72.5vh',
                  alignItems: 'stretch',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid xs={2} />
                <Grid item xs={8} sx={{}}>
                  <Paper
                    sx={{
                      p: 2.5,
                      mb: 'auto',
                      mt: '5',
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    <Typography sx={{ py: 1 }}>
                      <strong>Banco: </strong> {driver.bankAccount.bankName}
                    </Typography>
                    <Typography sx={{ py: 1 }}>
                      <strong>Agência: </strong>{' '}
                      {driver.bankAccount.agencyNumber}
                    </Typography>
                    <Typography sx={{ py: 1 }}>
                      <strong>Número da conta: </strong>{' '}
                      {driver.bankAccount.accountNumber}
                    </Typography>
                    <Typography sx={{ py: 1 }}>
                      <strong>PIX: </strong>{' '}
                      {driver.bankAccount.pixNumber ?? 'Sem pix'}
                    </Typography>
                    <Typography sx={{ py: 1 }}>
                      <strong>CPF: </strong> {driver.bankAccount.cpf}
                    </Typography>
                    <Typography sx={{ py: 1 }}>
                      <strong>Nome: </strong> {driver.bankAccount.fullName}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </>
        ) : (
          <></>
        )}
        {activeStep == 'Fotos' ? (
          <>
            <Paper
              sx={{ backgroundColor: '#EDEDED', borderRadius: 0 }}
              elevation={0}
            >
              <Grid container xs={12}>
                <Grid xs={2} item />
                <Grid item xs={8}>
                  <ImageList
                    sx={{
                      width: '100%',
                      height: 450,
                      ml: 2,
                      '&::-webkit-scrollbar': {
                        backgroundColor: '#COC1C3',
                      },
                    }}
                    cols={3}
                  >
                    {driver.pictures.map((name) => (
                      <ImageItem
                        key={name}
                        name={name}
                        onClick={() => handleModal(name)}
                      />
                    ))}
                  </ImageList>
                </Grid>
              </Grid>
            </Paper>
          </>
        ) : (
          <></>
        )}

        {activeStep == 'Referências' ? (
          <>
            <Paper
              sx={{
                backgroundColor: '#EDEDED',
                borderRadius: 0,
                minHeight: '72.5vh',
                display: 'flex',
              }}
              elevation={0}
            >
              <Grid
                container
                xs={12}
                sx={{
                  height: '100%',
                  display: 'flex',
                  minHeight: '72.5vh',
                  alignItems: 'stretch',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid xs={2} />
                <Grid item xs={8} sx={{}}>
                  <Paper
                    sx={{
                      p: 2.5,
                      mb: '5rem',
                      mt: '5rem',
                      width: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'auto',
                    }}
                  >
                    {driver.references.personalContacts.map((contact) => (
                      <Typography sx={{ py: 1 }} key={contact.phoneNumber}>
                        <strong>Contato Pessoal: </strong> {contact.name}
                        {contact.phoneNumber ? ` - ${contact.phoneNumber}` : ''}
                      </Typography>
                    ))}

                    {driver.references.companyContacts.map((contact) => (
                      <Typography sx={{ py: 1 }} key={contact.phoneNumber}>
                        <strong>Contato Empresarial: </strong> {contact.name}
                        {contact.phoneNumber ? ` - ${contact.phoneNumber}` : ''}
                      </Typography>
                    ))}

                    <Typography sx={{ py: 1 }}>
                      <strong>Observação: </strong> {driver.observation}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </>
        ) : (
          <></>
        )}
      </Container>
      <ImageComponent
        workspaceId={workspaceId}
        open={openModal}
        name={imageName}
        handleClose={handleCloseModal}
      />
    </>
  );
}
