import api from './api';
async function CreateRoom({ name }) {
  const res = await api.post('/rooms', {
    name,
  });
  return res.data;
}

async function UpdateRoom({ name, roomId }) {
  const res = await api.put(`/rooms/${roomId}/info`, {
    name,
  });
  return res.data;
}
async function DeleteRoom({ roomId }) {
  const res = await api.delete(`/rooms/${roomId}`);
  return res.data;
}

const roomApi = { CreateRoom: CreateRoom, UpdateRoom, DeleteRoom };

export default roomApi;
