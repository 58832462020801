import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ObjectArrayInput from './ObjectArrayInput';
import { PhoneNumberInput } from '../MaskedInputs';
import TextFieldInput from '../Form/TextFieldInput';

import { useController } from 'react-hook-form';

export default function ContactsFieldInput({ name, label, prefix, min, max }) {
  const { fieldState } = useController({ name });

  return (
    <>
      <Divider color="red">
        <Typography fontSize={17} color={fieldState.error ? 'error' : ''}>
          {label}
        </Typography>
      </Divider>

      {fieldState.error && (
        <Typography fontSize={14} color="error">
          {fieldState.error.message}
        </Typography>
      )}

      <ObjectArrayInput
        min={min}
        max={max}
        name={name}
        itemDefaultValue={{
          name: '',
          phoneNumber: '',
        }}
        formLabelPrefix={prefix}
        buttonLabelSufix={prefix}
        ItemForm={IndividualContact}
      />
    </>
  );
}

function IndividualContact({ name }) {
  return (
    <>
      <TextFieldInput name={`${name}.name`} label="Nome" />
      <PhoneNumberInput name={`${name}.phoneNumber`} label="N° de Telefone" />
    </>
  );
}
