import DeleteResourceDialog from '../WorkspaceResourceDialog/DeleteResourceDialog';

export default function DeleteOwnerDialog({
  resource: owner,
  onClose,
  onDelete,
}) {
  if (!owner) return <></>;
  return (
    <DeleteResourceDialog
      title={`Deletar prorpietário com número ${owner?.phoneNumber}?`}
      desc={
        'Esta ação é irreversível. Você tem certeza que desejar deletar este proprietário?'
      }
      onClose={onClose}
      open={Boolean(owner)}
      onDelete={() => onDelete(owner)}
    />
  );
}
