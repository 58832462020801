import Stack from '@mui/material/Stack';
import TextFieldInput from '../../components/Form/TextFieldInput';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import roomApi from '../../api/room.api';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
const creatorSchema = yup.object({
  name: yup
    .string()
    .label('Nome do time')
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
});

const theme = createTheme({
  palette: {
    secondary: { main: '#ffdf23', contrastText: '#000' },
  },
});
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function CreateRoom() {
  async function onSubmit({ name }) {
    try {
      await roomApi.CreateRoom({
        name,
      });

      setMessage('Criado !');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Tipo de dado errado !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.response.status === 422) {
        setMessage('Task ja existe !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    }
  }
  const [openSuccess, setOpenSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const creatorMethods = useYupForm(creatorSchema);
  return (
    <>
      <ThemeProvider theme={theme}>
        <FormProvider {...creatorMethods}>
          <Container
            maxWidth="xs"
            sx={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper sx={{ p: 5, boderRadius: 6 }}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography textAlign="center" fontSize={20} mb={1.5}>
                  Criar Time
                </Typography>
                <TextFieldInput
                  name="name"
                  label="Nome do time"
                  sx={{ py: 0.5 }}
                  fullWidth
                />
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  color="secondary"
                  onClick={creatorMethods.handleSubmit(onSubmit)}
                >
                  Criar
                </Button>
              </Stack>
            </Paper>
          </Container>
        </FormProvider>
      </ThemeProvider>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
