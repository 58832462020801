import api from './api';

async function modifyRecommendation({ recommendationId, approved }) {
  const res = await api.put(`/recommendations/${recommendationId}`, {
    approved,
  });

  return res.data;
}

async function updateRecommendation({ recommendationId, nearestBranch }) {
  await api.put(`/recommendations/${recommendationId}`, {
    nearestBranch,
    id: recommendationId,
  });
}

async function createBoardingRebound({
  recommendationId,
  reembarked,
  driverFeedback,
}) {
  const res = await api.post(
    `/boarding-rebounds`,
    driverFeedback.length === 0
      ? {
          recommendationId,
          reembarked,
        }
      : { recommendationId, reembarked, driverFeedback }
  );
  return res.data;
}

async function getBoardingCount({ data }) {
  const boardingCount = await api.get(`boarding-rebounds/usersRebounds`, {
    date: data,
  });

  return boardingCount.data;
}
const recommendationApi = {
  getBoardingCount,
  modifyRecommendation,
  createBoardingRebound,
  updateRecommendation,
};
export default recommendationApi;
