import './yupExtensions';
import PageRoutes from './pages/PageRoutes';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import PermissionsProvider from './contexts/PermissionsContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import SnackbarProvider from './contexts/SnackbarContext';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

function App() {
  return (
    <AuthProvider>
      <PermissionsProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBRLocale}
          >
            <SnackbarProvider>
              <NotistackSnackbarProvider
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                autoHideDuration={2500}
                style={{
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                }}
              >
                <BrowserRouter>
                  <PageRoutes />
                </BrowserRouter>
              </NotistackSnackbarProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </PermissionsProvider>
    </AuthProvider>
  );
}

export default App;
