import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import banner02 from '../banner02.svg';
import { StyledEndTableCell, StyledTableCell } from '../components/StyledTable';
export default function ShipperReport({ report }) {
  const sorted = report.sort(
    (a, b) =>
      parseFloat(b.contatos.percEmbarques_C) -
      parseFloat(a.contatos.percEmbarques_C)
  );
  return (
    <>
      <Box
        sx={{
          height: 'vh',
          ml: 1,
          width: '100%',
          display: 'flex',
          mt: 1,
        }}
      >
        <img src={banner02} width="100%" height="100%" alt="sala" />
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          display: 'flex',
          width: '100%',
          ml: 1,
          overflow: 'scroll',
          borderRadius: '5px 0px 5px 5px',
          '::-webkit-scrollbar': { display: 'none' },
          '::-webkit-scrollbar-corner': { display: 'none' },
          '::-webkit-scrollbar-thumb': {
            display: 'none',
          },
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ minWidth: '1vw', maxWidth: '3vw' }}>
                <Typography fontSize={15}>Embarcador</Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ minWidth: '1vw', maxWidth: '4vw' }}>
                <Typography visibility="hidden">recomendacoes</Typography>
              </StyledTableCell>
              <StyledTableCell
                sx={{ minWidth: '1vw', maxWidth: '2vw' }}
                align={'center'}
              >
                <Typography fontSize={15}>Contatos</Typography>
              </StyledTableCell>
              <Tooltip
                title={
                  <Typography fontSize={16}>
                    Percentual de contatos sobre as recomendações
                  </Typography>
                }
              >
                <StyledTableCell
                  sx={{ minWidth: '1vw', maxWidth: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={15}>% contatos</Typography>
                </StyledTableCell>
              </Tooltip>
              <StyledTableCell
                sx={{ minWidth: '1vw', maxWidth: '3vw' }}
                align={'center'}
              >
                <Typography fontSize={15}>Reembarques</Typography>
              </StyledTableCell>

              <Tooltip
                title={
                  <Typography fontSize={16}>
                    Percentual de reembarques sobre os contatos
                  </Typography>
                }
              >
                <StyledTableCell
                  sx={{ minWidth: '1vw', maxWidth: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={15}>% reembarques</Typography>
                </StyledTableCell>
              </Tooltip>
              <Tooltip
                title={
                  <Typography fontSize={16}>
                    Percentual de embarques sobre as recomendações
                  </Typography>
                }
              >
                <StyledEndTableCell
                  sx={{ minWidth: '1vw', maxWidth: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={15}>% reembarques / rec</Typography>
                </StyledEndTableCell>
              </Tooltip>
            </TableRow>
          </TableHead>

          <TableBody>
            {sorted?.map((shipper) => (
              <TableRow key={shipper.Embarcador}>
                <StyledTableCell
                  sx={{ minWidth: '2vw', maxWidth: '3vw', width: '1vw' }}
                >
                  <Typography fontSize={14}>{shipper.Embarcador}</Typography>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '3vw' }}>
                  <Typography></Typography>
                </StyledTableCell>

                <StyledTableCell sx={{ width: '2vw' }} align={'center'}>
                  <Typography fontSize={14}>
                    {shipper.contatos.qtdContatos}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '2vw' }} align={'center'}>
                  <Typography fontSize={14}>
                    {shipper.contatos.percContatos.toFixed(1)}%
                  </Typography>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '2vw' }} align={'center'}>
                  <Typography fontSize={14}>
                    {shipper.qtdReembarques}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell sx={{ width: '2vw' }} align={'center'}>
                  <Typography fontSize={14}>
                    {shipper.contatos.percEmbarques_C.toFixed(1)}%
                  </Typography>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '2vw' }} align={'center'}>
                  <Typography fontSize={14}>
                    {shipper.recomendacoes.percEmbarques_R.toFixed(1)}%
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
