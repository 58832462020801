import { Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioComponent(props) {
  return (
    <Controller
      name={props.name}
      render={({ field }) => (
        <FormControl sx={{ width: '100%' }}>
          <FormLabel>Período</FormLabel>
          <RadioGroup {...field} {...props} row>
            <FormControlLabel
              value={'WEEKLY'}
              control={<Radio />}
              label="Semanal"
            />

            <FormControlLabel
              value={'MONTHLY'}
              control={<Radio />}
              label="Mensal"
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
