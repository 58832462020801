import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import {
  CustomTable,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  TableText,
  TableTitle,
} from '../../../components/Table';
import TableDate from '../TableDate';
import CreateNoteDialog from './CreateNoteDialog';
import { useState } from 'react';
import { useTicketNotes } from '../../../hooks/useTicket';

export default function TicketNotes({ ticketId }) {
  const { notes, isLoading } = useTicketNotes(ticketId);

  const [createNoteOpen, setCreateNoteOpen] = useState(false);

  if (isLoading) return <></>;
  return (
    <>
      <CustomTable>
        <CustomTableHead>
          <CustomTableCell align="center">
            <TableTitle>Horário</TableTitle>
          </CustomTableCell>

          <CustomTableCell align="left">
            <TableTitle>Usuário</TableTitle>
          </CustomTableCell>

          <CustomTableCell align="left">
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <TableTitle>Anotação</TableTitle>

              <Button onClick={() => setCreateNoteOpen(true)}>
                <AddIcon sx={{ color: '#fff' }} />
              </Button>
            </Box>
          </CustomTableCell>
        </CustomTableHead>

        <TableBody>
          {notes.map((note) => (
            <CustomTableRow key={note.id}>
              <CustomTableCell align="center">
                <TableText>
                  <TableDate dateString={note.createdAt} />
                </TableText>
              </CustomTableCell>

              <CustomTableCell align="left">
                <TableText>{note.createdBy.username}</TableText>
              </CustomTableCell>

              <CustomTableCell align="left">
                <TableText>{note.content}</TableText>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </CustomTable>

      <CreateNoteDialog
        ticketId={ticketId}
        open={createNoteOpen}
        handleClose={() => setCreateNoteOpen(false)}
      />
    </>
  );
}
