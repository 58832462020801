import { TextFieldInput } from '../../components/Form';
import TextField from '@mui/material/TextField';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import ThreeStateCheckbox from '../../components/Form/ThreeStateCheckbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CompanyReferencesComponentInput({
  name,
  sx,
  isDisabled,
}) {
  return (
    <>
      <TextFieldInput
        size="small"
        disabled={isDisabled ?? false}
        {...sx}
        label="Nome"
        name={`${name}.name`}
      />
      <FormControlLabel
        label="Placa"
        control={
          <ThreeStateCheckbox
            name={`${name}.licensePlate`}
            isDisabled={isDisabled}
          />
        }
      />
      <TextFieldInput
        size="small"
        {...sx}
        label="Viagens"
        type="number"
        disabled={isDisabled ?? false}
        name={`${name}.travelQuantity`}
      />
      <TextFieldInput
        size="small"
        {...sx}
        label="Produto"
        disabled={isDisabled ?? false}
        name={`${name}.product`}
      />
      <DatePickerInput
        sx={{ width: '100%' }}
        name={`${name}.date`}
        label={'Data'}
        isDisabled={isDisabled ?? false}
        renderInput={(params) => <TextField size="small" {...params} />}
      />
    </>
  );
}
