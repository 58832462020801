import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import WorkspaceImage from './WorkspaceImage';
import { useModalImage } from '../../contexts/ModalImageContext';

export default function HorizontalImageList({ images, title }) {
  const selectImage = useModalImage();

  return (
    <ImageList rowHeight={250} cols={images.length + 5}>
      <Box sx={{ width: 200, height: 250, display: 'inline-block' }}>
        <Divider sx={{ width: '100%' }} orientation="vertical">
          <Typography>{title}</Typography>
        </Divider>
      </Box>

      {images.map((name) => (
        <WorkspaceImage
          onClick={() => selectImage(name)}
          key={name}
          width="250"
          height="250"
          style={{ 'object-fit': 'cover' }}
          name={name}
        />
      ))}
    </ImageList>
  );
}
