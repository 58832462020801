import useAuthSWR from './useAuthSWR';

export function useTicket(ticketId) {
  const ticketSWR = useAuthSWR(`/tickets/${ticketId}`, {}, {}, !ticketId);

  return {
    ticket: ticketSWR.data,
    isLoading: !ticketSWR.error && !ticketSWR.data,
    error: ticketSWR.error,
  };
}

export function useTicketNotes(ticketId) {
  const ticketSWR = useAuthSWR(`/tickets/${ticketId}/notes`, {}, {}, !ticketId);

  return {
    notes: ticketSWR.data,
    isLoading: !ticketSWR.error && !ticketSWR.data,
    error: ticketSWR.error,
  };
}

export function useTicketHistory(ticketId) {
  const ticketSWR = useAuthSWR(
    `/tickets/${ticketId}/history`,
    {},
    {},
    !ticketId
  );

  return {
    history: ticketSWR.data,
    isLoading: !ticketSWR.error && !ticketSWR.data,
    error: ticketSWR.error,
  };
}
