import useAuthSWR from '../../hooks/useAuthSWR';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useYupForm from '../../hooks/useYupForm';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import { Autocomplete, Grid } from '@mui/material';
import DropdownFieldInput from '../../components/Form/DropdownFieldInput';
import Paper from '@mui/material/Paper';
import SummarizeIcon from '@mui/icons-material/Summarize';
import reportsApi from '../../api/reports.api';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { ResponsiveBump } from '@nivo/bump';
import { useState } from 'react';
import { useMemo } from 'react';
import generateReport from './generate-report';
import OccupationChart from './barChart';
const creatorSchema = yup.object({
  scale: yup.object(),
  day: yup.date(),
  taskId: yup.object(),
});
export default function ViewReports() {
  const { watch, ...creatorMethods } = useYupForm(creatorSchema);

  const { data: sequence } = useAuthSWR('/tasks');
  const { data: manyUsers } = useAuthSWR('/users');

  const [scale, setScale] = useState();
  const [report, setReport] = useState([]);
  const [minimum, setMinimum] = useState();
  const [maximum, setMaximum] = useState();

  let day = watch('day');

  const dateConversor = (day, hour) => {
    if (!day || !hour) return new Date();
    const literalNewDate = new Date(day);
    literalNewDate.setHours(hour.getHours());
    literalNewDate.setMinutes(hour.getMinutes());
    literalNewDate.setSeconds(0);
    literalNewDate.setMilliseconds(0);
    return literalNewDate;
  };
  const max = useMemo(() => {
    return dateConversor(day, maximum);
  }, [maximum, day]);
  const min = useMemo(() => {
    return dateConversor(day, minimum);
  }, [minimum, day]);
  const [bumpData, barSizes, users] = useMemo(() => {
    return generateReport({
      scale,
      max,
      min,
      timeline: report.timeline,
      productivity: report.productivity,
      manyUsers,
      day,
    });
  }, [scale, max, min, report.timeline, manyUsers, report.productivity, day]);

  if (!sequence) return <>Loading...</>;
  async function fetchTimeline(data) {
    const { day, taskId } = data;

    try {
      const data = await reportsApi.getAllReports({
        day,
        taskId: taskId?.id,
      });

      setReport(data);
    } catch (error) {
      return;
    }
  }
  const handleStart = (time) => {
    setMinimum(time);
  };
  const handleEnd = (time) => {
    setMaximum(time);
  };

  return (
    <>
      <Paper sx={{ mr: 'auto', ml: 'auto', width: '95%', mb: 5 }}>
        <Grid
          container
          xs={12}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            p: '15px',
            justifyContent: 'space-around',
          }}
        >
          <FormProvider {...creatorMethods}>
            <Stack spacing={3} direction="row">
              <DatePickerInput
                label="Dia"
                fullWidth
                name="day"
                views={['day', 'month']}
                renderInput={(params) => <TextField {...params} />}
              />

              <DropdownFieldInput
                name="taskId"
                options={sequence ?? []}
                label="Coluna"
                getOptionLabel={(option) => option.title}
              />

              <Button
                variant="contained"
                color="secondary"
                fullWidth
                startIcon={<SummarizeIcon />}
                onClick={creatorMethods.handleSubmit(fetchTimeline)}
              >
                Gerar
              </Button>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={3} direction="row">
              <TimePicker
                label="Início"
                value={minimum}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
                onChange={handleStart}
              />

              <TimePicker
                label="Fim"
                fullWidth
                value={maximum}
                renderInput={(params) => <TextField {...params} />}
                onChange={handleEnd}
              />

              <Autocomplete
                name="scale"
                fullWidth
                options={[
                  { value: 5, label: '5 minutos' },
                  { value: 10, label: '10 minutos' },
                  { value: 15, label: '15 minutos' },
                  { value: 20, label: '20 minutos' },
                  { value: 30, label: '30 minutos' },
                  { value: 60, label: '1 Hora' },
                  { value: 120, label: '2 Horas' },
                ]}
                width="75%"
                renderInput={(params) => (
                  <TextField {...params} label="Escala" />
                )}
                onChange={(_event, option) => {
                  setScale(option.value);
                }}
              />
            </Stack>
          </FormProvider>
        </Grid>
      </Paper>
      <Paper
        sx={{
          mr: 'auto',
          ml: 'auto',
          width: '95%',
          height: '600px',
          display: 'flex',
        }}
      >
        <ResponsiveBump
          data={bumpData ?? []}
          interpolation={null}
          colors={{ scheme: 'spectral' }}
          lineWidth={3}
          activeLineWidth={6}
          inactiveLineWidth={3}
          inactiveOpacity={0.15}
          pointSize={0}
          activePointSize={0}
          inactivePointSize={0}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={3}
          isInteractive={true}
          animate={true}
          activePointBorderWidth={3}
          pointBorderColor={{ from: 'serie.color' }}
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Timeline',
            legendPosition: 'middle',
            legendOffset: -30,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
          axisRight={null}
        />
      </Paper>
      <Paper
        sx={{
          mr: 'auto',
          ml: 'auto',
          width: '95%',
          height: '600px',
          mt: 5,
          mb: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <OccupationChart
          barSizes={barSizes}
          users={users}
          scale={scale}
          min={min}
        />
      </Paper>
    </>
  );
}
