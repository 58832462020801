import NavBar from '../components/NavBar';
import Toolbar from '@mui/material/Toolbar';
import { Outlet } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import BaseLayout from './BaseLayout';
import { Height } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';

function DashboardLayout() {
  return (
    <BaseLayout
      sx={{
        backgroundImage: 'url("background/background-dashboard.svg")',
        backgroundSize: '50vh',
      }}
    >
      <NavBar />
      <Stack sx={{ maxHeight: '100%' }}>
        <Toolbar sx={{ mb: 4 }} />
        <Outlet />
      </Stack>
    </BaseLayout>
  );
}

export default DashboardLayout;
