import React, { useEffect } from 'react';
import useAuthSWR from '../../hooks/useAuthSWR';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
export default function Forms({ query, page, setIsFinished, limitP }) {
  const minimumProgress = query?.minimumProgress;

  const limit = query?.limit ? query.limit : 10;
  const { data: forms } = useAuthSWR(
    'registration-form',
    { page } /* {
    limit,
    minimumProgress,
    page,
  } */
  );
  useEffect(
    () => setIsFinished(forms?.length < limitP),
    [forms, setIsFinished, limitP]
  );
  const navigate = useNavigate();
  /* if (!query) return <></>; */
  if (!forms) return <></>;

  return (
    <List component={Paper}>
      {forms.map((form) => (
        <>
          <ListItem key={form.id} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/record/${form.id}`);
              }}
            >
              <ListItemText
                primary={form.id}
                secondary={form.createdBy.username}
              />
              <Typography>{form.progress.toFixed(2)}</Typography>
            </ListItemButton>
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
}
