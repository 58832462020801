import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import SheetsClipboardButton from './SheetsClipboardButton';
import LinkClipboardButton from './LinkClipboardButton';

export default function TicketInfoTitle({ ticket }) {
  const navigate = useNavigate();

  const title = ticket.task?.title ?? '.';

  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.common.black,
        position: 'relative',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: 15,
          transform: 'translate(0, -50%)',
        }}
        onClick={() => navigate('/dashboard')}
      >
        <ArrowBackIcon
          sx={{
            color: (theme) => theme.palette.common.white,
          }}
        />
      </IconButton>

      <Stack
        direction="row"
        spacing={1}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 15,
          transform: 'translate(0, -50%)',
          ticket,
        }}
      >
        {process.env.REACT_APP_ENABLE_COPY_LINK === 'true' ? (
          <LinkClipboardButton ticket={ticket} />
        ) : (
          <></>
        )}
        <SheetsClipboardButton ticket={ticket} />
      </Stack>

      <Typography
        visibility={ticket.task === null ? 'hidden' : 'initial'}
        color="white"
        textAlign="center"
        fontSize={20}
        py={1.5}
      >
        {title}
      </Typography>
    </Paper>
  );
}
