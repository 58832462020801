import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import DeleteResourceDialog from '../WorkspaceResourceDialog/DeleteResourceDialog';

import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import { useRef } from 'react';
import { useBoolean } from 'react-hanger';

export default function ImageMoreButton({ imageUrl, onDelete, name }) {
  const buttonRef = useRef();
  const menuOpen = useBoolean();
  const deleteOpen = useBoolean();

  return (
    <>
      <IconButton
        ref={buttonRef}
        onClick={menuOpen.setTrue}
        sx={{ color: '#fff' }}
      >
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={buttonRef.current}
        open={menuOpen.value}
        onClose={menuOpen.setFalse}
      >
        <MenuItem
          onClick={() => {
            menuOpen.setFalse();

            const a = document.createElement('a');
            a.download = true;
            a.target = '_blank';
            a.href = imageUrl;
            a.click();
          }}
        >
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Baixar</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            deleteOpen.setTrue();
            menuOpen.setFalse();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remover</ListItemText>
        </MenuItem>
      </Menu>

      <DeleteResourceDialog
        title={`Deletar imagem com nome ${name}?`}
        open={deleteOpen.value}
        onClose={deleteOpen.setFalse}
        onDelete={() => onDelete(name)}
        desc="Esta ação é irreversível. Você tem certeza que desejar deletar esta imagem?"
      />
    </>
  );
}
