import DropdownFieldInput from '../Form/DropdownFieldInput';
import TextFieldInput from '../Form/TextFieldInput';
import Stack from '@mui/material/Stack';
import { RadioComponent } from '../Form';

export default function CreateClientForm({ users }) {

  return (
    <Stack spacing={2}>
      <TextFieldInput
        sx={{ width: '100%' }}
        name="name"
        label="Cliente"
        defaultValue=""
      />
      <TextFieldInput
        sx={{ width: '100%' }}
        name="phonenumber"
        label="Contato"
        defaultValue=""
      />

      <DropdownFieldInput
        fullWidth
        name="user"
        options={users ?? []}
        label="Usuário"
        defaultValue=""
        getOptionLabel={(option) => option.name}
      />
      <RadioComponent name="period" defaultValue="" />
    </Stack>
  );
}
