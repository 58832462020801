import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { capitilizeInitialLetters } from './capitilizeInitialLetters';
import { useSnackbar } from 'notistack';

export default function SheetsClipboardButton({ ticket }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        const now = new Date();

        const roomName = roomMap.get(ticket.createdBy.room?.id) ?? '';
        const month = monthMap.get(now.getMonth());
        const date = now.toLocaleDateString('pt-BR');
        const user = ticket.createdBy.username;
        const client = capitilizeInitialLetters(ticket.clientName);
        const originCity = ticket.originCity;
        const destinyCity = ticket.destinyCity;
        const expo = exportingCities.has(ticket.destinyCity) ? 'S' : 'N';
        const originState = ticket.originState;
        const destinyState = ticket.destinyState;
        const driverName = capitilizeInitialLetters(
          ticket.driverName.split(' ')[0]
        );
        const licensePlate = ticket.licensePlate;

        navigator.clipboard.writeText(
          `Mervil\t${roomName}\t${month}\t${date}\t${user}\t${client}\t${originCity}/${destinyCity}\t${expo}\t${originState}\t${destinyState}\t${driverName}\t${licensePlate}`
        );
        enqueueSnackbar('Ticket copiado para a área de transferência.');
      }}
    >
      <ContentCopyIcon
        sx={{
          color: (theme) => theme.palette.common.white,
        }}
      />
    </IconButton>
  );
}

const monthMap = new Map([
  [0, 'JAN'],
  [1, 'FEV'],
  [2, 'MAR'],
  [3, 'ABR'],
  [4, 'MAI'],
  [5, 'JUN'],
  [6, 'JUL'],
  [7, 'AGO'],
  [8, 'SET'],
  [9, 'OUT'],
  [10, 'NOV'],
  [11, 'DEZ'],
]);

const roomMap = new Map([
  ['f34982df-6ab8-4f0c-81d1-1bd7c4a9acb7', "'018"],
  ['371f3c25-81d1-4200-8964-fd23ddb64700', 'Frota'],
  ['65530da6-81ac-4b90-8f57-6bfafffc5945', "'015"],
  ['0ed1712d-4ee4-4f48-86c1-d09c64496a00', 'SP'],
  ['20958f39-bd90-4e76-9c64-73495ec76aca', 'Pga'],
  ['91ce0fee-d525-4cbc-a9f7-e3856956abc8', 'Ind'],
  ['cb2105ba-ed21-4316-bebc-1ffdfc35493d', 'Fern'],
]);

const exportingCities = new Set(['Paranaguá', 'Santos']);
