import workspaceApi from '../../api/workspace.api';
import useWorkspaceResource from './useWorkspaceResource';

export default function useWorkspaceAntts() {
  const {
    resource,
    error,
    isLoading,
    createResource,
    deleteResource,
    updateResource,
    getResourceById,
  } = useWorkspaceResource({
    getResource: (workspace) => workspace.antts,
    api: workspaceApi.antts,
    getResourceId: (antt) => antt.id,
    setResource: (workspace, antts) => (workspace.antts = antts),
  });

  return {
    antts: resource,
    error,
    isLoading,
    updateAntt: updateResource,
    addAntt: createResource,
    deleteAntt: deleteResource,
    getAntt: getResourceById,
  };
}
