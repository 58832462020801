import api from './api';
async function putOperation({
  id,
  approved,
  reprovedReason,
  other,
  score,
  gr,
  isCnpj,
  driverInfo,
  documents,
  companyReferences,
  personalReference,
  lastManifest,
  extras,
  driverReference,
  responsibleReference,
  observations,
}) {
  if (id === undefined) return;

  const { type, experieceTime, ...restOfResponsibleReference } =
    responsibleReference;

  const res = await api.put(`/registration-form/${id}`, {
    approved,
    reprovedReason:
      approved === false
        ? reprovedReason === 'Outros'
          ? other
          : reprovedReason
        : null,
    score,
    gr,
    isCnpj,
    driverInfo,
    documents,
    companyReferences,
    personalReference,
    lastManifest,
    extras,
    driverReference,
    responsibleReference: {
      ...restOfResponsibleReference,
      experienceTime:
        Number(experieceTime.years) * 360 +
        Number(experieceTime.months) * 30 +
        Number(experieceTime.days),
      type: type?.value ?? null,
    },
    observations,
  });

  return res.data;
}

const formApi = { putOperation };

export default formApi;
