import React from 'react';
import { useEffect, useState } from 'react';
import OwnerList from './OwnerList';
import WorkspaceResourceDialog from '../WorkspaceResourceDialog';

import { useWorkspaceOwners } from '../../../hooks/useWorkspaces';
import { useSnackbar } from '../../../contexts/SnackbarContext';

function WorkspaceOwnerDialog({ value, onChange, onClose, open }) {
  const [selected, setSelected] = useState(value);
  useEffect(() => setSelected(value), [value]);

  const { deleteOwner } = useWorkspaceOwners();
  const handleRequest = useSnackbar();

  function onConfirm() {
    onChange(selected);
    onClose();
  }

  async function onDelete(owner) {
    async function main() {
      await deleteOwner(owner.id);

      if (value.id === owner.id) {
        setSelected(undefined);
        onChange(null);
      }
    }

    return handleRequest(main);
  }

  return (
    <WorkspaceResourceDialog
      title="Escolher proprietário"
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      disableConfirm={!selected}
    >
      <OwnerList
        selected={selected}
        onSelect={setSelected}
        onDelete={onDelete}
      />
    </WorkspaceResourceDialog>
  );
}

export default React.memo(WorkspaceOwnerDialog);
