import { useState } from 'react';
import useAuthSWR from '../../hooks/useAuthSWR';
import EditSectorForm from './EditSectorForm';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Slide from '@mui/material/Slide';
import { TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import sectorApi from '../../api/sector.api';

function SlideUpTransition(props) {
  return <Slide {...props} direction="up" />;
}
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function EditSector() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sectorNameState, setSectorNameState] = useState('');
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const { data: sectors } = useAuthSWR('/sectors');
  if (!sectors) return <></>;

  async function addSector() {
    try {
      await sectorApi.createSector({ name: sectorNameState });
      setMessage('Created !');
      setOpenSuccess(true);
      setIsDialogOpen(false);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
 }

  return (
    <>
      <Divider textAlign="right" sx={{ mb: '20px' }}>
        <Fab color="secondary" onClick={() => setIsDialogOpen(true)}>
          <AddIcon />
        </Fab>
      </Divider>
      <List sx={{ display: 'flex', justifyContent: 'center' }}>
        <Container>
          <Paper>
            {sectors.map((sector) => {
              return <EditSectorForm key={sector.id} sector={sector} />;
            })}
          </Paper>
        </Container>
      </List>
      <Dialog
        fullWidth
        open={isDialogOpen}
        TransitionComponent={SlideUpTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle>Criação de Setor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite o nome que será atribuído ao setor
          </DialogContentText>
          <TextField
            fullWidth={true}
            label="Setor"
            onChange={(event) => setSectorNameState(event.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              addSector();
              setIsDialogOpen(false);
            }}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
