const userOption = { label: 'Usuário', value: 'USER' };

const adminOption = { label: 'Administrador do Setor', value: 'ADMIN' };

const superAdminOption = { label: 'Administrador Geral', value: 'SUPER_ADMIN' };

export const ROLE_OPTIONS = [adminOption, superAdminOption, userOption];

export const ROLE_NAME_MAP = {
  USER: userOption,
  ADMIN: adminOption,
  SUPER_ADMIN: superAdminOption,
};
