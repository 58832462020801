import useAuthSWR from '../../hooks/useAuthSWR';
import ResponsabilityForm from './ResponsabilityForm';
import Container from '@mui/material/Container';
export default function AssignTaskReponsability() {
  const { data: sectors } = useAuthSWR('/sectors');
  const { data: tasks } = useAuthSWR('/tasks');

  if (!sectors) return <></>;
  if (!tasks) return <></>;
  return (
    <Container>
      {sectors.map((sector) => (
        <ResponsabilityForm sector={sector} tasks={tasks} key={sector.id} />
      ))}
    </Container>
  );
}
