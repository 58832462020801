import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageComponent({
  name,
  open,
  handleClose,
  workspaceId,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xl"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <InnerImageZoom
          zoomType="click"
          zoomScale="3"
          zoomPreload={true}
          zoomSrc={`${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${workspaceId}/images/${name}`}
          alt="a"
          src={`${process.env.REACT_APP_BACKEND_URL}/driver-workspaces/${workspaceId}/images/${name}`}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
