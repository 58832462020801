import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextFieldInput from '../../components/Form/TextFieldInput';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import { FormProvider } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import sectorApi from '../../api/sector.api';
import Paper from '@mui/material/Paper';

const sectorSchema = yup.object({
  name: yup
    .string()
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function CreateSector() {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);

  const sectorMethods = useYupForm(sectorSchema);

  async function onSubmit({ name, description }) {
    setIsLoading(true);
    try {
      await sectorApi.createSector({
        name,
        description,
        // shiftStart: convertedShifValueToData(shift[0]),
        // shiftEnd: convertedShifValueToData(shift[1]),
      });
      setMessage('Criado !');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Ocorreu um erro !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormProvider {...sectorMethods}>
      <Container
        maxWidth="xs"
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper sx={{ p: 10, borderRadius: 6 }}>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Criar Setor
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldInput
                    margin="normal"
                    required
                    fullWidth
                    label="Nome"
                    name="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldInput
                    margin="normal"
                    required
                    fullWidth
                    name="description"
                    label="Descrição"
                  />
                </Grid>
              </Grid>
              <LoadingButton
                loading={isLoading}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="secondary"
                onClick={sectorMethods.handleSubmit(onSubmit)}
              >
                Criar
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
