import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';

import '../../shared/yup-locale';

import { useFieldArray } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function ObjectArrayForm({
  name,
  formLabelPrefix,
  buttonLabelSufix,
  ItemForm,
  ItemFormProps,
  itemDefaultValue,
  min,
  max,
}) {
  const { fields, remove, append } = useFieldArray({
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <Stack key={field.id} spacing={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            columnSpacing={1}
          >
            <Grid item>
              <FormLabel>
                {formLabelPrefix} {index + 1}
              </FormLabel>
            </Grid>

            <Grid item xs>
              <Divider />
            </Grid>

            <Grid item>
              {index >= min && (
                <IconButton
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          
          <ItemForm {...(ItemFormProps ?? {})} name={`${name}.${index}`} />
        </Stack>
      ))}

      {fields.length < max && (
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => append(itemDefaultValue)}
        >
          Adicionar {buttonLabelSufix}
        </Button>
      )}
    </>
  );
}
