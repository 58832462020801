import MaskedTextField from './MaskedTextField';
import { useController } from 'react-hook-form';

export default function TextFieldInput(props) {
  const { field, fieldState, formState } = useController({ name: props.name });

  return (
    <MaskedTextField
      {...field}
      {...props}
      onBlur={(e) => {
        field.onBlur(e);
        props.onBlur && props.onBlur(e);
      }}
      InputLabelProps={{
        shrink: field.value !== '' && field.value !== undefined,
      }}
      error={Boolean(fieldState.error)}
      helperText={fieldState.error?.message}
      onChange={(e)=>{
        field.onChange(e);
      }}
    />
  );
}
