import Stack from '@mui/material/Stack';

import { FormProvider } from 'react-hook-form';

import useYupForm from '../../../hooks/useYupForm';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useBoolean } from 'react-hanger';
import OwnerUpsertFormInput from './OwnerUpserFormInputs';

export const upsertOwnerSchema = yup.object({
  phoneNumber: yup.string().required().min(14).label('Telefone'),
  cnh: yup
    .array()
    .of(yup.string())
    .when('legalPerson', { is: false, then: yup.array().min(1).required() })
    .label('CNH'),
  addressProof: yup
    .array()
    .of(yup.string())
    .when('legalPerson', { is: false, then: yup.array().min(1).required() })
    .typeError('Campo obrigatório')
    .label('Comprovante de residência'),
  legalPerson: yup.boolean().required(),
  cnhValidationDate: yup
    .date()
    .when('legalPerson', {
      is: true,
      then: yup.date().nullable(),
      otherwise: yup.date().required(),
    })
    .typeError('Campo obrigatório')
    .min(new Date(), 'CNH expirada'), //validação par a CNH
  addressProofDate: yup
    .date()
    .when('legalPerson', {
      is: false,
      then: yup.date().required(),
    })
    .typeError('Campo obrigatório')
    .test(
      'is-invalid',
      'Comprovante inválido',
      (addressProofDate, context) => {
        if (context.parent.legalPerson) return true; //Se for PJ, as validações serão ignoradas
      
        if (!addressProofDate) return false;
      
        const currentDate = new Date(); 
        currentDate.setHours(0, 0, 0, 0);
      
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1); //puxando a data de um ano atrás
      
        if (addressProofDate <= currentDate && addressProofDate >= oneYearAgo) return true; //validação para o Comp. de residência
    }
  ),
});

export default function OwnerUpsertForm({
  defaultValue,
  onSubmit,
  buttonTitle,
}) {
  const { handleSubmit, ...formMethods } = useYupForm(
    upsertOwnerSchema,
    defaultValue
  );

  const isLoading = useBoolean();

  async function submitForm(values) {
    isLoading.setTrue();

    try {
      await onSubmit(values, formMethods.reset);
    } finally {
      isLoading.setFalse();
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Stack px={4} direction="row" spacing={2} my={2}>
        <OwnerUpsertFormInput />
        <LoadingButton
          fullWidth
          loading={isLoading.value}
          onClick={handleSubmit(submitForm)}
          variant="contained"
          sx={{
            maxHeight: 36,
          }}
          px={5}
        >
          {buttonTitle}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
