import { TextFieldInput } from '../../components/Form';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
export default function RegistrationFormComponentInput({
  name,
  sx,
  label,
  isDisabled,
}) {
  return (
    <Stack spacing={2} sx={{ mt: '15px' }}>
      <TextFieldInput
        size="small"
        label={`${label.primary} - Nome`}
        {...sx}
        disabled={isDisabled ?? false}
        name={`${name}.nameOfPrimary`}
      />

      <TextFieldInput
        size="small"
        label={`${label.primary} - Cidade`}
        disabled={isDisabled ?? false}
        {...sx}
        name={`${name}.cityOfPrimary`}
      />

      <TextFieldInput
        size="small"
        disabled={isDisabled ?? false}
        label={`${label.secondary} - Nome`}
        {...sx}
        name={`${name}.nameOfSecondary`}
      />

      <TextFieldInput
        disabled={isDisabled ?? false}
        size="small"
        label={`${label.secondary} - Cidade`}
        {...sx}
        name={`${name}.cityOfSecondary`}
      />
    </Stack>
  );
}
