import { Controller } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers';
export default function DatePickerInput(props) {
  return (
    <Controller
      name={props.name}
      render={({ field }) => (
        <DesktopDatePicker
          disabled={props.isDisabled ?? false}
          {...props}
          {...field}
        />
      )}
    />
  );
}
