import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function PrivateRoute(WrappedComponent) {
  return function SecuredComponent(props) {
    const { isLoading, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    if (isLoading) return <>Loading</>;
    if (!isAuthenticated) {
      navigate('/login');
      return <></>;
    }

    return <WrappedComponent {...props} />;
  };
}

export default PrivateRoute;
