import React from 'react';
import Collapse from '@mui/material/Collapse';
import { useWorkspaceOwners } from '../../../../hooks/useWorkspaces';
import { useSnackbar } from '../../../../contexts/SnackbarContext';

import OwnerUpsertForm from '../OwnerUpsertForm';

function OwnerEditCollapse({ resource: owner, open }) {
  const { updateOwner } = useWorkspaceOwners();
  const handleRequest = useSnackbar();

  async function submit({ phoneNumber, cnh, addressProof, legalPerson, cnhValidationDate, addressProofDate }) {
    //console.log(`recebi-colapse ${phoneNumber, cnh, addressProof, legalPerson, cnhValidationDate, addressProofDate}`)
    
    await handleRequest(
      async () =>
        await updateOwner(owner.id, { 
          phoneNumber,
          ...(legalPerson ? {} : { cnh, addressProof, cnhValidationDate, addressProofDate }),
        })
    );
  }

  return (
    <Collapse in={open}>
      <OwnerUpsertForm
        onSubmit={submit}
        defaultValue={{
          ...owner,
          legalPerson:
            owner.cnh.length === 0 && owner.addressProof.length === 0,
        }}
        buttonTitle="SALVAR"
      />
    </Collapse>
  );
}

export default React.memo(OwnerEditCollapse);
