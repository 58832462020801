import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
export default function FeedbackField({ feedback, liscensePlate }) {
  return (
    <Paper sx={{ display: 'flex', borderRadius: 3, m: 1 }} disablePadding>
      <Grid
        container
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            ml: 1,
          }}
        >
          <Typography variant="h6" color="#d4a10b">
            {liscensePlate}
          </Typography>
        </Box>

        <Grid item xs={11} sx={{ m: 1 }}>
          <Typography sx={{ fontSize: '16px' }}>{feedback}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
