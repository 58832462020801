import TextFieldInput from '../Form/TextFieldInput';

function PhoneNumberInput(props) {
  function createMask(value) {
    return value && value.length == 15 ? '(99) 99999-9999' : '(99) 9999-99999';
  }

  return (
    <TextFieldInput label="N° de Telefone" getMask={createMask} {...props} />
  );
}

export default PhoneNumberInput;
