import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function ClientHistoryTable({ history }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Observação</TableCell>
          <TableCell align="center">Liberou</TableCell>
          <TableCell align="center">Data</TableCell>
          <TableCell align="center">Usuário</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {history.map((interaction, index) => {
          const registeredAt = new Date(interaction.registeredAt);

          const day = registeredAt.getDate();
          const month = String(registeredAt.getMonth() + 1).padStart(2, '0');
          const year = registeredAt.getFullYear();

          return (
            <TableRow key={index}>
              <TableCell scope="row">{interaction.observation}</TableCell>
              <TableCell align="center">
                {interaction.released ? (
                  <DoneIcon color="green" />
                ) : (
                  <CloseIcon color="red" />
                )}
              </TableCell>
              <TableCell scope="row" align="center">
                {`${day}/${month}/${year}`}
              </TableCell>
              <TableCell align="left">{interaction.registeredBy}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
