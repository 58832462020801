import { createContext, useContext } from 'react';
import useAuthSWR from '../hooks/useAuthSWR';
const PermissionsContext = createContext(() => false);

export default function PermissionsProvider({ children }) {
  const { data } = useAuthSWR('/users/me/task-permissions');
  const userPermissions = data ?? [];

  function hasTaskPermission({ taskId, action }) {
    const perm = userPermissions.find((perm) => {
      return perm.action == action && perm.taskId == taskId;
    });

    return perm != undefined;
  }

  return (
    <PermissionsContext.Provider value={hasTaskPermission}>
      {children}
    </PermissionsContext.Provider>
  );
}

export function usePermissions() {
  return useContext(PermissionsContext);
}
