import TextFieldInput from '../../Form/TextFieldInput';
import WorkspaceFormButtons from '../WorkspaceFormButtons';
import '../../../shared/yup-locale';

import * as yup from 'yup';
import FileFieldInput, { imageSchema } from '../FileFieldInput';

export const lastManifestSchema = imageSchema.required();
export const picturesSchema = imageSchema.required();
export const observationSchema = yup.string();

export default function ConclusionForm() {
  return (
    <>
      <FileFieldInput name="lastManifest" label="Último Manifesto" />

      <FileFieldInput name="pictures" label="Fotos do Motorista" />

      <TextFieldInput multiline name="observation" label="Observação" />

      <WorkspaceFormButtons />
    </>
  );
}
