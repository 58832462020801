import NavBar from '../components/NavBar';
import Toolbar from '@mui/material/Toolbar';
import { Outlet } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import BaseLayout from './BaseLayout';

function SinglePage() {
  return (
    <BaseLayout>
      <NavBar />
      <Stack sx={{ minHeight: '100vh', height: '1px' }}>
        <Toolbar />
        <Outlet />
      </Stack>
    </BaseLayout>
  );
}

export default SinglePage;
