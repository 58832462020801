import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import banner01 from '../banner01.svg';
import {
  StyledEndTableCell,
  StyledTableCell,
  StyledTableRow,
} from '../components/StyledTable';

export default function BoardingReboundReport({ report }) {
  const reportWithTotal = useMemo(() => {
    if (!report) return undefined;

    const total = {
      sala: 'Mervil',
      qtdReembarques: 0,
      recomendacoes: {
        qtdRecomendacoes: 0,
        percReembarques_R: 0,
      },
      contatos: {
        qtdContatos: 0,
        percContatos: 0,
        percReembarques_C: 0,
      },
    };

    const filteredreport = report.filter(
      (room) =>
        room.sala !== 'Central' &&
        room.sala !== 'Frota' &&
        room.sala !== 'Industrializados'
    );

    for (const room of filteredreport) {
      total.qtdReembarques += room.qtdReembarques;
      total.recomendacoes.qtdRecomendacoes +=
        room.recomendacoes.qtdRecomendacoes;
      total.contatos.qtdContatos += room.contatos.qtdContatos;
    }
    total.contatos.percContatos =
      (total.contatos.qtdContatos / total.recomendacoes.qtdRecomendacoes) * 100;
    total.contatos.percReembarques_C =
      (total.qtdReembarques / total.contatos.qtdContatos) * 100;
    total.recomendacoes.percReembarques_R =
      (total.qtdReembarques / total.recomendacoes.qtdRecomendacoes) * 100;

    return [...filteredreport, total];
  }, [report]);

  if (!reportWithTotal)
    return (
      <Paper sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Typography>Sem dados</Typography>
      </Paper>
    );
  const sorted = reportWithTotal.sort(
    (a, b) =>
      parseFloat(b.contatos.percReembarques_C) -
      parseFloat(a.contatos.percReembarques_C)
  );
  return (
    <>
      <Box
        sx={{
          height: 'vh',
          ml: 1,
          width: '100%',
          display: 'flex',
        }}
      >
        <img src={banner01} width="100%" height="100%" alt="sala" />
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          display: 'flex',
          width: '100%',
          ml: 1,
          borderRadius: '5px 0px 5px 5px',
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <StyledTableCell sx={{ minWidth: '1vw', maxWidth: '3vw' }}>
              <Typography fontSize={15}>Filial</Typography>
            </StyledTableCell>

            <StyledTableCell
              align={'center'}
              sx={{ minWidth: '1vw', maxWidth: '4vw' }}
            >
              <Typography fontSize={15}>Recomendações</Typography>
            </StyledTableCell>
            <StyledTableCell
              align={'center'}
              sx={{ minWidth: '1vw', maxWidth: '2vw' }}
            >
              <Typography fontSize={15}>Contatos</Typography>
            </StyledTableCell>
            <Tooltip
              title={
                <Typography fontSize={16}>
                  Percentual de contatos sobre as recomendações
                </Typography>
              }
            >
              <StyledTableCell
                align={'center'}
                sx={{ minWidth: '1vw', maxWidth: '2vw' }}
              >
                <Typography fontSize={15}>% contatos</Typography>
              </StyledTableCell>
            </Tooltip>
            <StyledTableCell
              align={'center'}
              sx={{ minWidth: '1vw', maxWidth: '3vw' }}
            >
              <Typography fontSize={15}>Reembarques</Typography>
            </StyledTableCell>
            <Tooltip
              title={
                <Typography fontSize={16}>
                  Percentual de reembarques sobre os contatos
                </Typography>
              }
            >
              <StyledTableCell
                align={'center'}
                sx={{ minWidth: '1vw', maxWidth: '2vw' }}
              >
                <Typography fontSize={15}>% reembarques</Typography>
              </StyledTableCell>
            </Tooltip>
            <Tooltip
              title={
                <Typography fontSize={16}>
                  Percentual de reembarques sobre as recomendações
                </Typography>
              }
            >
              <StyledEndTableCell
                align={'center'}
                sx={{ minWidth: '1vw', maxWidth: '2vw' }}
              >
                <Typography fontSize={15}>% reembarques / rec</Typography>
              </StyledEndTableCell>
            </Tooltip>
          </TableHead>
          <TableBody>
            {sorted.map((room) => (
              <StyledTableRow key={room.sala}>
                <StyledTableCell
                  roomName={room.sala}
                  sx={{ minWidth: '2vw', maxWidth: '3vw', width: '1vw' }}
                >
                  <Typography fontSize={14}>{room.sala}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  roomName={room.sala}
                  align={'center'}
                  sx={{ width: '3vw' }}
                >
                  <Typography fontSize={14}>
                    {room.recomendacoes.qtdRecomendacoes}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  roomName={room.sala}
                  sx={{ width: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={14}>
                    {room.contatos.qtdContatos}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  roomName={room.sala}
                  sx={{ width: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={14}>
                    {room.contatos.percContatos.toFixed(1)}%
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  roomName={room.sala}
                  sx={{ width: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={14}>{room.qtdReembarques}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  roomName={room.sala}
                  sx={{ width: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={14}>
                    {room.contatos.percReembarques_C.toFixed(1)}%
                  </Typography>
                </StyledTableCell>
                <StyledEndTableCell
                  roomName={room.sala}
                  sx={{ width: '2vw' }}
                  align={'center'}
                >
                  <Typography fontSize={14}>
                    {room.recomendacoes.percReembarques_R.toFixed(1)}%
                  </Typography>
                </StyledEndTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
