import Modal from '@mui/material/Modal';
import { createContext, useContext, useState } from 'react';
import WorkspaceImage from '../components/EditWorkspaceForm/WorkspaceImage';

const ModalImageContext = createContext(() => {});

export default function ModalImageProvider({ children }) {
  const [image, setImage] = useState(undefined);
  const open = Boolean(image);

  return (
    <ModalImageContext.Provider value={setImage}>
      {children}
      <Modal open={open} onClose={() => setImage(undefined)}>
        <WorkspaceImage
          name={image}
          style={{
            position: 'absolute',
            inset: '0',
            maxWidth: '80vw',
            maxHeight: '90vh',
            margin: 'auto',
          }}
        />
      </Modal>
    </ModalImageContext.Provider>
  );
}

export function useModalImage() {
  return useContext(ModalImageContext);
}
