import api from './api';

async function analizeSolicitation({ solicitationId, approved }) {
  const res = await api.put(`/solicitations/${solicitationId}`, { approved });

  return res.data;
}

async function createSolicitation({ ticketId }) {
  const res = await api.post(`/solicitations`, { ticketId });

  return res.data;
}
const notificationApi = {
  analizeSolicitation,

  createSolicitation,
};

export default notificationApi;
