import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CondensedTicket from './CondensedTicket';
import TaskTitle from './TaskTitle';
import useTask from '../../../hooks/useTask';
import Skeleton from '@mui/material/Skeleton';
export default function TaskColumn({ taskId, query }) {
  const {
    task,
    tickets,
    isTaskLoading,
    isTicketsLoading,
    taskError,
    numberOfTickets,
  } = useTask(taskId, query);

  console.log(task)

  if (isTaskLoading)
    return (
      <Skeleton
        sx={{
          maxWidth: '100%',
          flexShrink: 0,
          height: '100%',
        }}
      />
    );
  if (taskError) return <Box sx={{ width: '100%' }}>ERRO</Box>;

  //if (!isTicketsLoading && tickets.length === 0) return <></>;

  return (
    <Box
      sx={{
        maxWidth: '100%',
        flexShrink: 0,
        height: '100%',
      }}
    >
      <TaskTitle>
        {task.sequence + '-' + task.title + ' : ' + numberOfTickets}
      </TaskTitle>

      <Stack py={1} spacing={0.5}>
        {isTicketsLoading ? (
          <Skeleton sx={{ width: '100%', mt: '-25px' }} height={200} />
        ) : (
          tickets.map((ticket) => (
            <CondensedTicket
              key={ticket.id}
              {...ticket}
              task={task}
              ticketId={ticket.id}
            />
          ))
        )}
      </Stack>
    </Box>
  );
}
