import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import TextFieldInput from '../../components/Form/TextFieldInput';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import { ticketsApi } from '../../api';
import { useTicket } from '../../hooks/useTicket';
import { FormProvider } from 'react-hook-form';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DatePickerInput from '../../components/Form/DatePickerComponent';
import CheckboxInput from '../../components/Form/CheckboxInput';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import DropdownFieldInput from '../../components/Form/DropdownFieldInput';
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const creatorSchema = yup.object({
  licensePlate: yup
    .string()
    .label('Nome do usuário')
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),

  price: yup.string().required('Campo obrigatório'),
  originCity: yup.string().required('Campo obrigatório'),
  clientName: yup.string().required('Campo obrigatório'),

  loadingDate: yup.date(),
  stopOver: yup.string(),
  product: yup.string(),
  isTrocaNota: yup.boolean(),
  driverName: yup.string(),
});

export default function EditTicketForm({ ticketId }) {
  const STATES = [
    'AC',

    'AL',

    'AP',

    'AM',

    'BA',

    'CE',

    'ES',

    'GO',

    'MA',

    'MT',

    'MS',

    'MG',

    'PA',

    'PB',

    'PR',

    'PE',

    'PI',

    'RJ',

    'RN',

    'RS',

    'RO',

    'RR',

    'SC',

    'SP',

    'SE',

    'TO',

    'DF',
  ];
  const navigate = useNavigate();
  async function onSubmit({
    licensePlate,
    price,
    originCity,
    destinyCity,
    originState,
    destinyState,
    clientName,
    loadingDate,
    stopOver,
    product,
    isTrocaNota,
    driverName,
  }) {
    try {
      await ticketsApi.updateTicket({
        licensePlate,
        price,
        originCity,
        destinyCity,
        originState,
        destinyState,
        clientName,
        loadingDate,
        stopOver,
        product,
        isTrocaNota,
        driverName,

        ticketId: ticket.id,
      });
      setMessage('Salvo !');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response.status === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const { ticket, isLoading } = useTicket(ticketId);
  const creatorMethods = useYupForm(creatorSchema, { ...ticket });

  useEffect(() => {
    if (!ticket) return;

    creatorMethods.reset(
      ticket,
      false,
      false,
      false,
      false,
      false,
      false,
      true
    );
  }, [creatorMethods, ticket]);

  if (isLoading) return <></>;

  return (
    <>
      <Grid container spacing={1}>
        <FormProvider {...creatorMethods}>
          <Container
            maxWidth="lg"
            spacing={6}
            sx={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper sx={{ p: 5, borderRadius: 6 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    textAlign="center"
                    fontSize={20}
                    py={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Editar ticket
                  </Typography>
                </Grid>
                <Grid item spacing={2} xs={12} md={6}>
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <TextFieldInput name="driverName" label="Motorista" />
                    <TextFieldInput name="licensePlate" label="Placa" />
                    <TextFieldInput name="price" label="Preço" />
                    <TextFieldInput name="originCity" label="Origem" />
                    <DropdownFieldInput
                      name="originState"
                      options={STATES}
                      label="Estado origem"
                      getOptionLabel={(option) => option}
                    />
                    <DatePickerInput
                      label="Carregamento"
                      name="loadingDate"
                      views={['day', 'month', 'year']}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <CheckboxInput
                      name="isTrocaNota"
                      onClick={(e) => e.target.checked}
                      label="Troca de nota"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <TextFieldInput name="destinyCity" label="Destino" />
                    <DropdownFieldInput
                      name="destinyState"
                      options={STATES}
                      label="Estado destino"
                      getOptionLabel={(option) => option}
                    />
                    <TextFieldInput name="clientName" label="Cliente" />
                    <TextFieldInput name="product" label="Produto" />
                    <TextFieldInput name="stopOver" label="Posto" />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={() => navigate('/dashboard')}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="secondary"
                    onClick={creatorMethods.handleSubmit(onSubmit)}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </FormProvider>
      </Grid>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
