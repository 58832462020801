import React from 'react';

import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
export const StyledTableCell = styled(TableCell)(({ theme, roomName }) => ({
  display: 'tableRowGroup',

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#979A9B',
    borderBottomWidth: '2px',
    boarderBottomColor: '#000',
    color: theme.palette.common.black,
  },

  [`&.${tableCellClasses.body}`]: {
    borderRightStyle: 'dotted',
    borderBottomWidth: '1px',
    borderBottomStyle: 'dotted',
    borderRightWidth: '1px',
    borderColor: '#000',
    ...(roomName === 'Mervil'
      ? {
          color: '#000',
          backgroundColor: '#c2c2c2',
        }
      : {}),
  },
}));

export const StyledEndTableCell = styled(TableCell)(({ theme, roomName }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#979A9B',
    color: theme.palette.common.black,
    display: 'tableRowGroup',
    borderBottomWidth: '2px',
    boarderBottomColor: '#000',
  },

  [`&.${tableCellClasses.body}`]: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'dotted',
    borderColor: '#000',
    ...(roomName === 'Mervil'
      ? {
          color: '#000',
          backgroundColor: '#c2c2c2',
        }
      : {}),
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme, roomName }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: '#fff',
    color: theme.palette.common.black,
    display: 'tableRowGroup',
  },
  [`&.${tableRowClasses.root}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}));
