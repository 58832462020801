import DatePickerInput from '../Form/DatePickerComponent';
import TextField from '@mui/material/TextField';
import { DropdownFieldInput } from '../Form';
import Stack from '@mui/material/Stack';
export default function ContactReportForm({ users }) {
  return (
    <Stack spacing={2}>
      <DatePickerInput
        sx={{ width: '100%' }}
        name="date"
        label={'Data'}
        views={['month', 'year']}
        renderInput={(params) => <TextField {...params} />}
      />
      <DropdownFieldInput
        fullWidth
        name="user"
        options={users ?? []}
        label="Usuário - Opcional"
        getOptionLabel={(option) => option.name}
      />
    </Stack>
  );
}
