import { useMemo, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableComponent from './TableComponent';
import Slide from '@mui/material/Slide';

const ChartColors = [
  '#e4605e',
  '#eefb54',
  '#766ec5',
  '#FF9900',
  '#FF007F',
  '#33CCCC',
  '#008000',
  '#800080',
  '#00ffff',
  '#fffafa',
];

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
export default function DetailsChart({ data, manyTask }) {
  const [open, setOpen] = useState(false);
  const [barChartDataState, setBarChartDataState] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const taskMap = useMemo(() => {
    if (!manyTask) return;
    const map = new Map();
    for (const task of manyTask) {
      map.set(task.id, task);
    }
    return map;
  }, [manyTask]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [barData, taskTitles] = useMemo(() => {
    if (!data) return [undefined, undefined];

    const taskTitles = new Set();
    const barData = [];

    for (const { ticketId, durations } of data.details) {
      const item = {
        ticketId,
      };

      if (durations.length === 0) continue;

      for (const { taskId, timeInMinutes } of durations) {
        const taskTitle = taskMap.get(taskId).title;
        taskTitles.add(taskTitle);

        item[taskTitle] = Math.floor(timeInMinutes);
      }

      barData.push(item);
    }

    return [barData, Array.from(taskTitles)];
  }, [data, taskMap]);

  return (
    <>
      <Paper
        sx={{
          mr: 'auto',
          ml: 'auto',
          width: '100%',
          height: '600px',
          display: 'flex',
        }}
      >
        <ResponsiveBar
          data={barData ?? []}
          keys={taskTitles ?? []}
          indexBy="ticketId"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={ChartColors}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          theme={{
            background: '#deddda',
            textColor: '#333333',
            fontSize: 11,
            axis: {
              domain: {
                line: {
                  stroke: '#777777',
                  strokeWidth: 1,
                },
              },
              legend: {
                text: {
                  fontSize: 12,
                  fill: '#333333',
                },
              },
              ticks: {
                line: {
                  stroke: '#777777',
                  strokeWidth: 1,
                },
                text: {
                  fontSize: 11,
                  fill: '#333333',
                },
              },
            },
            grid: {
              line: {
                stroke: '#dddddd',
                strokeWidth: 1,
              },
            },
            legends: {
              title: {
                text: {
                  fontSize: 11,
                  fill: '#333333',
                },
              },
              text: {
                fontSize: 11,
                fill: '#333333',
              },
              ticks: {
                line: {},
                text: {
                  fontSize: 10,
                  fill: '#333333',
                },
              },
            },
            annotations: {
              text: {
                fontSize: 13,
                fill: '#333333',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              link: {
                stroke: '#000000',
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              outline: {
                stroke: '#a51d2d',
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              symbol: {
                fill: '#000000',
                outlineWidth: 20,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
            },
            tooltip: {
              container: {
                background: '#f6f5f4',
                color: '#333333',
                fontSize: 15,
              },
              basic: {},
              chip: {},
              table: {},
              tableCell: {},
              tableCellValue: {},
            },
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          onClick={(node) => (
            setBarChartDataState(node.data), handleClickOpen()
          )}
          barAriaLabel={function (e) {
            return (
              e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
            );
          }}
        />
      </Paper>
      <Dialog
        TransitionComponent={SlideTransition}
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{'Detalhes'}</DialogTitle>
        <DialogContent>
          <TableComponent
            data={barChartDataState}
            barData={data?.details}
            manyTask={manyTask}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
