import api from './api';

async function createRegistrationForm({ workspaceId, referencedBy }) {
  await api.post('/registration-form', {
    referenceBy: referencedBy,
    workspaceId,
  });
}

const registrationFormApi = {
  createRegistrationForm,
};

export default registrationFormApi;
