import { useState } from 'react';
import useAuthSWR from '../../hooks/useAuthSWR';
import ManipulateRoomForm from './ManipulateRoomForm';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Slide from '@mui/material/Slide';
import { TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import roomApi from '../../api/room.api';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
function SlideUpTransition(props) {
  return <Slide {...props} direction="up" />;
}
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export default function ManipulateRoom() {
  const { data: rooms, mutate } = useAuthSWR('/rooms');

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [roomNameState, setRoomNameState] = useState('');
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');
  if (!rooms) return <>Loading...</>;
  async function addRoom() {
    try {
      await roomApi.CreateRoom({ name: roomNameState });

      setMessage('Created !');
      setOpenSuccess(true);
      setIsDialogOpen(false);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }
  return (
    <>
      <Divider textAlign="right" sx={{ mb: '20px' }}>
        <Fab color="secondary" onClick={() => setIsDialogOpen(true)}>
          <AddIcon />
        </Fab>
      </Divider>
      <List sx={{ display: 'flex', justifyContent: 'center' }}>
        <Container>
          <Paper>
            {rooms.map((room) => {
              return (
                <ManipulateRoomForm key={room.id} room={room} mutate={mutate} />
              );
            })}
          </Paper>
        </Container>
      </List>
      <Dialog
        fullWidth
        open={isDialogOpen}
        TransitionComponent={SlideUpTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle>Criação de Sala</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite o nome que será atribuído à filial
          </DialogContentText>
          <TextField
            fullWidth={true}
            label="Sala"
            onChange={(event) => setRoomNameState(event.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              addRoom();
              setIsDialogOpen(false);
            }}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
