import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { useBoolean } from 'react-hanger';

import useYupForm from '../../../hooks/useYupForm';
import { FormProvider } from 'react-hook-form';

function  CreateResourceDialog({
  title,
  children,
  open,
  onClose,
  onChange,
  createResource,
  yupSchema,
  resetValue,
}) {
  const { handleSubmit, reset, ...formMethods } = useYupForm(
    yupSchema,
    resetValue
  );
  const loading = useBoolean(false);

  async function submit(data) {
    try {
      loading.setTrue();

      const resource = await createResource(data);
      onChange(resource);
      onClose();
      reset(resetValue);
    } finally {
      loading.setFalse();
    }
  }

  return (
    <Dialog maxWidth="xs" scroll="body" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        <Stack sx={{ my: 2 }} spacing={2}>
          <FormProvider {...formMethods}>{children}</FormProvider>
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton disabled={loading.value} onClick={onClose}>
          Cancelar
        </LoadingButton>

        <LoadingButton loading={loading.value} onClick={handleSubmit(submit)}>
          Criar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateResourceDialog;
