import React from 'react';

import { TextFieldInput } from '../../components/Form';

export default function ExperienceTimeComponent({ name, isDisabled }) {
  return (
    <>
      <TextFieldInput
        name={`${name}.days`}
        fullWidth={true}
        sx={{ mt: '10px' }}
        size="small"
        type="number"
        label="Dias"
        disabled={isDisabled ? isDisabled : false}
      />

      <TextFieldInput
        name={`${name}.months`}
        fullWidth={true}
        disabled={isDisabled ? isDisabled : false}
        sx={{ mt: '10px' }}
        size="small"
        type="number"
        label="Mêses"
      />

      <TextFieldInput
        disabled={isDisabled ? isDisabled : false}
        name={`${name}.years`}
        fullWidth={true}
        sx={{ mt: '10px' }}
        size="small"
        type="number"
        label="Anos"
      />
    </>
  );
}
