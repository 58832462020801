import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

import PhoneNumberInput from '../../MaskedInputs/PhoneNumberInput';
import { useController } from 'react-hook-form';

import FileFieldInput from '../FileFieldInput';

import DatePickerInput from '../../Form/DatePickerComponent';
import { TextField } from '@mui/material';

export default function OwnerUpsertFormInput() {

  const { formState } = useController({name})

  const { field: checkboxField } = useController({
    name: 'legalPerson',
  });

  return (
    <>
      <Stack spacing={2} direction="row">
        <PhoneNumberInput sx={{ minWidth: 200 }} fullWidth name="phoneNumber"/>

        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxField.value}
              onChange={checkboxField.onChange}
            />
          }
          label="PJ"
        />
      </Stack>

      {/* input da CNH */}
      <FileFieldInput
        fullWidth
        disabled={checkboxField.value}
        label="CNH"
        name="cnh"
      />

      {/* Input da data da CNH */}
      <DatePickerInput
        label="Data de validade da CNH"
        name="cnhValidationDate"
        views={['day', 'month', 'year']}
        disabled={checkboxField.value}
        readOnly={checkboxField.value}
        renderInput={(params) => {
          return (
            <TextField {...params}
              disabled={checkboxField.value}
              error={Boolean(formState.errors.cnhValidationDate?.message)}
              helperText={formState.errors.cnhValidationDate?.message}
            />
          );
        }}
      />

      {/* input do comprovante de residência */}
      <FileFieldInput
        fullWidth
        disabled={checkboxField.value}
        label="Comprovante de Res."
        name="addressProof"
      />

      {/* Input da data do comprovante de residência */}
      <DatePickerInput
        label="Data do comprovante de residência"
        name="addressProofDate"
        views={['day', 'month', 'year']}
        disabled={checkboxField.value}
        readOnly={checkboxField.value}
        renderInput={(params) => {
          return (
            <TextField {...params}
              disabled={checkboxField.value}
              error={Boolean(formState.errors.addressProofDate?.message)}
              helperText={formState.errors.addressProofDate?.message}
            />
          )
        }}
      />
    </>
  );
}