const colorMap = new Map([
  ['ACTIVE', '#FFC300'],
  ['INACTIVE', '#FF5733'],
  ['IDLE', '#52D027'],
]);

export default function generateReport({
  day,
  max,
  min,
  timeline,
  scale,
  manyUsers,
  productivity,
}) {
  if (!timeline) return [undefined, undefined];
  if (!manyUsers) return [undefined, undefined];
  if (!productivity) return [undefined, undefined];

  const bumpData = [];
  const usersMap = new Map();
  for (const user of manyUsers) {
    usersMap.set(user.id, user.username);
  }
  for (const { ticketId, timeline: ticketTimeline } of timeline) {
    const timelinePoints = [];

    let lastPointsBuffer = [];
    let lastRangeId = null;

    for (let i = 0; i < ticketTimeline.length; i++) {
      const point = ticketTimeline[i];

      const currentRangeId = getRangeId(point.timestamp, scale);

      if (i === ticketTimeline.length - 1) {
        const condensedPoints = condensePoints(
          [...lastPointsBuffer, point],
          getAverageDateByRangeId(lastRangeId, scale, day),
          min,
          max
        );

        timelinePoints.push(...condensedPoints);
      }

      if (lastRangeId === null) {
        lastPointsBuffer.push(point);
      } else if (lastRangeId === currentRangeId) {
        lastPointsBuffer.push(point);

        if (i === ticketTimeline.length - 1) {
          const condensedPoints = condensePoints(
            lastPointsBuffer,
            getAverageDateByRangeId(lastRangeId, scale, day),
            min,
            max
          );

          timelinePoints.push(...condensedPoints);
        }

        continue;
      } else {
        const condensedPoints = condensePoints(
          lastPointsBuffer,
          getAverageDateByRangeId(lastRangeId, scale, day),
          min,
          max
        );

        lastPointsBuffer = [point];

        timelinePoints.push(...condensedPoints);

        if (i === ticketTimeline.length - 1) {
          const condensedPoints = condensePoints(
            [point],
            getAverageDateByRangeId(currentRangeId, scale, day),
            min,
            max
          );

          lastPointsBuffer = [point];

          timelinePoints.push(...condensedPoints);
        }
      }

      lastRangeId = currentRangeId;
    }

    const seriesPoints = [];

    for (let i = 0; i < timelinePoints.length; i++) {
      const point = timelinePoints[i];
      const nextPoint = timelinePoints[i + 1];

      // ARRUMAR ESSES USUÁRIOS AÍ NESSA
      const yValue =
        point.action === 'APPROVED' ? usersMap.get(point.userId) : null;
      const currentTimestamp = point.timestamp;
      const nextTimestamp = nextPoint?.timestamp ?? max;
      const numberOfPoints =
        (nextTimestamp - currentTimestamp) / 1000 / 60 / scale;
      // nao popula o meio entre dois pontos da timeline
      for (let i = 0; i < numberOfPoints; i++) {
        const additionalTime = i * scale * 60 * 1000;

        if (currentTimestamp.valueOf() + additionalTime < min.valueOf()) {
          seriesPoints.push({
            x: new Date(min).getHours(),
            y: yValue,
          });
        } else if (
          currentTimestamp.valueOf() + additionalTime > min.valueOf() &&
          currentTimestamp.valueOf() + additionalTime < max.valueOf()
        ) {
          seriesPoints.push({
            x: new Date(currentTimestamp.valueOf() + additionalTime).getHours(),
            y: yValue,
          });
        } else {
          break;
        }
      }
    }

    if (seriesPoints.length > 0) {
      bumpData.push({ id: ticketId, data: seriesPoints });
    }
  }

  const barSizes = [];
  const users = [];
  let pos = 0;

  const minInMinutes = getDateMinutes(min);
  const maxInMinutes = getDateMinutes(max);

  for (const { occupation, userId } of productivity) {
    users.push(usersMap.get(userId));

    let timeElapsedInMinutes = 0;

    for (const { durationInMinutes, state } of occupation) {
      const bar = [];

      bar[pos] = getBarSize({
        lowerBound: minInMinutes,
        upperBound: maxInMinutes,
        size: durationInMinutes,
        offset: timeElapsedInMinutes,
      });

      if (bar[pos] !== 0)
        barSizes.push({
          backgroundColor: colorMap.get(state),
          data: bar,
        });

      timeElapsedInMinutes += durationInMinutes;
    }
    pos++;
  }

  return [bumpData, barSizes, users];
}

function getBarSize({ lowerBound, upperBound, offset, size }) {
  let expectedSize = offset + size;

  if (expectedSize <= lowerBound) return 0;
  if (offset >= upperBound) return 0;

  if (expectedSize >= upperBound)
    if (offset >= lowerBound) return upperBound - offset;
    else return upperBound - lowerBound;

  if (offset >= lowerBound) return size;

  return expectedSize - lowerBound;
}

function getDateMinutes(date) {
  return date.getMinutes() + date.getHours() * 60;
}

function getRangeId(timestamp, scale) {
  const timestampInMinutes = timestamp / 1000 / 60;
  return Math.floor((timestampInMinutes + scale / 2) / scale);
}

function getAverageDateByRangeId(rangeId, scale) {
  const result = new Date(0);

  result.setMinutes(rangeId * scale);

  return result;
}

function condensePoints(points, averageTimestamp, min, max) {
  if (!points) [];

  if (averageTimestamp < min || averageTimestamp > max) return [];

  const qualquerCoisaMap = new Map();
  for (const { action, userId } of points) {
    const userLastRelevantAction = qualquerCoisaMap.get(userId);
    if (
      userLastRelevantAction !== 'DECLINED' &&
      userLastRelevantAction !== 'APPROVED'
    ) {
      qualquerCoisaMap.set(userId, action);
    }
  }
  const result = [];
  qualquerCoisaMap.forEach((lastAction, userId) => {
    result.push({ action: lastAction, userId, timestamp: averageTimestamp });
  });

  return result;
}
