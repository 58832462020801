import { useController } from 'react-hook-form';
import DropdownFieldInput from '../../Form/DropdownFieldInput';
import FileFieldInput from '../FileFieldInput';
import OwnerFieldInput from '../OwnerFieldInput';
import OwnerUpsertFormInput from '../WorkspaceOwnerDialog/OwnerUpserFormInputs';

export function UpsertAnttField() {
  return (
    <>
      <FileFieldInput label="RNTRC" name="rntrc" />

      <DropdownFieldInput
        options={['CPF', 'CNPJ']}
        label="Tipo"
        name="type"
      />
    </>
  );
}

export function UpsertOwnerField() {
  const { field: dropdownField } = useController({
    name: 'type',
  });

  return (
    <>
      {dropdownField.value === 'CPF' && <OwnerUpsertFormInput />}
      {dropdownField.value === 'CNPJ' && (
        <OwnerFieldInput label="Proprietário" name="owner" />
      )}
    </>
  );
}
