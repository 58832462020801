import PostAddIcon from '@mui/icons-material/PostAdd';
import ResourceFieldInput from './ResourceFieldInput';
import WorkspaceAnttDialog, { CreateAnttDialog } from './WorkspaceAnttDialog';

import { imageSchema } from './FileFieldInput';

import * as yup from 'yup';

export const anttSchema = yup.object({
  id: yup.number().required(),
  phoneNumber: yup.string().required(),
  rntrc: yup.string().required(),
  cnh: imageSchema.required().min(1),
  addressProof: imageSchema.required().min(1),
  cnhValidationDate: yup.date().required(),
  addressProofDate: yup.date().required()
});

export default function AnttFieldInput(props) {
  return (
    <ResourceFieldInput
      WorkspaceResourceDialog={WorkspaceAnttDialog}
      InputIcon={PostAddIcon}
      getValue={(antt) => antt?.rntrc ?? ''}
      WorkspaceCreateDialog={CreateAnttDialog}
      {...props}
    />
  );
}
