import MaskedTextField from './MaskedTextField';
import { useController } from 'react-hook-form';

export default function TextFieldInputPlate(props) {
    const { field, fieldState, formState } = useController({ name: props.name });

    function handlePlateChange(plate){
        props.handlePlateChange(plate)
    } 

    return (
        <MaskedTextField
            {...field}
            {...props}
            onBlur={(e) => {
                field.onBlur(e);
                props.onBlur && props.onBlur(e);
            }}
            InputLabelProps={{
                shrink: field.value !== '' && field.value !== undefined,
            }}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
            onChange={(e)=>{
                field.onChange(e);
                handlePlateChange(e.target.value)
            }}
        />
    );
}