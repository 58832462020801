import { Routes, Route } from 'react-router-dom';
import Login from './Login';
import Ticket from './Ticket';
import Dashboard from './Dashboard';
import CreateTask from './CreateTask';
import ViewUsers from './Users/ViewUsers';
import EditTicket from './EditTickets/EditTicket';
import TaskCard from '../components/Task/TaskCard';
import CreateSector from './Sector/CreateSector';
import EditSector from './Sector/EditSector';
import SetPermission from './Permissions/SetPermission';
import RegisterDriver from './Drivers/RegisterDriver';
import CreateTicket from './Ticket/CreateTicket';
import Acomp from './Acomp/Acomp';
import InactiveTickets from './Ticket/InactiveTickets';
import ViewReports from './Reports/ViewReports';
import { Navigate } from 'react-router-dom';
import AssignTaskReponsability from './Sector/AssignTaskReponsability';
import CreateRoom from './Room/CreateRoom';
import ManipulateRoom from './Room/ManipulateRoom';
import ViewWorkspaces from '../pages/Workspace/ViewWorkspaces';
import DriverDetails from './Drivers/DriverDetails';
import WithNavbar from '../layouts/WithNavbar';
import DashboardLayout from '../layouts/DashboardLayout';
import EditWorkspace from './EditWorkspace';
import GenerateTimeReport from './timeReport/generateTimeReport';

import CommercialReport from '../components/CommercialComponents/commercialReport';
import CreateForm from './RegistrationForm/CreateForm';
import {
  DriverForm,
  TruckTractorForm,
  TruckTrailerForm,
  BankAccountForm,
  ReferencesForm,
  ConclusionForm,
} from '../components/EditWorkspaceForm/Steps';
import ViewForm from './RegistrationForm/ViewForm';
import CommercialList from '../components/CommercialComponents/List';
import CustomersToInteract from '../components/CommercialComponents/CustomersToInteract';
import { useAuth } from '../contexts/AuthContext';
import ListOfRegistrations from './RegistrationForm/ListOfRegistrations';
import FlowOfTrucks from '../Trucks/FlowOfTrucks';
import SinglePage from '../layouts/SinglePage';

export default function PageRoutes() {
  const { user: me } = useAuth();

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>

      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route element={<SinglePage />}>
        <Route path="/createTicket" element={<CreateTicket />} />
      </Route>
      <Route element={<WithNavbar />}>
        <Route path="/tickets/:ticketId" element={<Ticket />} />
        <Route path="/createTask" element={<CreateTask />} />
        <Route path="/viewUsers" element={<ViewUsers />} />
        <Route path="/editTicket/:ticketId" element={<EditTicket />} />
        <Route path="/editTask" element={<TaskCard />} />
        <Route path="/createSector" element={<CreateSector />} />
        <Route path="/editSector" element={<EditSector />} />
        <Route path="/assignPermissions" element={<SetPermission />} />
        <Route path="/registerDriver" element={<RegisterDriver />} />
        <Route path="/registrationForm/:formId" element={<CreateForm />} />
        <Route path="/record/:formId" element={<ViewForm />} />
        <Route path="/Acomp" element={<Acomp />} />
        <Route
          path="/completed-tickets"
          element={<InactiveTickets status="COMPLETED" />}
        />
        <Route
          path="/deleted-tickets"
          element={<InactiveTickets status="DELETED" />}
        />
        <Route path="/reports" element={<ViewReports />} />
        <Route path="/createRoom" element={<CreateRoom />} />
        <Route
          path="AssignTaskReponsability"
          element={<AssignTaskReponsability />}
        />
        <Route path="manipulateRoom" element={<ManipulateRoom />} />
        <Route path="/viewDrivers" element={<ViewWorkspaces />} />
        <Route path="/timeReport" element={<GenerateTimeReport />} />

        <Route path="/FlowOfTrucks" element={<FlowOfTrucks />} />

        <Route path="/editWorkspaces/:workspaceId" element={<EditWorkspace />}>
          <Route index element={<Navigate to="1" />} />
          <Route path="1" element={<DriverForm />} />
          <Route path="2" element={<TruckTractorForm />} />
          <Route path="3" element={<TruckTrailerForm />} />
          <Route path="4" element={<BankAccountForm />} />
          <Route path="5" element={<ReferencesForm />} />
          <Route path="6" element={<ConclusionForm />} />
        </Route>

        <Route path="/DriverDetails/:cpf" element={<DriverDetails />} />
        <Route
          path="/modules/commercial"
          element={<CustomersToInteract me={me} />}
        />

        <Route path="/registrations" element={<ListOfRegistrations />} />
        <Route path="/modules/commercial/all" element={<CommercialList />} />

        <Route
          path="/modules/commercial/report"
          element={<CommercialReport />}
        />
      </Route>
    </Routes>
  );
}
