import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useWorkspaceAntts } from '../../../hooks/useWorkspaces';
import CreateResourceDialog from '../WorkspaceResourceDialog/CreateResourceDialog';
import { updateAnttSchema } from './AnttUpsertForm';
import { UpsertAnttField, UpsertOwnerField } from './UpsertAnttFields';

export default function CreateAnttDialog({ ...props }) {
  const { addAntt } = useWorkspaceAntts();
  const handleRequest = useSnackbar();

  async function createAntt({
    rntrc,
    phoneNumber,
    cnh,
    addressProof,
    cnhValidationDate,
    addressProofDate,
    legalPerson,
    type,
    owner,
  }) {
    async function main() {
      const newOwnerInput = {
        phoneNumber,
        ...(legalPerson ? {} : { cnh, addressProof, cnhValidationDate, addressProofDate }),
      };
      console.log(`Recebido no createAnttDialog ${newOwnerInput}`)

      return await addAntt({
        rntrc,
        owner: type === 'CPF' ? newOwnerInput : owner.id,
      });
    }

    return await handleRequest(main);
  }

  return (
    <CreateResourceDialog
      title="Criar ANTT"
      createResource={createAntt}
      yupSchema={updateAnttSchema}
      resetValue={{
        rntrc: [],
        phoneNumber: '',
        addressProof: [],
        cnh: [],
        cnhValidationDate: undefined,
        addressProofDate:undefined,
        legalPerson: false,
        type: 'CPF',
        owner: null,
      }}
      {...props}
    >
      <UpsertAnttField />
      <UpsertOwnerField />
    </CreateResourceDialog>
  );
}
