import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import RemoveIcon from '@mui/icons-material/Remove';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useBoolean, useArray } from 'react-hanger';
import { useRef } from 'react';

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import useWorkspaceImages from '../../../hooks/useWorkspaces/useWorkspaceImages';

export default function FileDropInput() {
  const files = useArray([]);
  const inputRef = useRef();
  const loading = useBoolean(false);

  const { uploadImages } = useWorkspaceImages();
  const handleRequest = useSnackbar();

  async function submit() {
    async function main() {
      loading.setTrue();
      await uploadImages(files.value);
      files.clear();
      loading.setFalse();
    }

    await handleRequest(main);
  }

  return (
    <Stack spacing={2} sx={{ height: '70vh' }}>
      <Button
        fullWidth
        onClick={() => inputRef.current.click()}
        startIcon={<AddIcon />}
        variant="outlined"
      >
        Adicionar
      </Button>
      <List
        dense
        sx={{
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': { width: '10px' },
        }}
      >
        {files.value.map((img, index) => (
          <>
            <ListItem
              key={img.name}
              secondaryAction={
                <IconButton onClick={() => files.removeIndex(index)}>
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={img.name} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>

      <LoadingButton
        disabled={files.value.length === 0}
        onClick={submit}
        loading={loading.value}
        endIcon={<DriveFileMoveIcon />}
        variant="contained"
      >
        Enviar
      </LoadingButton>

      <input
        type="file"
        hidden
        multiple
        ref={inputRef}
        onChange={(e) => {
          const newFiles = Array.from(e.target.files);

          files.setValue((files) => {
            const oldFiles = new Set(files.map((file) => file.name));

            newFiles.forEach((file) => {
              if (oldFiles.has(file.name)) return;
              files.push(file);
            });

            return [...files];
          });
        }}
      />
    </Stack>
  );
}
