import DeleteResourceDialog from '../WorkspaceResourceDialog/DeleteResourceDialog';

export default function DeleteAnttDialog({
  onClose,
  resource: antt,
  onDelete,
}) {
  if (!antt) return <></>;
  return (
    <DeleteResourceDialog
      title={`Deletar ANTT com número ${antt?.phoneNumber}?`}
      desc="Esta ação é irreversível. Você tem certeza que desejar deletar esta ANTT?"
      onClose={onClose}
      open={Boolean(antt)}
      onDelete={() => onDelete(antt)}
    />
  );
}
