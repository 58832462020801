import TableBody from '@mui/material/TableBody';
import {
  CustomTable,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  TableText,
  TableTitle,
} from '../../components/Table';
import { useTicketHistory } from '../../hooks/useTicket';
import TableDate from './TableDate';

const actionMap = {
  PASSED: (history) =>
    `Ticket avançado por ${history.triggeredBy.username} de ${history.movedFrom?.title} para ${history.movedTo?.title}`,
  RETURNED: (history) =>
    `Ticket retornado por ${history.triggeredBy.username} de ${history.movedFrom?.title} para ${history.movedTo?.title}`,
  DELETED: (history) => `Ticket excluído por ${history.triggeredBy.username}`,
  PRIORITY_UPDATED: (history) =>
    `Prioridade alterada por ${history.triggeredBy.username}`,
  CREATED: (history) => `Ticket criado por ${history.triggeredBy.username}`,
  UPDATED: (history) => `Ticket alterado por ${history.triggeredBy.username}`,
  RISK_MANAGED_UPDATED: (history) =>
    `Risco alterado por ${history.triggeredBy.username}`,
};

export default function TicketHistory({ ticketId }) {
  const { history, isLoading } = useTicketHistory(ticketId);

  if (isLoading) return <></>;

  return (
    <CustomTable>
      <CustomTableHead>
        <CustomTableCell align="center">
          <TableTitle>Horário</TableTitle>
        </CustomTableCell>

        <CustomTableCell align="left">
          <TableTitle>Ação</TableTitle>
        </CustomTableCell>
      </CustomTableHead>

      <TableBody>
        {history.map((hist) => {
          return (
            <CustomTableRow key={hist.id}>
              <CustomTableCell align="center">
                <TableText>
                  <TableDate dateString={hist.createdAt} />
                </TableText>
              </CustomTableCell>

              <CustomTableCell align="left">
                <TableText>{actionMap[hist.action](hist)}</TableText>
              </CustomTableCell>
            </CustomTableRow>
          );
        })}
      </TableBody>
    </CustomTable>
  );
}
