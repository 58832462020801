import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function TruckComponentInput({
  value,
  onRemove,
  showLicensePlate,
}) {
  return (
    <>
      {showLicensePlate && (
        <TextField value={value?.licensePlate} variant="outlined" />
      )}

      {onRemove && <Button onClick={onRemove}>Remover</Button>}
    </>
  );
}
