import Grid from '@mui/material/Grid';
import { useTicket } from '../../../hooks/useTicket';
import TicketInfoTitle from './TicketInfoTitle';
import TicketInfoText from './TicketInfoText';
import TicketInfoSurface from './TicketInfoSurface';
import TicketInfoButtons from './TicketInfoButtons';
import useAuthSWR from '../../../hooks/useAuthSWR';

function TicketInfo({ ticketId }) {
  const { data: me } = useAuthSWR('users/me');
  const { ticket, isLoading } = useTicket(ticketId);

  let transformDate = new Date(ticket?.loadingDate);
  let convertedLoadingDate = `${transformDate
    .getDate()
    .toString()
    .padStart(2, '0')}/${(transformDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${transformDate.getFullYear()}
  `;

  if (isLoading) return <></>;
  if (!me) return <>Loading...</>;

  return (
    <>
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12}>
          <TicketInfoTitle ticket={ticket} />
        </Grid>
        <Grid item xs={12} md={6} /* sx={{ minHeight: '10vh' }} */>
          <TicketInfoSurface>
            <TicketInfoText>
              NO: {ticket.orderNumber ? ticket.orderNumber : 'Sem Número'}
            </TicketInfoText>
            <TicketInfoText>
              NO: {ticket.orderNumber ? ticket.orderNumber : 'Sem Número'}
            </TicketInfoText>
            <TicketInfoText>Motorista: {ticket.driverName}</TicketInfoText>
            <TicketInfoText>Placa: {ticket.licensePlate}</TicketInfoText>
            <TicketInfoText>Preço: R${ticket.price}</TicketInfoText>
            <TicketInfoText>
              Origem: {ticket.originCity + ' - ' + ticket.originState}
            </TicketInfoText>
            <TicketInfoText>
              Destino: {ticket.destinyCity + ' - ' + ticket.destinyState}
            </TicketInfoText>
            <TicketInfoText>
              Embarcador: {ticket.createdBy?.username}
            </TicketInfoText>
          </TicketInfoSurface>
        </Grid>
        <Grid item xs={12} md={6} /* sx={{ minHeight: '10vh' }} */>
          <TicketInfoSurface>
            <TicketInfoText>
              Já cadastrado : {ticket.isDriverRegistered ? 'Sim' : 'Não'}
            </TicketInfoText>
            <TicketInfoText>
              Atualização de cadastro : {ticket.isDriverUpdate ? 'Sim' : 'Não'}
            </TicketInfoText>
            <TicketInfoText>Cliente: {ticket.clientName}</TicketInfoText>
            <TicketInfoText>Produto: {ticket.product}</TicketInfoText>
            <TicketInfoText>Posto: {ticket.stopOver}</TicketInfoText>
            <TicketInfoText>
              Carregamento: {convertedLoadingDate}
            </TicketInfoText>
            <TicketInfoText>
              Troca de nota: {ticket.isTrocaNota ? 'Sim' : 'Não'}
            </TicketInfoText>
          </TicketInfoSurface>
        </Grid>

        <Grid item xs={12}>
          <TicketInfoButtons ticket={ticket} />
        </Grid>
      </Grid>
    </>
  );
}
export default TicketInfo;
