import React from 'react';
import Slide from '@mui/material/Slide';
import useAuthSWR from '../../hooks/useAuthSWR';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import ClientHistoryTable from './ClientHistoryTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientContactedHistory({
  isHiddend,
  contactId,
  onHidden,
}) {
  const { data: history } = useAuthSWR(
    `/contacts/${contactId}/interactions`,
    {},
    {},
    contactId === undefined
  );

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={isHiddend}
      TransitionComponent={Transition}
      keepMounted
      onClose={onHidden}
    >
      <DialogTitle>{'Histórico de interações'}</DialogTitle>
      <DialogContent>
        {history === undefined ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : history.length === 0 ? (
          <Typography align="center" variant="h6">
            Nenhuma interação registrada!
          </Typography>
        ) : (
          <ClientHistoryTable history={history} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onHidden}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
