import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ResourceFieldInput from './ResourceFieldInput';
import WorkspaceOwnerDialog, {
  CreateOwnerDialog,
} from './WorkspaceOwnerDialog';

import { imageSchema } from './FileFieldInput';

import * as yup from 'yup';

export const ownerSchema = yup.object({
  id: yup.number().required(),
  phoneNumber: yup.string().required(),
  cnh: imageSchema.required().min(1),
  addressProof: imageSchema.required().min(1),
  cnhValidationDate: yup
    .date()
    .when('legalPerson', {
      is: true,
      then: yup.date().nullable(),
      otherwise: yup.date().required(),
    })
    .typeError('Campo obrigatório')
    .min(new Date(), 'CNH expirada'), //validação par a CNH
  addressProofDate: yup
    .date()
    .when('legalPerson', {
      is: false,
      then: yup.date().required(),
    })
    .typeError('Campo obrigatório')
    .test(
      'is-invalid',
      'Comprovante inválido',
      (addressProofDate, context) => {
        if (context.parent.legalPerson) return true; 
      
        if (!addressProofDate) return false;
      
        const currentDate = new Date(); 
        currentDate.setHours(0, 0, 0, 0);
      
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1); 
      
        if (addressProofDate <= currentDate && addressProofDate >= oneYearAgo) return true; 
    }
  ),
});

export default function OwnerFieldInput(props) {

  return (
    <ResourceFieldInput
      WorkspaceResourceDialog={WorkspaceOwnerDialog}
      InputIcon={PersonAddIcon}
      getValue={(owner) => owner?.cnh ?? owner?.phoneNumber}
      getShrink={(owner) => owner != undefined}
      WorkspaceCreateDialog={CreateOwnerDialog}
      {...props}
    />
  );
}
