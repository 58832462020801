import { useEffect } from 'react';
import workspaceApi from '../../api/workspace.api';
import DriverComponent from './DriverComponent';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useSWR from 'swr';
import { useBoolean } from 'react-hanger';
import CreateWorkspaceModal from '../../components/CreateWorkspaceModal';

export default function DriverList({ query, page, limit, setIsFinished }) {
  const isSearching = query !== '';
  const createModalOpen = useBoolean(false);

  const {
    data: defaultWorkspaces,
    error: defaultError,
    mutate,
  } = useSWR(() => (isSearching ? null : { page, limit }), {
    fetcher: workspaceApi.getMany,
  });

  const { data: searchWorkspaces, error: searchError } = useSWR(
    () => (isSearching ? query : null),
    { fetcher: workspaceApi.search }
  );

  const workspaces = isSearching ? searchWorkspaces : defaultWorkspaces;
  const error = !isSearching ? searchError : defaultError;
  useEffect(
    () => setIsFinished(defaultWorkspaces?.length < limit),
    [defaultWorkspaces, setIsFinished, limit]
  );
  if (!workspaces) return <></>;
  if (error) return <>ERROR</>;

  return (
    <Stack spacing={2}>
      <Divider textAlign="right" flexItem>
        <Fab
          onClick={createModalOpen.setTrue}
          color="secondary"
          variant="extended"
        >
          <AddIcon sx={{ mr: 1 }} />
          Criar Novo
        </Fab>
      </Divider>

      {workspaces.map((driver) => {
        return <DriverComponent key={driver.id} driver={driver} />;
      })}

      <CreateWorkspaceModal
        open={createModalOpen.value}
        onClose={createModalOpen.setFalse}
        mutate={mutate}
      />
    </Stack>
  );
}
