import api from './api';

async function updateUserById(
  id,
  { username, fullName, cellphone, sectorId, roleName, password, roomId }
) {
  const res = await api.patch(`/users/${id}`, {
    username,
    fullName,
    cellphone,
    sectorId,
    roleName,
    password,
    roomId,
  });

  return res.data;
}

async function deleteUserById({ id }) {
  const res = await api.delete(`/users/${id}`);

  return res.data;
}

async function login({ username, password }) {
  const res = await api.post('/users/login', {
    username,
    password,
  });

  return res.data;
}

async function getMe() {
  const res = await api.get('/users/me');

  return res.data;
}

async function getLoggedUserPermissions() {
  const res = await api.get('/users/me/task-permissions');

  return res.data;
}

async function createNewUser({
  name,
  username,
  roleName,
  sectors,
  cellphone,
  password,
  roomId,
}) {
  const res = await api.post('/users', {
    username,
    name,
    roleName,
    sectors,
    cellphone,
    password,
    roomId,
  });

  return res.data;
}
async function updateUserInfo({ username, id, name }) {
  const res = await api.put(`users/${id}/info`, {
    username,
    name,
  });

  return res.data;
}

async function changeUserRole({ id, roleName }) {
  const res = await api.put(`users/${id}/role`, {
    value: roleName,
  });
  return res.data;
}
async function getAll() {
  const res = await api.get('/users');

  return res.data;
}
async function changeUserPassword({ id, password }) {
  const res = await api.put(`users/${id}/password`, {
    value: password,
  });
  return res.data;
}
async function changeUserPhoneNumber({ id, phoneNumber }) {
  const res = await api.put(`users/${id}/phone-number`, {
    value: phoneNumber,
  });
  return res.data;
}
async function restoreUser({ id }) {
  const res = await api.post(`users/${id}`);
  return res.data;
}
async function addUserToSector({ id, sectorId }) {
  const res = await api.put(`/sectors/${sectorId}/users/${id}`);
  return res.data;
}
async function removeUserFromSector({ id, sectorId }) {
  const res = await api.delete(`/sectors/${sectorId}/users/${id}`);
  return res.data;
}
async function initiateSectorTransfer({ id, from, to }) {
  const res = await api.post('/sector-transfer', {
    userId: id,
    fromSectorId: from.id,
    toSectorId: to.id,
  });
  return res.data;
}

async function addUserToRoom({ id, roomId }) {
  const res = await api.put(`/users/${id}/rooms/${roomId}`);
  return res.data;
}
async function deleteUserfromRoom({ id, roomId }) {
  const res = await api.put(`/rooms/${roomId}/users/${id}`);
  return res.data;
}
async function transferUserToRoom({ toRoom, id }) {
  const res = await api.put(`/rooms/${toRoom.id}/users/${id}`);
  return res.data;
}
const usersApi = {
  updateUserInfo,
  changeUserRole,
  changeUserPhoneNumber,
  changeUserPassword,
  restoreUser,
  addUserToSector,
  removeUserFromSector,
  initiateSectorTransfer,
  addUserToRoom,
  deleteUserfromRoom,
  transferUserToRoom,

  createNewUser,
  deleteUserById,
  updateUserById,
  login,
  getMe,
  getAll,
  getLoggedUserPermissions,
};

export default usersApi;
