import TextFieldInput from '../../Form/TextFieldInput';
import WorkspaceFormButtons from '../WorkspaceFormButtons';
import '../../../shared/yup-locale';
import * as yup from 'yup';
import { CpfCnpjInput } from '../../MaskedInputs';

export const bankAccountFormSchema = yup.object({
  cpf: yup.string().required().min(14).label('CPF/CNPJ'),
  fullName: yup.string().required().label('Nome'),
  bankName: yup.string().required().label('Nome do banco'),
  agencyNumber: yup.string().required().label('Nome da agência'),
  accountNumber: yup.string().required().label('N° da conta'),
  pixNumber: yup.string().label('PIX'),
});

export default function BankAccountForm() {
  return (
    <>
      <CpfCnpjInput name="bankAccount.cpf" />
      <TextFieldInput name="bankAccount.fullName" label="Nome Completo" />
      <TextFieldInput name="bankAccount.bankName" label="Nome do Banco" />
      <TextFieldInput name="bankAccount.agencyNumber" label="N° da Agência" />
      <TextFieldInput name="bankAccount.accountNumber" label="N° da Conta" />
      <TextFieldInput name="bankAccount.pixNumber" label="Chave PIX" />

      <WorkspaceFormButtons />
    </>
  );
}
