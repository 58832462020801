import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useYupForm from '../hooks/useYupForm';
import { useState } from 'react';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import { CpfInput } from './MaskedInputs';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import workspaceApi from '../api/workspace.api';

const createSchema = yup.object({
  cpf: yup.string().required().length(14).label('CPF'),
});
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function CreateWorkspaceModal({ open, onClose, mutate }) {
  const { handleSubmit, reset, ...formMethods } = useYupForm(createSchema, {
    cpf: undefined,
  });
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  async function submit({ cpf }) {
    try {
      await mutate(
        async (workspaces) => {
          await workspaceApi.createWorkspace({ cpf });

          return workspaces;
        },
        { revalidate: true, rollbackOnError: true }
      );
      onClose();
      setMessage('Criado !');
      setOpenSuccess(true);

      reset({ cpf: '' });
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response === 422) {
        setMessage('Deu ruim');
      }

      setOpenAlert(true);
    }
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Criar área de trabalho</DialogTitle>

        <DialogContent>
          <FormProvider {...formMethods}>
            <CpfInput sx={{ mt: 1 }} fullWidth name="cpf" />
          </FormProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleSubmit(submit)}>Criar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
