import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

export default function ResourceImageCollapse({ open, children }) {
  return (
    <Collapse in={open}>
      <Stack spacing={1} mt={1}>
        {children}
      </Stack>
    </Collapse>
  );
}
