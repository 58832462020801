import React, { useState } from 'react';
import usePagination from '../../hooks/usePagination';
import Pagination from '../../components/Pagination';
import Container from '@mui/material/Container';
import { TextFieldInput } from '../../components/Form';
import Paper from '@mui/material/Paper';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import { FormProvider } from 'react-hook-form';
import Button from '@mui/material/Button';
import Forms from './Forms';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
const querySchema = {
  limit: yup.number().nullable(),
  minimumProgress: yup.number().nullable(),
};

export default function ListOfRegistrations() {
  const queryMethods = useYupForm(querySchema);

  const pagination = usePagination({ defaultLimit: 10, defaultPage: 0 });
  const [isFinished, setIsFinished] = useState(false);
  const [query, setQuery] = useState();

  return (
    <FormProvider {...queryMethods}>
      <Grid
        container
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', aligmItem: 'center' }}
      >
        <Grid item xs={12}>
          <Pagination {...pagination.register()} disableRight={isFinished} />
        </Grid>
      </Grid>

      <Container sx={{ mt: 10 }}>
        <Forms
          query={query}
          limitP={pagination.limit}
          page={pagination.page}
          setIsFinished={setIsFinished}
        />
      </Container>
    </FormProvider>
  );
}
