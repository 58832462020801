import React, { useState } from 'react';
import useAuthSWR from '../../hooks/useAuthSWR';
import EditTaskForm from './EditTaskForm';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import taskApi from '../../api/task.api';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import { FormProvider } from 'react-hook-form';
import { TextFieldInput, CheckboxInput } from '../Form';
import List from '@mui/material/List';
const createTaskSchema = yup.object({
  title: yup.string(),
  sequence: yup.number(),
  completable: yup.boolean(),
  isTimeManaged: yup.boolean(),
});

function SlideUpTransition(props) {
  return <Slide {...props} direction="up" />;
}
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function TaskCard() {
  const taskMethod = useYupForm(createTaskSchema);

  const { data: task, mutate } = useAuthSWR('/tasks');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');

  if (!task) return <></>;

  async function addTask({ title, sequence, completable, isTimeManaged }) {
    try {
      await taskApi.createTask({ title, sequence, completable, isTimeManaged });

      setMessage('Criado !');
      setOpenSuccess(true);

      setIsDialogOpen(false);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }
  return (
    <FormProvider {...taskMethod}>
      <Divider textAlign="right" sx={{ mb: '20px' }}>
        <Fab color="secondary" onClick={() => setIsDialogOpen(true)}>
          <AddIcon />
        </Fab>
      </Divider>
      <Container>
        <List>
          {task.map((task) => {
            return <EditTaskForm key={task.id} task={task} />;
          })}
        </List>
      </Container>

      <Dialog
        fullWidth
        open={isDialogOpen}
        TransitionComponent={SlideUpTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle>Criação de coluna</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite o nome que será atribuído à coluna
          </DialogContentText>

          <TextFieldInput
            fullWidth={true}
            label="Nome"
            sx={{ mt: 2 }}
            name="title"
          />
          <TextFieldInput
            name="sequence"
            sx={{ mt: 2 }}
            type="number"
            fullWidth={true}
            label="Sequência"
          />
          <CheckboxInput name="completable" label="Arquivável" />
          <CheckboxInput name="isTimeManaged" label="Tempo controlado" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancelar</Button>
          <Button onClick={taskMethod.handleSubmit(addTask)}>Criar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
