import api from './api';

async function createTicket({
  licensePlate,
  price,
  originCity,
  destinyCity,
  originState,
  destinyState,
  clientName,
  status,
  loadingDate,

  stopOver,
  product,
  isTrocaNota,
  driverName,
  taskId,
  isDriverRegistered,
  isDriverUpdate,
}) {
  const res = await api.post('/tickets', {
    licensePlate,
    price,
    originCity,
    destinyCity,
    originState,
    destinyState,
    clientName,
    status,
    loadingDate,
    stopOver,

    product,
    isTrocaNota,
    driverName,
    taskId,
    isDriverRegistered,
    isDriverUpdate,
  });

  return res.data;
}
async function updateTicket({
  ticketId,
  licensePlate,
  price,
  originCity,
  destinyCity,
  originState,
  destinyState,
  clientName,
  status,
  loadingDate,
  stopOver,
  product,
  isTrocaNota,
  driverName,
  isDriverRegistered,
  isPriority,
  isDriverUpdate,
}) {
  const res = await api.patch(`/tickets/${ticketId}`, {
    licensePlate,
    price,
    originCity,
    destinyCity,
    originState,
    destinyState,
    clientName,
    status,
    loadingDate,
    stopOver,
    product,
    isTrocaNota,
    driverName,
    isDriverRegistered,
    isPriority,
    isDriverUpdate,
  });

  return res.data;
}

async function changeTicketTask({ ticketId, taskId }) {
  const res = await api.put(`/tickets/${ticketId}/tasks/${taskId}`);

  return res.data;
}

async function passTicket({
  ticketId,
  completable,
  nearestBranch,
  ticketReferenced,
}) {
  const res = await api.put(
    `/tickets/${ticketId}/tasks/${completable ? 'end' : 'next'}`,
    {
      nearestBranch,
      ticketReferenced,
    }
  );

  return res.data;
}

async function returnTicket({ ticketId, taskId }) {
  const res = await api.put(`/tickets/${ticketId}/tasks/${taskId}`);

  return res.data;
}

async function deleteTicket({ ticketId, reason }) {
  const res = await api.delete(`/tickets/${ticketId}`, { data: { reason } });

  return res.data;
}
async function createNewNote({ content, ticketId }) {
  const res = await api.post(`/tickets/${ticketId}/notes`, {
    content,
  });
  return res.data;
}
async function createMoment({ ticketId, action }) {
  await api.post(`reports/moments`, {
    ticketId,
    action,
  });
}
async function setPriority({ ticketId, value }) {
  const res = await api.put(`/tickets/${ticketId}/priority`, {
    value,
  });
  return res.data;
}

async function getIndividualTicket({ ticketId }) {
  const res = await api.get(`/tickets/${ticketId}`);

  return res.data;
}

const ticketsApi = {
  getIndividualTicket,
  createTicket,
  updateTicket,
  changeTicketTask,
  passTicket,
  returnTicket,
  deleteTicket,
  createNewNote,
  createMoment,
  setPriority,
};

export default ticketsApi;
