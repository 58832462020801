import { FormProvider } from 'react-hook-form';	
import { useWorkspace } from '../../../contexts/WorkspaceContext';	
import TextFieldInput from '../../Form/TextFieldInput';	
import FileFieldInput, { imageSchema } from '../FileFieldInput';	
import { useSnackbar } from '../../../contexts/SnackbarContext';	
import WorkspaceFormButtons from '../WorkspaceFormButtons';	

import * as yup from 'yup';	
import useYupForm from '../../../hooks/useYupForm';	
import { useEditWorkspace } from '../../../contexts/EditWorkspaceContext';	
import '../../../shared/yup-locale';	

import TextField from '@mui/material/TextField';	
import { useWorkspaceOwners } from '../../../hooks/useWorkspaces';	
import { useBoolean } from 'react-hanger';	
import { useEffect, useState } from 'react';	
import { PhoneNumberInput } from '../../MaskedInputs';	
import { Input } from '@mui/material';	
import DatePickerInput from '../../Form/DatePickerComponent';

const driverSchema = yup.object({	
    name: yup.string().required().min(4).label('Nome'),	
    phoneNumber: yup.string().required().min(14).label('Telefone'),	
    addressProof: imageSchema	
        .required()	
        .min(1)	
        .label('Comprovante de residência'),	
    cnh: imageSchema.required().min(1).label('CNH'),	
    cnhValidationDate: yup.date()
        .typeError('Campo obrigatório')
        .min(new Date(), 'CNH expirada')
        .required('Campo obrigatório'),
    addressProofDate: yup.date()
        .typeError('Campo obrigatório')
        .required('Campo obrigatório')
        .test(
            'is-invalid', 'Comprovante inválido',
            addressProofDate => {
                if (!addressProofDate) return false
            
                const currentDate = new Date()
                currentDate.setHours(0,0,0,0)

                const oneYearAgo = new Date()
                oneYearAgo.setFullYear(currentDate.getFullYear() - 1)

                if(addressProofDate <= currentDate && addressProofDate >= oneYearAgo) return true
        }
    )
});	

export default function DriverForm() {	
    const { workspace, isLoading } = useWorkspace();	
    const { driver, updateOwner, addOwner } = useWorkspaceOwners();	
    const handleRequest = useSnackbar();	
    const loading = useBoolean(false);	
    const { nextStep } = useEditWorkspace();	
    const { handleSubmit, reset, ...formMethods } = useYupForm(driverSchema);	

    useEffect(() => {	
        if (driver) reset(driver);	
    }, [reset, driver]);	

    async function submit({ phoneNumber, addressProof, cnh, name, cnhValidationDate, addressProofDate }) {	
        loading.setTrue();	
        console.log("recebi", { phoneNumber, addressProof, cnh, name, cnhValidationDate, addressProofDate })	

        if (driver)	
        await handleRequest(	
            async () =>	
            await updateOwner(driver.id, {	
                addressProof,	
                cnh,	
                name,	
                phoneNumber,	
                cnhValidationDate,	
                addressProofDate,
            })	
        );	
    else	
        await handleRequest(	
            async () => await addOwner({ addressProof, cnh, name, phoneNumber, cnhValidationDate, addressProofDate })	
        );	

        loading.setFalse();	
        nextStep();	
}	

    return (	
        <FormProvider {...formMethods}>	
            <TextField	
                disabled={isLoading}	
                label="CPF"	
                value={workspace.id}	
                InputProps={{	
                    readOnly: true,	
                }}	
            />	
        <TextFieldInput disabled={isLoading} label="nome" name="name" />	
        <PhoneNumberInput disabled={isLoading} name="phoneNumber" />	
        <FileFieldInput disabled={isLoading} label="CNH" name="cnh" />	

        <DatePickerInput
            label="Data de validade da CNH"
            name="cnhValidationDate"
            views={['day', 'month', 'year']}
            renderInput={(params) => <TextField {...params} 
                error={formMethods.formState.errors.cnhValidationDate?.message}
                helperText={formMethods.formState.errors.cnhValidationDate?.message}/>}
        />

        <FileFieldInput	
            disabled={isLoading}	
            label="Comprovante de Res."	
            name="addressProof"	
        />

        <DatePickerInput
            label="Data do comprovante de residência"
            name="addressProofDate"
            views={['day', 'month', 'year']}
            renderInput={(params) => <TextField {...params} 
                error={formMethods.formState.errors.addressProofDate?.message}
                helperText={formMethods.formState.errors.addressProofDate?.message}/>}
        />

        <WorkspaceFormButtons	
            loading={loading.value}	
            onNext={handleSubmit(submit)}	
        />	

        </FormProvider>	
    );	
}