import useAuthSWR from '../../hooks/useAuthSWR';
import UserCard from '../../components/UserCard';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import CreateUserDialog from '../../components/CreateUserDialog';
import SearchBar from '../../components/SearchBar';

export default function ViewUsers() {
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { data: users, mutate } = useAuthSWR(
    '/users',
    {},
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  function createMutateForUser(user) {
    return (newUser) =>
      mutate(
        (users) => {
          const newData = users.map((currentUser) => {
            if (currentUser.id !== user.id) return currentUser;

            return newUser;
          });

          return newData;
        },
        {
          revalidate: false,
        }
      );
  }

  if (!users) return <></>;

  //filtragem dos usuários
  const filteredUsers = users.filter((user) => user.name.toLowerCase().includes(query.toLowerCase()))

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Box display="flex" justifyContent="center" gap={2}>
        {/* botão criar usuário */}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsCreateUserDialogOpen(true)}
          sx={{ mb: 2 }}
          startIcon={<AddIcon />}
        >
          Criar usuário
        </Button>
        <SearchBar fullWidth onChange={setQuery} delay={1000} /> {/* barra de pesquisa */}
      </Box>
      <Stack spacing={2} alignItems="center" width="100%" direction="column">
        {filteredUsers.map((user) => {
          return (
            <UserCard
              key={user.id}
              user={user}
              mutateUser={createMutateForUser(user)}
              mutate={mutate}
            />
          );
        })}
      </Stack>

      <CreateUserDialog
        open={isCreateUserDialogOpen}
        setOpen={setIsCreateUserDialogOpen}
        mutate={mutate}
      />
    </Container>
  );
}
