import api from './api';

async function getMany({ page, limit }) {
  const res = await api.get('/driver-workspaces', { params: { page, limit } });

  return res.data;
}

async function getOne(workspaceId) {
  const res = await api.get(`/driver-workspaces/${workspaceId}`);

  return res.data;
}

async function search(q) {
  const res = await api.get('driver-workspaces/search', { params: { q } });

  return res.data;
}

async function createWorkspace({ cpf }) {
  const res = await api.post('driver-workspaces', { cpf });
  return res.data;
}

async function createOwner(
  workspaceId,
  { phoneNumber, cnh, addressProof, name, cnhValidationDate, addressProofDate }
) {
  const res = await api.post(`/driver-workspaces/${workspaceId}/owners`, {
    phoneNumber,
    cnh,
    addressProof,
    name,
    cnhValidationDate,
    addressProofDate,
    type: name ? 'DRIVER' : 'COMMON',
  });

  //console.log(`criando owner ${res.data}`)
  return res.data
  
}

async function updateOwner(
  workspaceId,
  ownerId,
  { phoneNumber, cnh, addressProof, name, cnhValidationDate, addressProofDate}
) {
  const res = await api.put(
    `/driver-workspaces/${workspaceId}/owners/${ownerId}`,
    {
      phoneNumber,
      cnh,
      addressProof,
      name,
      cnhValidationDate,
      addressProofDate,
      type: name ? 'DRIVER' : 'COMMON',
    }
  );
  console.log(`id do motorista: ${ownerId}, seus dados: ${res.data}`)
  return res.data;
}

async function deleteOwner(workspaceId, ownerId) {
  await api.delete(`/driver-workspaces/${workspaceId}/owners/${ownerId}`);
}

async function createAntt(workspaceId, { rntrc, owner }) {
  const res = await api.post(`/driver-workspaces/${workspaceId}/antts`, {
    rntrc,
    owner,
  });

  //console.log(`antt criada na api: ${res.data}`)
  return res.data;
}

async function updateAntt(workspaceId, anttId, { rntrc, owner }) {
  const res = await api.put(
    `/driver-workspaces/${workspaceId}/antts/${anttId}`,
    {
      rntrc,
      owner,
    }
  );

  console.log(`antt atualizada ${res.data}`)
  return res.data;
}

async function deleteAntt(workspaceId, anttId) {
  await api.delete(`/driver-workspaces/${workspaceId}/antts/${anttId}`);
}

async function createImages(workspaceId, images) {
  const formData = new FormData();
  images.forEach((img) => formData.append('images', img));

  const res = await api.post(
    `driver-workspaces/${workspaceId}/images`,
    formData
  );

  return res.data;
}

async function deleteImage(workspaceId, imageName) {
  await api.delete(`driver-workspaces/${workspaceId}/images/${imageName}`);
}

async function upsertResult(
  workspaceId,
  {
    driver,
    pictures,
    references,
    bankAccount,
    truckTractor,
    truckTrailers,
    observation,
    lastManifest,
  }
) {
  await api.put(`driver-workspaces/${workspaceId}/result`, {
    driver,
    pictures,
    references,
    bankAccount,
    truckTractor,
    truckTrailers,
    observation,
    lastManifest,
  });
  console.log(`truckTractor: ${truckTractor}`)
  console.log(`truckTrailers: ${truckTrailers}`)
}

const workspaceApi = {
  getOne,
  search,
  getMany,
  createWorkspace,
  owners: {
    create: createOwner,
    update: updateOwner,
    delete: deleteOwner,
  },
  antts: {
    create: createAntt,
    update: updateAntt,
    delete: deleteAntt,
  },
  images: {
    createMany: createImages,
    delete: deleteImage,
  },
  result: {
    upsert: upsertResult,
  },
};
export default workspaceApi;
