import Typography from '@mui/material/Typography';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import Button from '@mui/material/Button';
import sectorApi from '../../api/sector.api';
import Slider from '@mui/material/Slider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const creatorSchema = yup.object({
  name: yup
    .string()
    .label('Nome do setor')
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
});

export default function EditSectorForm({ sector }) {
  const [shift, setShift] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [sectorNameState, setSectorNameState] = useState('');
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [ancoraMenu, setAncoraMenu] = useState(null);
  const [openHour, setOpenHour] = useState(false);
  const isMenuOpen = Boolean(ancoraMenu);

  const handleCloseMenu = () => {
    setAncoraMenu(null);
  };
  const handleClick = (event) => {
    setAncoraMenu(event.currentTarget);
  };
  const convertedShifValueToData = (shiftValue) => {
    const now = new Date();
    const shiftValueInMinutes = shiftValue * 30;

    now.setSeconds(0);
    now.setHours(Math.floor(shiftValueInMinutes / 60));
    now.setMinutes(shiftValueInMinutes % 60);

    return now;
  };

  async function onSubmitShift() {
    try {
      const shifts = [];

      for (let i = 0; i < shift.length; i++) {
        const current = shift[i];

        if (current) {
          shifts.push({
            startHour: convertedShifValueToData(current.startHour),
            endHour: convertedShifValueToData(current.endHour),
            dayOfWeek: i,
          });
        }
      }

      await sectorApi.setSectorShifts({
        sectorId: sector.id,
        shifts,
      });

      setMessage('Salvo !');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }
  async function onSubmit() {
    try {
      await sectorApi.updateSector({
        sectorId: sector.id,
        name: sectorNameState,
      });

      setMessage('Alterado !');
      setOpenSuccess(true);
      setUpdateDialog(false);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }

  async function onDeleteSector({ sectorId }) {
    try {
      await sectorApi.deleteSector({
        sectorId,
      });

      setMessage('Deletado !');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }

      setOpen(true);
    }
  }

  const creatorMethods = useYupForm(creatorSchema, {
    ...sector,
  });

  return (
    <>
      <FormProvider {...creatorMethods}>
        <ListItem>
          <ListItemButton>
            <ListItemText primary={sector.name} />
            <ListItemIcon>
              <MoreVertIcon onClick={handleClick} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <Divider />
        <Menu anchorEl={ancoraMenu} open={isMenuOpen} onClose={handleCloseMenu}>
          <MenuItem onClick={() => (setUpdateDialog(true), handleCloseMenu())}>
            <Typography>Alterar</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => (setConfirmationDialog(true), handleCloseMenu())}
          >
            <Typography>Deletar</Typography>
          </MenuItem>
          <MenuItem onClick={() => (setOpenHour(true), handleCloseMenu())}>
            <Typography>Expediente</Typography>
          </MenuItem>
        </Menu>

        <Dialog
          open={confirmationDialog}
          TransitionComponent={SlideTransition}
          keepMounted
          maxWidth="sm"
          onClose={() => setConfirmationDialog(false)}
        >
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deseja excluir permanentemente o setor
              <b> {sector.name} </b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationDialog(false)}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onDeleteSector({ sectorId: sector.id });
                setConfirmationDialog(false);
              }}
            >
              Remover
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          open={updateDialog}
          TransitionComponent={SlideTransition}
          keepMounted
          maxWidth="sm"
          onClose={() => setUpdateDialog(false)}
        >
          <DialogTitle>Alterar Nome</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Digite o nome que será atribuído ao setor <b>{sector.name}</b>
            </DialogContentText>
            <TextField
              fullWidth={true}
              sx={{ mt: '10px' }}
              label="Nome"
              onChange={(e) => setSectorNameState(e.currentTarget.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUpdateDialog(false)}>Cancelar</Button>
            <Button onClick={() => onSubmit()}>Alterar</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openHour}
          fullWidth={true}
          TransitionComponent={SlideTransition}
          onClose={() => setOpenHour(false)}
          scroll="body"
        >
          <DialogTitle>Funcionamento</DialogTitle>
          <DialogContent dividers={scroll === 'body'}>
            {[
              {
                label: 'Domingo',
                dayOfWeek: 1,
              },
              {
                label: 'Segunda',
                dayOfWeek: 2,
              },
              {
                label: 'Terça',
                dayOfWeek: 3,
              },
              {
                label: 'Quarta',
                dayOfWeek: 4,
              },
              {
                label: 'Quinta',
                dayOfWeek: 5,
              },
              {
                label: 'Sexta',
                dayOfWeek: 6,
              },
              {
                label: 'Sábado',
                dayOfWeek: 7,
              },
            ].map((week) => {
              return (
                <Paper
                  sx={{ p: 5, mb: 3, borderRadius: 6 }}
                  key={week.dayOfWeek}
                >
                  <Typography>{week.label}</Typography>
                  <Slider
                    defaultValue={[12, 46]}
                    valueLabelDisplay="auto"
                    disableSwap
                    step={1}
                    marks
                    valueLabelFormat={(x) =>
                      `${Math.floor(x / 2)}:${x % 2 ? '30' : '00'}`
                    }
                    min={12}
                    max={46}
                    onChange={(e, value) =>
                      setShift((shifts) => {
                        shifts[week.dayOfWeek] = {
                          startHour: value[0],
                          endHour: value[1],
                        };

                        return [...shifts];
                      })
                    }
                  />
                </Paper>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHour(false)}>Cancelar</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={creatorMethods.handleSubmit(onSubmitShift)}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
