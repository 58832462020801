import useSWR from 'swr';
import { api } from '../api';
import { useAuth } from '../contexts/AuthContext';

export default function useAuthSWR(uri, data, swrOptions, awaiting) {
  const { isAuthenticated } = useAuth();

  return useSWR(
    () => (isAuthenticated && !awaiting ? [uri, data] : null),
    axiosFetcher,
    swrOptions
  );
}

const axiosFetcher = async (uri, data) => {
  try {
    const res = await api.get(uri, { params: data });

    return res.data;
  } catch (error) {
    throw {
      statusCode: error.request.status,
      data: error.request.data,
    };
  }
};

export { axiosFetcher };
