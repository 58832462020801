import Stack from '@mui/material/Stack';
import { useAuth } from '../../contexts/AuthContext';
import TaskColumn from './TaskColumn';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState, useMemo } from 'react';
import taskApi from '../../api/task.api';
import sectorApi from '../../api/sector.api';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Groups2Icon from '@mui/icons-material/Groups2';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { usersApi } from '../../api';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FormProvider } from 'react-hook-form';
import TextFieldInput from '../../components/Form/TextFieldInput';
import DialogActions from '@mui/material/DialogActions';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import useAuthSWR from '../../hooks/useAuthSWR';
import NearbyTrucks from './NearbyTrucks';
import SquareColor from '../../dashboard/components/Form/SquareColor';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

function slideDialog(props) {
  return <Slide {...props} direction="up" />;
}

function Dashboard() {
  const navigate = useNavigate();
  const Room = [
    { name: 'Meu', icon: <PersonIcon /> },
    { name: 'Time', icon: <PeopleIcon /> },
    { name: 'Todos', icon: <Groups2Icon /> },
  ];

  const [open, setOpen] = useState(false);

  const { user } = useAuth();
  const [taskIds, setTaskIds] = useState([]);
  const [filter, setFilter] = useState('Todos');
  const [isDriverDialogOpen, setIsDriverDialogOpen] = useState(false);
  const { data: recommendations } = useAuthSWR('/recommendations');

  const query = useMemo(() => {
    if (filter == 'Meu') return { createdBy: user.id };
    if (filter == 'Time') return { roomId: user.room?.id };
    if (filter == 'Todos') return {};
  }, [filter, user]);

  useEffect(() => {
    async function main() {
      if (!user) return <></>;
      if (user.role == 'SUPER_ADMIN') {
        try {
          const data = await taskApi.getManyTasks();

          setTaskIds(data.map((task) => task.id));
        } catch (error) {
          setOpen(true);
        }
      } else if (user.sectors?.at(0) != undefined) {
        try {
          const data = await usersApi.getLoggedUserPermissions();

          const taskIds = data
            .filter((perm) => perm.action == 'VIEW')
            .map((perm) => perm.taskId);

          setTaskIds(taskIds);
        } catch (error) {
          1 + 1;
        }
      } else {
        setOpen(true);
        // This doesnt make sense
        navigate('/login');
      }
    }
    main();
  }, [user]);

  return (
    <>
    <Box sx={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}} >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflow: 'auto',
        }}
      > 
        <div />
        {taskIds.map((id) => (
          <TaskColumn key={id} taskId={id} query={query} />
        ))}
        <div />
      </Stack>
      <Box
      sx={{ 
          top: '0',
          marginTop: '3%',
          right: '0',
          position: 'fixed', 
        }} 
      >
        <SquareColor />
      </Box>
    </Box>
      <Box
        sx={{
          transform: 'translateZ(0px)',
        }}
      >
        <SpeedDial
          ariaLabel="Visualização de ticket"
          sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex' }}
          icon={<SpeedDialIcon color="#ffdf23" openIcon={<VisibilityIcon />} />}
        >
          {Room.map((action) => (
            <SpeedDialAction
              onClick={() => setFilter(action.name)}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          <Typography>Você não está cadastrado!</Typography>
        </Alert>
      </Snackbar>

    </>
  );
}

export default Dashboard;
