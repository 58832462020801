import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import useYupForm from '../../hooks/useYupForm';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextFieldInput from '../../components/Form/TextFieldInput';
import FileInput from '../../components/EditWorkspaceForm/FileFieldInput';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import driverApi from '../../api/drivers.api';

import Cavalo from '../../components/TruckTractorInput';

const steps = [
  'Motorista',
  'Cavalo',
  'Carreta',
  'ANTT',
  'Conta Bancária',
  'Fotos',
  'Referências',
];

const creatorSchema = yup.object({
  cnh: yup.array(),
  proofOfAddress: yup.array(),
  antt: yup.array(),
  bankAccount: yup.object(), //
  carrierPersonnelReference: yup.array(),
  cnhOrRgOwner: yup.array(),
  ownerProofAdress: yup.object(),
  ownerContact: yup.object(), //
  lastManifest: yup.array(),
  driverPictures: yup.array(),
  howGetToUs: yup.object(), //
  cartDocument: yup.array(),
  anttOwnerContact: yup.object(), //
  professionalTime: yup.object(), //
  driverContact: yup.object(), //
  pis: yup.object(), //
  file: yup.array(),
});
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export default function RegisterDriveForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [person, setPerson] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const creatorMethods = useYupForm(creatorSchema);

  const handlePerson = (e) => {
    setPerson(e.target.checked);
  };

  async function onSubmit({
    nomemot,
    telmot,
    motcpf,
    motcnh,
    motproofOfAddress,
    telefonePropAntt,
    ComprovanteResidenciaAntt,
    cnhAntt,
    NomeTitular,
    cpfTitular,
    ContaBancaria,
    numConta,
    NumAgencia,
    pix,
    mot,
    caminhao,
    motCaminhao,
    motDoc,
    LastManifest,
    ProfessionalTime,
    observacao,
    rntrc,
    carreta,
    cavalo,
    ContatoPes1,
    ContatoPes2,
    Transportadora1,
    Transportadora2,
    Transportadora3,
  }) {
    try {
      const images = [];
      const pictures = [];

      mot.map((img) => {
        const i = images.push(img);
        pictures.push(i);
      });
      caminhao.map((img) => {
        const i = images.push(img);
        pictures.push(i);
      });
      motCaminhao.map((img) => {
        const i = images.push(img);
        pictures.push(i);
      });
      motDoc.map((img) => {
        const i = images.push(img);
        pictures.push(i);
      });

      let truckTractorOwner;
      if (cavalo.isPropriety) truckTractorOwner = null;
      else if (cavalo.isLegalPerson) truckTractorOwner = undefined;
      else
        truckTractorOwner = {
          cnh: cavalo.cnh.map((v) => {
            const i = images.push(v) - 1;
            return i;
          }),
          addressProof: cavalo.ProofOfAdressCarr.map((v) => {
            const i = images.push(v) - 1;
            return i;
          }),
          phoneNumber: cavalo.phoneNumber,
        };
      await driverApi.createDriver({
        images,
        register: {
          driver: {
            name: nomemot,
            cpf: motcpf,
            phoneNumber: telmot,
            cnh: motcnh.map((v) => {
              const i = images.push(v) - 1;
              return i;
            }),
            addressProof: motproofOfAddress.map((v) => {
              const i = images.push(v) - 1;
              return i;
            }),
          },
          truckTractor: {
            crlv: cavalo.docCarreta.map((v) => {
              const i = images.push(v) - 1;
              return i;
            }),
            owner: truckTractorOwner,
          },

          truckTrailer: carreta.map((a) => {
            let owner;
            if (a.isPropriety) owner = undefined;
            else if (a.isLegalPerson) owner = undefined;
            else
              owner = {
                cnh: a.cnh,
                addressProof: a.ProofOfAdressCarr.map((v) => {
                  const i = images.push(v) - 1;
                  return i;
                }),
                phoneNumber: a.phoneNumber,
              };
            return {
              crlv: a.docCarreta,
              owner: owner,
            };
          }),
          antt: {
            phoneNumber: telefonePropAntt,
            cnh:
              cnhAntt &&
              cnhAntt.map((v) => {
                const i = images.push(v) - 1;
                return i;
              }),

            addressProof:
              ComprovanteResidenciaAntt &&
              ComprovanteResidenciaAntt.map((v) => {
                const i = images.push(v) - 1;
                return i;
              }),
            rntrc:
              rntrc &&
              rntrc.map((v) => {
                const i = images.push(v) - 1;
                return i;
              }),
          },
          bankAccount: {
            bankName: ContaBancaria,
            agencyNumber: NumAgencia,
            accountNumber: numConta,
            pixNumber: pix,
            fullName: NomeTitular,
            cpf: cpfTitular,
          },
          lastManifest: LastManifest,
          pictures,
          references: {
            personalContacts: [ContatoPes1, ContatoPes2],
            companyContacts: [
              Transportadora1,
              Transportadora2,
              Transportadora3,
            ],
            experienceTime: ProfessionalTime,
            observation: observacao,
          },
        },
      });

      setMessage('Cadastrado !');
      setOpenStatusSuccess(true);
    } catch (error) {
      if (error.response === 401) {
        setMessage('Não autorizado !');
      } else if (error.response === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      } else if (error.response === 500) {
        setMessage('Erro no servidor !');
      }
      setOpenStatus(true);
    }
  }
  return (
    <>
      <FormProvider {...creatorMethods}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Dados salvos !</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Novo</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep === 0 ? (
                  <>
                    <Container
                      maxWidth="xs"
                      sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Paper sx={{ p: 5, boderRadius: 6 }}>
                        <Stack spacing={2} sx={{ width: '100%' }}>
                          <TextFieldInput name="nomemot" label="Nome" />
                          <TextFieldInput name="telmot" label="Telefone" />
                          <TextFieldInput name="motcpf" label="CPF" />

                          <FileInput
                            name="motcnh"
                            TextFieldProps={{ label: 'CNH' }}
                          />
                          <FileInput
                            name="motproofOfAddress"
                            TextFieldProps={{
                              label: 'Comprovante de Res.',
                            }}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={
                              creatorMethods.handleSubmit(onSubmit) &&
                              handleNext
                            }
                          >
                            {activeStep === steps.length - 1
                              ? 'Terminar'
                              : 'Próximo'}
                          </Button>
                          <Button
                            color="red"
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Voltar
                          </Button>
                        </Stack>
                      </Paper>
                    </Container>
                  </>
                ) : (
                  <></>
                )}
                {activeStep === 1 ? (
                  <>
                    <Container
                      maxWidth="xs"
                      sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Paper sx={{ p: 5, boderRadius: 6 }}>
                        <Stack spacing={2} sx={{ width: '100%' }}>
                          <Cavalo name="cavalo" />
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={
                              creatorMethods.handleSubmit(onSubmit) &&
                              handleNext
                            }
                          >
                            {activeStep === steps.length - 1
                              ? 'Terminar'
                              : 'Próximo'}
                          </Button>
                          <Button
                            color="red"
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Voltar
                          </Button>
                        </Stack>
                      </Paper>
                    </Container>
                  </>
                ) : (
                  <></>
                )}
                {activeStep === 2 ? (
                  <Container
                    maxWidth="xs"
                    sx={{
                      minHeight: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Paper sx={{ p: 5, boderRadius: 6 }}>
                      <Stack spacing={2} sx={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={
                            creatorMethods.handleSubmit(onSubmit) && handleNext
                          }
                        >
                          {activeStep === steps.length - 1
                            ? 'Terminar'
                            : 'Próximo'}
                        </Button>
                        <Button
                          color="red"
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Voltar
                        </Button>
                      </Stack>
                    </Paper>
                  </Container>
                ) : (
                  <></>
                )}
                {activeStep === 3 ? (
                  <Container
                    maxWidth="xs"
                    sx={{
                      minHeight: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Paper sx={{ p: 5, boderRadius: 6 }}>
                      <Stack spacing={2} sx={{ width: '100%' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="PJ"
                            onChange={handlePerson}
                            checked={person}
                          />
                        </FormGroup>
                        {person ? (
                          <>
                            <TextFieldInput
                              name="telefonePropAntt"
                              type="number"
                              label="Telefone"
                            />
                            <FileInput
                              name="rntrc"
                              TextFieldProps={{
                                label: 'RNTRC',
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <TextFieldInput
                              name="telefonePropAntt"
                              type="number"
                              label="Telefone Prop"
                            />
                            <FileInput
                              name="ComprovanteResidenciaAntt"
                              TextFieldProps={{
                                label: 'Comprovante de residência',
                              }}
                            />
                            <FileInput
                              name="cnhAntt"
                              TextFieldProps={{
                                label: 'cnh',
                              }}
                            />
                            <FileInput
                              name="rntrc"
                              TextFieldProps={{
                                label: 'RNTRC',
                              }}
                            />
                          </>
                        )}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={
                            creatorMethods.handleSubmit(onSubmit) && handleNext
                          }
                        >
                          {activeStep === steps.length - 1
                            ? 'Terminar'
                            : 'Próximo'}
                        </Button>
                        <Button
                          color="red"
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Voltar
                        </Button>
                      </Stack>
                    </Paper>
                  </Container>
                ) : (
                  <></>
                )}
                {activeStep === 4 ? (
                  <Container
                    maxWidth="xs"
                    sx={{
                      minHeight: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Paper sx={{ p: 5, boderRadius: 6 }}>
                      <Stack spacing={2} sx={{ width: '100%' }}>
                        <TextFieldInput
                          name="NomeTitular"
                          label="Nome do titular"
                        />
                        <TextFieldInput
                          name="cpfTitular"
                          label="CPF Titular"
                          type="number"
                        />
                        <TextFieldInput name="ContaBancaria" label="Banco" />
                        <TextFieldInput
                          name="NumAgencia"
                          label="Agencia"
                          type="number"
                        />
                        <TextFieldInput name="numConta" label="Conta" />
                        <TextFieldInput name="pix" label="pix" />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={
                            creatorMethods.handleSubmit(onSubmit) && handleNext
                          }
                        >
                          {activeStep === steps.length - 1
                            ? 'Terminar'
                            : 'Próximo'}
                        </Button>
                        <Button
                          color="red"
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Voltar
                        </Button>
                      </Stack>
                    </Paper>
                  </Container>
                ) : (
                  <></>
                )}
                {activeStep === 5 ? (
                  <Container
                    maxWidth="xs"
                    sx={{
                      minHeight: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Paper sx={{ p: 5, boderRadius: 6 }}>
                      <Stack spacing={2} sx={{ width: '100%' }}>
                        <FileInput
                          name="mot"
                          TextFieldProps={{
                            label: 'Motorista',
                          }}
                        />
                        <FileInput
                          name="caminhao"
                          TextFieldProps={{
                            label: 'Caminhao',
                          }}
                        />
                        <FileInput
                          name="motCaminhao"
                          TextFieldProps={{
                            label: 'Motorista/Caminhão',
                          }}
                        />
                        <FileInput
                          name="motDoc"
                          TextFieldProps={{
                            label: 'Motorista/Doc',
                          }}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={
                            creatorMethods.handleSubmit(onSubmit) && handleNext
                          }
                        >
                          {activeStep === steps.length - 1
                            ? 'Terminar'
                            : 'Próximo'}
                        </Button>
                        <Button
                          color="red"
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Voltar
                        </Button>
                      </Stack>
                    </Paper>
                  </Container>
                ) : (
                  <></>
                )}
                {activeStep === 6 ? (
                  <Container
                    maxWidth="xs"
                    sx={{
                      minHeight: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Paper sx={{ p: 5, boderRadius: 6 }}>
                      <Stack spacing={2} sx={{ width: '100%' }}>
                        <TextFieldInput
                          name="ContatoPes1"
                          label="Contatos pessoal 1"
                        />
                        <TextFieldInput
                          name="ContatoPes2"
                          label="Contatos pessoal 2"
                        />
                        <TextFieldInput
                          name="Transportadora1"
                          label="Transportadora3 1"
                        />
                        <TextFieldInput
                          name="Transportadora2"
                          label="Transportadora 2"
                        />
                        <TextFieldInput
                          name="Transportadora3"
                          label="Transportadora 3"
                        />
                        <TextFieldInput
                          name="ContatoMot"
                          label="Contato Motorista"
                        />
                        <TextFieldInput
                          name="ref"
                          label="Como chegou até nós?"
                        />
                        <TextFieldInput
                          name="LastManifest"
                          label="Ultimo Manifesto"
                        />
                        <TextFieldInput
                          name="ProfessionalTime"
                          label="Tempo de profissão"
                        />
                        <TextFieldInput name="observacao" label="Observação" />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={creatorMethods.handleSubmit(onSubmit)}
                        >
                          {activeStep === steps.length - 1
                            ? 'Terminar'
                            : 'Próximo'}
                        </Button>
                        <Button
                          color="red"
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Voltar
                        </Button>
                      </Stack>
                    </Paper>
                  </Container>
                ) : (
                  <></>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </FormProvider>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
