import React from 'react';
import { TextFieldInput } from '../../components/Form';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import { Divider, IconButton, Button } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function DocumentForm({
  name,
  formLabelPrefix,
  buttonLabelSufix,
  ItemForm,
  ItemFormProps,
  itemDefaultValue,
  min,
  max,
  offset,
  isRemovible,
  isAddable,
}) {
  const { fields, append, remove } = useFieldArray({
    name,
    itemDefaultValue,
  });

  return (
    <>
      {fields.slice(offset).map((field, i) => {
        const index = i + (offset ?? 0);

        return (
          <Stack key={field.id} spacing={2}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              columnSpacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item sx={{ mt: 1 }}>
                <FormLabel>
                  {formLabelPrefix} {index + 1}
                </FormLabel>
              </Grid>

              <Grid item xs>
                <Divider />
              </Grid>

              <Grid item>
                {index >= min && (isRemovible ?? true) ? (
                  <IconButton
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <ItemForm {...(ItemFormProps ?? {})} name={`${name}.${index}`} />
          </Stack>
        );
      })}
      {fields.length < max && (isAddable ?? true) ? (
        <Button
          sx={{ mt: '20px', width: '100%' }}
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => append(itemDefaultValue)}
        >
          Adicionar {buttonLabelSufix}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}
