import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// eslint-disable-next-line
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function OccupationChart({ barSizes, users, scale, min }) {
  const options = {
    indexAxis: 'y',

    layout: {
      autoPadding: true,
      width: '100%',
    },

    plugins: {
      title: {
        display: true,
        text: 'Produtividade',
      },

      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,

        ticks: {
          callback: function (durationInMinutes) {
            let minutes =
              durationInMinutes + min.getHours() * 60 + min.getMinutes();

            return (
              String(Math.floor(minutes / 60)).padStart(2, '0') +
              ':' +
              String(minutes % 60).padStart(2, '0')
            );
          },
          stepSize: scale,
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Bar
      options={options}
      data={{
        labels: users,
        datasets: barSizes ?? [],
      }}
    />
  );
}
