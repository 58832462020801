import Stack from '@mui/material/Stack';
import TextFieldInput from '../Form/TextFieldInput';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import useYupForm from '../../hooks/useYupForm';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PrivateRoute from '../../hoc/PrivateRoute';
import taskApi from '../../api/task.api';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CheckboxInput from '../Form/CheckboxInput';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const creatorSchema = yup.object({
  title: yup
    .string()
    .label('Nome da task')
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
  sequence: yup
    .number('Necessita ser um número')
    .required('Campo obrigatório')
    .min(1, 'minimo 1 caractere'),
  isTimeManaged: yup.boolean(),
  completable: yup.boolean(),
  indictable: yup.boolean(),
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function EditTaskForm({ task }) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isUpdateTaskDialog, setIsUpdateTaskDialog] = useState(false);
  const [isConfirmationDeleteTaskOpen, setIsConfirmationDeleteTaskOpen] =
    useState(false);

  const isMenuOpen = Boolean(menuAnchor);

  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = (event) => {
    setMenuAnchor(null);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function onSubmit({
    title,
    sequence,
    isTimeManaged,
    completable,
    indictable,
  }) {
    try {
      await taskApi.updateTask(task.id, {
        title,
        sequence,
        isTimeManaged,
        completable: completable,
        passable: !completable,
        indictable,
      });
      setMessage('Salvo !');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Tipo de dado errado !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.response.status === 422) {
        setMessage('Task ja existe !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    }
  }

  async function deleleteTask({ taskId }) {
    try {
      await taskApi.deleteTask({
        taskId,
      });
      setMessage('Excluído!');
      setOpenSuccess(true);
    } catch (error) {
      if (!error.isAxiosError) throw error;
      if (error.response.status === 400) {
        setMessage('Tipo de dado errado !');
      } else if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Permissão não encontrada !');
      } else if (error.response.status === 422) {
        setMessage('Task ja existe !');
      } else if (error.code === 'ERR_NETWORK') {
        setMessage('Erro de conexão');
      }
      setOpen(true);
    }
  }

  const creatorMethods = useYupForm(creatorSchema, {
    ...task,
  });
  return (
    <FormProvider {...creatorMethods}>
      <>
        <ListItem>
          <ListItemButton>
            <ListItemText primary={task.title} secondary={task.sequence} />
            <ListItemIcon>
              <MoreVertIcon onClick={handleClick} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Divider />
        <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={handleCloseMenu}>
          <MenuItem
            onClick={() => (setIsUpdateTaskDialog(true), handleCloseMenu())}
          >
            <Typography>Alterar</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => (
              setIsConfirmationDeleteTaskOpen(true), handleCloseMenu()
            )}
          >
            <Typography>Deletar</Typography>
          </MenuItem>
        </Menu>
      </>
      <Dialog
        open={isConfirmationDeleteTaskOpen}
        TransitionComponent={SlideTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setIsConfirmationDeleteTaskOpen(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja excluir permanentemente a coluna
            <b> {task.title} </b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationDeleteTaskOpen(false)}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleleteTask({ taskId: task.id });
              setIsConfirmationDeleteTaskOpen(false);
            }}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isUpdateTaskDialog}
        TransitionComponent={SlideTransition}
        keepMounted
        maxWidth="sm"
        onClose={() => setIsUpdateTaskDialog(false)}
      >
        <DialogTitle>
          Alterar a coluna <b>{task.title}</b>
        </DialogTitle>
        <DialogContent>
          <TextFieldInput
            label="Nome"
            name="title"
            fullWidth={true}
            sx={{ mt: 2 }}
          />
          <TextFieldInput
            label="Nome"
            name="sequence"
            sx={{ mt: 2 }}
            type="number"
            fullWidth={true}
          />
          <CheckboxInput name="isTimeManaged" label="Tempo controlado" />
          <CheckboxInput name="completable" label="Arquivável" />
          <CheckboxInput name="indictable" label="Indicável" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUpdateTaskDialog(false)}>Cancelar</Button>
          <Button onClick={creatorMethods.handleSubmit(onSubmit)}>
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
export default PrivateRoute(EditTaskForm);
