import { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import Paper from '@mui/material/Paper';

//cores do gráfico
const ChartColors = [
  '#e4605e',
  '#eefb54',
  '#766ec5',
  '#FF9900',
  '#FF007F',
  '#33CCCC',
  '#008000',
  '#800080',
  '#00ffff',
  '#fffafa',
];

export default function AverageChart({ data, manyTask }) {
  const TASKMAP = useMemo(() => {
    if (!manyTask) return;

    const map = new Map();
    for (const task of manyTask) {
      map.set(task.id, task);
    }
    return map;
  }, [manyTask]);

  const pieChartData = useMemo(() => {
    if (!data) return;

    return data.average.map(({ taskId, timeInMinutes }) => ({
      id: TASKMAP.get(taskId)?.title,
      label: `${TASKMAP.get(taskId)?.title} (${Math.round(timeInMinutes)} min)`,
      value: Math.round(timeInMinutes),
      color: 'hsl(354, 70%, 50%)',
    }));
  }, [TASKMAP, data]);

  //if (!pieChartData) return <></>;
  return (
    <>
      <h4>Tempo para cada etapa em minutos(min):</h4>
      <Paper
        sx={{
          mr: 'auto',
          ml: 'auto',
          width: '100%',
          height: '600px',
          display: 'flex',
        }}
      >
        <ResponsivePie
          colors={ChartColors}
          theme={{
            background: '#deddda',
            textColor: '#333333',
            fontSize: 11,
            axis: {
              domain: {
                line: {
                  stroke: '#777777',
                  strokeWidth: 1,
                },
              },
              legend: {
                text: {
                  fontSize: 12,
                  fill: '#333333',
                },
              },
              ticks: {
                line: {
                  stroke: '#777777',
                  strokeWidth: 1,
                },
                text: {
                  fontSize: 11,
                  fill: '#333333',
                },
              },
            },
            grid: {
              line: {
                stroke: '#dddddd',
                strokeWidth: 1,
              },
            },
            legends: {
              title: {
                text: {
                  fontSize: 11,
                  fill: '#333333',
                },
              },
              text: {
                fontSize: 11,
                fill: '#333333',
              },
              ticks: {
                line: {},
                text: {
                  fontSize: 10,
                  fill: '#333333',
                },
              },
            },
            annotations: {
              text: {
                fontSize: 13,
                fill: '#333333',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              link: {
                stroke: '#000000',
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              outline: {
                stroke: '#a51d2d',
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
              symbol: {
                fill: '#000000',
                outlineWidth: 20,
                outlineColor: '#ffffff',
                outlineOpacity: 1,
              },
            },
            tooltip: {
              container: {
                background: '#f6f5f4',
                color: '#333333',
                fontSize: 15,
              },
              basic: {},
              chip: {},
              table: {},
              tableCell: {},
              tableCellValue: {},
            },
          }}
          enableArcLinkLabels={false}
          animate={true}
          transitionMode="pushIn"
          data={pieChartData ?? []}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeInnerRadiusOffset={5}
          activeOuterRadiusOffset={10}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 0,
              translateY: 39,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 50,
              symbolSize: 20,
              itemDirection: 'top-to-bottom',
            },
          ]}
        />
      </Paper>
    </>
  );
}
