const statesData = {
  options: [
    {
      year: 2024,
      states: [
        {
          stateName: 'RS',
          dateValidations: {
            '2024/06/30': [1, 2, 3, 4, 5],
            '2024/07/31': [6, 7, 8, 9, 0],
          },
        },
        {
          stateName: 'SC',
          dateValidations: {
            '2024/03/31': [1],
            '2024/04/30': [2],
            '2024/05/31': [3],
            '2024/06/30': [4],
            '2024/07/31': [5],
            '2024/08/31': [6],
            '2024/09/30': [7],
            '2024/10/31': [8],
            '2024/11/30': [9],
            '2024/12/31': [0],
          },
        },
        {
          stateName: 'PR',
          dateValidations: {
            '2024/08/16': [1],
            '2024/08/30': [2],
            '2024/09/13': [3, 4],
            '2024/09/27': [5],
            '2024/10/14': [6, 7],
            '2024/10/28': [8],
            '2024/11/14': [9],
            '2024/11/29': [0],
          },
        },
        {
          stateName: 'SP',
          dateValidations: {
            '2024/09/30': [1, 2],
            '2024/10/30': [3, 4, 5],
            '2024/11/31': [6, 7, 8],
            '2024/12/30': [9, 0],
          },
        },
        {
          stateName: 'RJ',
          dateValidations: {
            '2024/07/31': [0, 1, 2],
            '2024/08/31': [3, 4, 5],
            '2024/09/30': [6, 7, 8, 9],
          },
        },
        {
          stateName: 'MG',
          dateValidations: {
            '2024/03/31': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'GO',
          dateValidations: {
            '2024/09/09': [1],
            '2024/09/10': [2],
            '2024/10/07': [3],
            '2024/10/08': [4],
            '2024/10/09': [5],
            '2024/10/10': [6],
            '2024/10/11': [7],
            '2024/10/14': [8],
            '2024/10/15': [9],
            '2024/10/16': [0],
          },
        },
        {
          stateName: 'ES',
          dateValidations: {
            '2024/02/28': [1, 2],
            '2024/03/28': [3, 4],
            '2024/04/30': [5, 6],
            '2024/05/31': [7, 8],
            '2024/06/28': [9, 0],
          },
        },
        {
          stateName: 'BA',
          dateValidations: {
            '2024/07/30': [1],
            '2024/07/31': [2],
            '2024/08/29': [3],
            '2024/08/30': [4],
            '2024/09/27': [5],
            '2024/09/30': [6],
            '2024/10/30': [7],
            '2024/10/31': [8],
            '2024/11/28': [9],
            '2024/11/29': [0],
          },
        },
        {
          stateName: 'SE',
          dateValidations: {
            '2024/04/30': [1, 2],
            '2024/05/31': [3, 4],
            '2024/06/28': [5],
            '2024/07/31': [6],
            '2024/08/30': [7],
            '2024/09/30': [8],
            '2024/10/31': [9],
            '2024/11/29': [0],
          },
        },
        {
          stateName: 'AL',
          dateValidations: {
            '2024/08/31': [1, 2],
            '2024/09/30': [3, 4],
            '2024/10/31': [5, 6],
            '2024/11/30': [7, 8],
            '2024/12/31': [9, 0],
          },
        },
        {
          stateName: 'PE',
          dateValidations: {
            '2024/09/29': [1, 2],
            '2024/10/31': [3, 4, 5],
            '2024/11/30': [6, 7, 8],
            '2024/12/29': [9, 0],
          },
        },
        {
          stateName: 'PB',
          dateValidations: {
            '2024/03/28': [1],
            '2024/04/30': [2],
            '2024/05/31': [3],
            '2024/06/28': [4],
            '2024/07/31': [5],
            '2024/08/30': [6],
            '2024/09/30': [7],
            '2024/10/31': [8],
            '2024/11/29': [9],
            '2024/12/30': [0],
          },
        },
        {
          stateName: 'RN',
          dateValidations: {
            '2024/02/08': [1],
            '2024/02/09': [2],
            '2024/03/07': [3],
            '2024/03/08': [4],
            '2024/04/11': [5],
            '2024/04/12': [6],
            '2024/05/09': [7],
            '2024/05/10': [8],
            '2024/06/06': [9],
            '2024/06/07': [0],
          },
        },
        {
          stateName: 'CE',
          dateValidations: {
            '2024/03/11': [1],
            '2024/04/10': [2],
            '2024/05/10': [3],
            '2024/06/12': [4],
            '2024/07/10': [5],
            '2024/08/12': [6],
            '2024/09/10': [7],
            '2024/10/10': [8],
            '2024/11/11': [9],
            '2024/12/10': [0],
          },
        },
        {
          stateName: 'PI',
          dateValidations: {
            '2024/03/29': [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'MA',
          dateValidations: {
            '2024/05/03': [1, 2],
            '2024/05/10': [3, 4],
            '2024/05/17': [5, 6],
            '2024/05/24': [7, 8],
            '2024/05/31': [9, 0],
          },
        },
        {
          stateName: 'TO',
          dateValidations: {
            '2024/01/31': [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'MS',
          dateValidations: {
            '2024/04/30': [1, 2],
            '2024/05/31': [3],
            '2024/06/30': [4, 5],
            '2024/07/31': [6],
            '2024/08/31': [7, 8],
            '2024/09/30': [9],
            '2024/10/31': [0],
          },
        },
        {
          stateName: 'MT',
          dateValidations: {
            '2024/03/31': [1, 2, 3],
            '2024/04/30': [4],
            '2024/05/31': [5],
            '2024/06/30': [6],
            '2024/07/31': [7],
            '2024/08/31': [8],
            '2024/09/30': [9],
            '2024/10/31': [0],
          },
        },
        {
          stateName: 'PA',
          dateValidations: {
            '2024/03/15': [1],
            '2024/04/12': [2],
            '2024/05/10': [3],
            '2024/06/07': [4],
            '2024/06/28': [5],
            '2024/07/19': [6],
            '2024/08/23': [7],
            '2024/09/20': [8],
            '2024/10/18': [9],
            '2024/11/22': [0],
          },
        },
        {
          stateName: 'AP',
          dateValidations: {
            '2024/03/15': [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'RR',
          dateValidations: {
            '2024/03/29': [1],
            '2024/04/30': [2],
            '2024/05/31': [3],
            '2024/06/28': [4],
            '2024/07/31': [5],
            '2024/08/30': [6],
            '2024/09/30': [7],
            '2024/10/31': [8],
            '2024/11/29': [9],
            '2024/12/30': [0],
          },
        },
        {
          stateName: 'AM',
          dateValidations: {
            '2024/03/28': [1],
            '2024/04/30': [2],
            '2024/05/31': [3],
            '2024/06/28': [4],
            '2024/07/31': [5],
            '2024/08/30': [6],
            '2024/09/30': [7],
            '2024/10/31': [8],
            '2024/11/29': [9],
            '2024/12/27': [0],
          },
        },
        {
          stateName: 'RO',
          dateValidations: {
            '2024/03/28': [1, 2, 3],
            '2024/04/30': [4],
            '2024/05/31': [5],
            '2024/06/28': [6],
            '2024/07/31': [7],
            '2024/08/30': [8],
            '2024/09/30': [9],
            '2024/10/31': [0],
          },
        },
        {
          stateName: 'AC',
          dateValidations: {
            '2024/03/27': [1, 2],
            '2024/04/30': [3, 4],
            '2024/05/29': [5],
            '2024/06/28': [6],
            '2024/07/31': [7],
            '2024/08/30': [8],
            '2024/09/30': [9],
            '2024/10/31': [0],
          },
        },
      ],
    },
    {
      year: 2023,
      states: [
        {
          stateName: 'RS',
          dateValidations: {
            '2024/06/30': [1, 2, 3, 4, 5],
            '2024/07/31': [6, 7, 8, 9, 0],
          },
        },
        {
          stateName: 'SC',
          dateValidations: {
            '2023/03/31': [1],
            '2023/04/30': [2],
            '2023/05/31': [3],
            '2023/06/30': [4],
            '2023/07/31': [5],
            '2023/08/31': [6],
            '2023/09/30': [7],
            '2023/10/31': [8],
            '2023/11/30': [9],
            '2023/12/31': [0],
          },
        },
        {
          stateName: 'PR',
          dateValidations: {
            '2024/08/16': [1],
            '2024/08/30': [2],
            '2024/09/13': [3, 4],
            '2024/09/27': [5],
            '2024/10/14': [6, 7],
            '2024/10/28': [8],
            '2024/11/14': [9],
            '2024/11/29': [0],
          },
        },
        {
          stateName: 'SP',
          dateValidations: {
            '2023/09/30': [1, 2],
            '2023/10/30': [3, 4, 5],
            '2023/11/31': [6, 7, 8],
            '2023/12/30': [9, 0],
          },
        },
        {
          stateName: 'RJ',
          dateValidations: {
            '2024/07/31': [0, 1, 2],
            '2024/08/31': [3, 4, 5],
            '2024/09/30': [6, 7, 8, 9],
          },
        },
        {
          stateName: 'MG',
          dateValidations: {
            '2023/07/31': [1, 2, 3],
            '2023/08/31': [4, 5, 6],
            '2023/09/30': [7, 8, 9, 0],
          },
        },
        {
          stateName: 'GO',
          dateValidations: {
            '2023/09/11': [1],
            '2023/09/12': [2],
            '2023/10/09': [3],
            '2023/10/10': [4],
            '2023/10/11': [5],
            '2023/10/16': [6],
            '2023/10/17': [7],
            '2023/10/18': [8],
            '2023/10/19': [9],
            '2023/10/20': [0],
          },
        },
        {
          stateName: 'ES',
          dateValidations: {
            '2023/09/28': [1, 2],
            '2023/09/29': [3, 4],
            '2023/10/02': [5, 6],
            '2023/10/03': [7, 8],
            '2023/10/04': [9, 0],
          },
        },
        {
          stateName: 'BA',
          dateValidations: {
            '2023/07/28': [1],
            '2023/07/31': [2],
            '2023/08/30': [3],
            '2023/08/31': [4],
            '2023/09/28': [5],
            '2023/09/29': [6],
            '2023/10/30': [7],
            '2023/10/31': [8],
            '2023/11/29': [9],
            '2023/11/30': [0],
          },
        },
        {
          stateName: 'SE',
          dateValidations: {
            '2023/04/28': [1, 2],
            '2023/05/31': [3, 4],
            '2023/06/30': [5],
            '2023/07/31': [6],
            '2023/08/31': [7],
            '2023/09/29': [8],
            '2023/10/31': [9],
            '2023/11/30': [0],
          },
        },
        {
          stateName: 'AL',
          dateValidations: {
            '2023/08/31': [1, 2],
            '2023/09/30': [3, 4],
            '2023/10/31': [5, 6],
            '2023/11/29': [7, 8],
            '2023/12/30': [9, 0],
          },
        },
        {
          stateName: 'PE',
          dateValidations: {
            '2023/09/30': [1, 2],
            '2023/10/31': [3, 4, 5],
            '2023/11/30': [6, 7, 8],
            '2023/12/31': [9, 0],
          },
        },
        {
          stateName: 'PB',
          dateValidations: {
            '2023/03/31': [1],
            '2023/04/28': [2],
            '2023/05/31': [3],
            '2023/06/30': [4],
            '2023/07/31': [5],
            '2023/08/31': [6],
            '2023/09/29': [7],
            '2023/10/31': [8],
            '2023/11/30': [9],
            '2023/12/28': [0],
          },
        },
        {
          stateName: 'RN',
          dateValidations: {
            '2023/09/30': [1, 2],
            '2023/10/30': [3, 4, 5],
            '2023/11/31': [6, 7, 8],
            '2023/12/30': [9, 0],
          },
        },
        {
          stateName: 'CE',
          dateValidations: {
            '2023/03/10': [1],
            '2023/04/10': [2],
            '2023/05/10': [3],
            '2023/06/12': [4],
            '2023/07/10': [5],
            '2023/08/10': [6],
            '2023/09/11': [7],
            '2023/10/10': [8],
            '2023/11/10': [9],
            '2023/12/11': [0],
          },
        },
        {
          stateName: 'PI',
          dateValidations: {
            '2023/03/31': [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'MA',
          dateValidations: {
            '2023/05/05': [1, 2],
            '2023/05/12': [3, 4],
            '2023/05/19': [5, 6],
            '2023/05/26': [7, 8],
            '2023/05/31': [9, 0],
          },
        },
        {
          stateName: 'TO',
          dateValidations: {
            '2023/12/31': [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'MS',
          dateValidations: {
            '2023/04/30': [1, 2],
            '2023/05/31': [3],
            '2023/06/30': [4, 5],
            '2023/07/31': [6],
            '2023/08/31': [7, 8],
            '2023/09/30': [9],
            '2023/10/31': [0],
          },
        },
        {
          stateName: 'MT',
          dateValidations: {
            '2023/03/31': [1, 2, 3],
            '2023/04/30': [4],
            '2023/05/31': [5],
            '2023/06/30': [6],
            '2023/07/31': [7],
            '2023/08/31': [8],
            '2023/09/30': [9],
            '2023/10/31': [0],
          },
        },
        {
          stateName: 'PA',
          dateValidations: {
            '2023/03/17': [1],
            '2023/04/14': [2],
            '2023/05/12': [3],
            '2023/06/02': [4],
            '2023/06/30': [5],
            '2023/07/21': [6],
            '2023/08/18': [7],
            '2023/09/15': [8],
            '2023/10/06': [9],
            '2023/11/24': [0],
          },
        },
        {
          stateName: 'AP',
          dateValidations: {
            '2023/03/15': [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          stateName: 'RR',
          dateValidations: {
            '2023/02/28': [1],
            '2023/03/31': [2],
            '2023/04/28': [3],
            '2023/05/31': [4],
            '2023/06/30': [5],
            '2023/07/31': [6],
            '2023/08/31': [7],
            '2023/09/29': [8],
            '2023/10/31': [9],
            '2023/11/30': [0],
          },
        },
        {
          stateName: 'AM',
          dateValidations: {
            '2023/05/31': [1, 2, 3],
            '2023/06/30': [4],
            '2023/07/31': [5],
            '2023/08/31': [6],
            '2023/09/29': [7],
            '2023/10/31': [8],
            '2023/11/30': [9],
            '2023/12/29': [0],
          },
        },
        {
          stateName: 'RO',
          dateValidations: {
            '2023/03/31': [1, 2, 3],
            '2023/04/28': [4],
            '2023/05/31': [5],
            '2023/06/30': [6],
            '2023/07/31': [7],
            '2023/08/31': [8],
            '2023/09/29': [9],
            '2023/10/31': [0],
          },
        },
        {
          stateName: 'AC',
          dateValidations: {
            '2023/03/31': [1, 2],
            '2023/04/28': [3, 4],
            '2023/05/31': [5],
            '2023/06/30': [6],
            '2023/07/31': [7],
            '2023/08/31': [8],
            '2023/09/29': [9],
            '2023/10/31': [0],
          },
        },
      ],
    },
  ],
};

export default statesData;
